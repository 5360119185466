<template>
  <v-card
    class="py-3 rounded-lg mx-auto d-flex flex-column align-center justify-center pa-12 text-center"
    width="1200"
  >
    <v-progress-circular
      width="6"
      size="130"
      :color="'primary'"
      indeterminate
      class="mt-8"
    >
      <Logo product="conecta_control" height="55" no-margins />
    </v-progress-circular>

    <v-card-title
      class="primary--text text-h5 text-sm-h4 font-weight-bold text-center break-word text-wrap normal-line-height mt-5"
    >
      {{ $ml.get("activatingControlAccess") }}
    </v-card-title>

    <v-card-text
      v-if="configuredTemporaryPassword"
      v-html="$ml.get('activatingControlAccessTip')"
    />

    <PasswordCopy v-if="configuredTemporaryPassword" class="my-6" />

    <v-card-text
      v-if="configuredTemporaryPassword"
      v-html="$ml.get('temporaryPasswordFutureChanges')"
      class="mt-5 mb-7"
    />

    <v-card-text
      v-if="askedGenerateRandomPassword"
      v-html="$ml.get('tipToAdminAboutThePasswords')"
      class="mt-5 mb-7"
    />

    <v-card-text
      v-if="askedSendLinkToChangePassword"
      v-html="$ml.get('tipToAdminAboutChangePasswordLink')"
      class="mt-5 mb-7"
    />
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import PasswordCopy from "@/components/activation/second_step/PasswordCopy.vue";

export default {
  name: "ActivationLoading",

  components: { PasswordCopy },

  computed: {
    ...mapGetters([
      "askedGenerateRandomPassword",
      "askedSendLinkToChangePassword",
    ]),
    configuredTemporaryPassword() {
      return !(
        this.askedGenerateRandomPassword || this.askedSendLinkToChangePassword
      );
    },
  },
};
</script>
