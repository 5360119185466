<template>
  <v-card class="pb-6" width="100%">
    <v-row justify="center" align="center" class="mb-8">
      <v-col cols="12" sm="12" md="6">
        <PasswordChangedIlustration />
      </v-col>

      <v-col cols="12" sm="12" md="6" class="">
        <v-card
          flat
          class="d-flex flex-column align-center justify-center"
          style="width: 100%"
        >
          <v-card-title
            v-if="!askedSendLinkToChangePassword"
            class="primary--text text-h4 font-weight-bold text-center break-word text-wrap normal-line-height text-center"
          >
            {{ $ml.get("temporaryPasswordChanged") }}
          </v-card-title>
          <v-card-title
            v-else
            class="primary--text text-h4 font-weight-bold text-center break-word text-wrap normal-line-height text-center"
          >
            {{ $ml.get("sendEmailToChangePassword") }}
          </v-card-title>

          <v-card-text
            v-if="!isAnotherPasswordChangeMethod"
            class="break-word text-body-1 normal-line-height mb-3 mt-1 secondary--text text--lighten-2 text-center"
            style="max-width: 500px"
          >
            {{ $ml.get("informUsersAboutTheNewPassword") }}
          </v-card-text>

          <PasswordCopy v-if="!isAnotherPasswordChangeMethod" />
          <div class="text-center pa-4 mb-3" style="width: 85%" outlined>
            <v-btn
              v-if="!isAnotherPasswordChangeMethod"
              x-large
              outlined
              color="accent"
              block
              @click="showDialog = true"
              :loading="loadingNotification"
              :disabled="loadingNotification"
              class="text-none text-body-2 text-sm-body-1 font-weight-medium"
            >
              <v-icon left dark size="25" class="mr-3">
                mdi-email-fast-outline
              </v-icon>
              {{ $ml.get("notify_users_via_email") }}
            </v-btn>
          </div>

          <v-list
            v-if="askedGenerateRandomPassword"
            :class="`${$vuetify.breakpoint.mobile ? 'px-4' : 'px-0'} mt-4`"
          >
            <v-list-item class="mb-5 yellow lighten-5 py-2">
              <v-list-item-action class="mr-4">
                <v-icon v-text="'mdi-lightbulb-on-10'" :color="'accent'" />
              </v-list-item-action>

              <v-list-item-title
                class="text-wrap break-word normal-line-height"
                v-html="temporaryPasswordWarningCoWorkersLabel"
              />
            </v-list-item>
            <v-list-item class="mb-5 green lighten-5 py-2">
              <v-list-item-action class="mr-4">
                <v-icon v-text="'mdi-check'" :color="'green'" />
              </v-list-item-action>

              <v-list-item-title class="text-wrap break-word normal-line-height"
                ><strong>{{
                  $ml.get("informUsersAboutRandomPassword")
                }}</strong></v-list-item-title
              >
            </v-list-item>
            <v-list-item class="mb-5 green lighten-5 py-2">
              <v-list-item-action class="mr-4">
                <v-icon v-text="'mdi-check'" :color="'green'" />
              </v-list-item-action>

              <v-list-item-title
                class="text-wrap break-word normal-line-height"
                v-html="informAdminAboutTheRandomPasswords"
              />
            </v-list-item>
          </v-list>

          <v-list
            v-if="askedSendLinkToChangePassword"
            :class="`${$vuetify.breakpoint.mobile ? 'px-4' : 'px-0'} mt-4`"
          >
            <v-list-item class="mb-5 green lighten-5 py-2">
              <v-list-item-action class="mr-4">
                <v-icon v-text="'mdi-check'" :color="'green'" />
              </v-list-item-action>

              <v-list-item-title class="text-wrap break-word normal-line-height"
                ><strong>{{
                  $ml.get("informUsersAboutEmailToChangePassword")
                }}</strong></v-list-item-title
              >
            </v-list-item>
            <v-list-item class="mb-5 green lighten-5 py-2">
              <v-list-item-action class="mr-4">
                <v-icon v-text="'mdi-check'" :color="'green'" />
              </v-list-item-action>

              <v-list-item-title
                class="text-wrap break-word normal-line-height"
                v-html="informAdminAboutChangedPassword"
              />
            </v-list-item>
            <v-list-item class="mb-5 yellow lighten-5 py-2">
              <v-list-item-action class="mr-4">
                <v-icon v-text="'mdi-lightbulb-on-10'" :color="'accent'" />
              </v-list-item-action>

              <v-list-item-title
                class="text-wrap break-word normal-line-height"
                v-html="informAdminAboutActivationWithEmail"
              />
            </v-list-item>
          </v-list>

          <v-list
            v-if="!isAnotherPasswordChangeMethod"
            :class="`${$vuetify.breakpoint.mobile ? 'px-4' : 'px-0'} mt-4`"
          >
            <v-list-item
              v-for="({ title }, index) in passwordChangedTips"
              :key="index"
              class="mb-5 yellow lighten-5 py-2"
            >
              <v-list-item-action class="mr-4">
                <v-icon v-text="'mdi-lightbulb-on-10'" :color="'accent'" />
              </v-list-item-action>

              <v-list-item-title
                class="text-wrap break-word normal-line-height"
                v-html="$ml.get(title)"
              />
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense align="center" class="px-4">
      <v-col
        :order="$vuetify.breakpoint.xs ? 2 : 1"
        cols="12"
        xs="12"
        sm="3"
        md="3"
        lg="3"
        :class="$vuetify.breakpoint.xs ? 'pt-2' : ''"
      >
        <v-btn
          text
          :large="$vuetify.breakpoint.xs"
          :block="$vuetify.breakpoint.xs"
          color="secondary lighten-2"
          class="mx-auto text-none text-body-2 text-md-body-1 font-weight-medium"
          @click="$emit('previous')"
          :disabled="loadingNotification"
        >
          {{ $ml.get("previous") }}
        </v-btn>
      </v-col>
      <v-col
        :order="$vuetify.breakpoint.xs ? 1 : 2"
        cols="12"
        xs="12"
        sm="9"
        md="9"
        lg="9"
      >
        <v-card-actions class="pa-0 ma-0 justify-end">
          <v-btn
            x-large
            color="accent"
            :class="`text-none text-body-2 text-md-body-1 font-weight-bold px-8`"
            :loading="loading"
            :block="$vuetify.breakpoint.xs"
            @click="executeActionsStep"
            :disabled="loadingNotification"
          >
            <span v-if="isAnotherPasswordChangeMethod">
              {{ $ml.get("uppercase_send_email") }}
              {{ $ml.get("and") }}
              {{ $ml.get("setUsersLogout") }}
            </span>
            <span v-else>
              {{ $ml.get("setUsersLogout") }}
            </span>
            <v-icon v-text="'mdi-chevron-right'" right />
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>

    <NotifyControlActivationDialog
      :show="showDialog"
      :loading="loadingNotification"
      @close="showDialog = false"
      @update:loading="loadingNotification = $event"
    />
  </v-card>
</template>
<script>
import PasswordChangedIlustration from "@/components/activation/second_step/ilustrations/PasswordChangedIlustration";
import PasswordCopy from "@/components/activation/second_step/PasswordCopy.vue";
import NotifyControlActivationDialog from "@/components/activation/second_step/NotifyControlActivationDialog.vue";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "PasswordChangedStep",

  components: {
    PasswordChangedIlustration,
    PasswordCopy,
    NotifyControlActivationDialog,
  },

  data() {
    return {
      loading: false,
      loadingNotification: false,
      showDialog: false,
      informAdminAboutTheRandomPasswords: this.$ml.get(
        "informAdminAboutTheRandomPasswords"
      ),
      temporaryPasswordWarningCoWorkersLabel: this.$ml.get(
        "temporaryPasswordWarningCoWorkers"
      ),
      informAdminAboutActivationWithEmail: this.$ml.get(
        "informAdminAboutActivationWithEmail"
      ),
      informAdminAboutChangedPassword: this.$ml.get(
        "informAdminAboutChangedPassword"
      ),
    };
  },

  methods: {
    ...mapActions([
      "sendUsersEmailWithNewPassword",
      "sendUsersEmailWithLinkToChangePassword",
    ]),

    async executeActionsStep() {
      this.loading = true;
      if (this.askedGenerateRandomPassword && !this.sharedRandomPassword) {
        await this.sendUsersEmailWithNewPassword().then(() =>
          this.$emit("next")
        );

        this.loading = false;
      } else if (this.askedSendLinkToChangePassword) {
        await this.sendUsersEmailWithLinkToChangePassword().then(() =>
          this.$emit("next")
        );
      }
      this.$emit("next");
      this.loading = false;
    },
  },

  computed: {
    ...mapGetters([
      "askedGenerateRandomPassword",
      "askedSendLinkToChangePassword",
      "sharedRandomPassword",
    ]),

    passwordChangedTips() {
      return [
        {
          title: "temporaryPasswordWarningCoWorkers",
        },
        {
          title: "temporaryPasswordFutureChanges",
        },
      ];
    },

    isAnotherPasswordChangeMethod() {
      return (
        this.askedGenerateRandomPassword || this.askedSendLinkToChangePassword
      );
    },
  },
};
</script>
