<template>
  <v-scale-transition>
    <v-card class="pb-4 rounded-t-0">
      <v-row justify-items-center align="center" class="mb-3">
        <v-col cols="12" sm="12" md="6" class="px-5 pb-0">
          <v-img contain :src="shift" max-width="600" class="mx-auto mt-3" />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="6"
          class="pr-5 pb-0 d-flex flex-column align-center pt-8"
          style="width: 100%"
        >
          <v-card-title
            class="primary--text text-h5 text-sm-h4 font-weight-bold text-center break-word text-wrap normal-line-height"
            :style="`${$vuetify.breakpoint.mdAndUp ? 'max-width: 500px' : ''}`"
          >
            {{ $ml.get("activationSessionOneTitle") }}
          </v-card-title>

          <v-list class="px-2 mt-4">
            <v-list-item
              v-for="({ title }, index) in getStartedList"
              :key="index"
              :class="`yellow lighten-5 mb-6`"
            >
              <v-list-item-action class="mr-4">
                <v-icon
                  v-text="'mdi-lightbulb-on-10'"
                  size="26"
                  color="accent"
                />
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title
                  class="text-wrap break-word normal-line-height text-body-2 text-sm-body-1 secondary--text text--lighten-1 mt-1"
                  v-html="$ml.get(title)"
                />
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <UsersWithoutShifts class="mx-2" hide-action />
        </v-col>
      </v-row>

      <v-row dense align="center" :class="`ma-0 px-4`">
        <v-col
          :order="$vuetify.breakpoint.xs ? 2 : 1"
          cols="12"
          xs="12"
          sm="4"
          md="4"
          lg="7"
          :class="$vuetify.breakpoint.xs ? 'pt-2' : ''"
        >
          <v-btn
            text
            :block="$vuetify.breakpoint.xs"
            :large="$vuetify.breakpoint.xs"
            color="secondary lighten-2"
            class="mx-auto text-none text-body-2 text-md-body-1 font-weight-medium"
            @click="$emit('previous')"
          >
            {{ $ml.get("previous") }}
          </v-btn>
        </v-col>
        <v-col
          :order="$vuetify.breakpoint.xs ? 1 : 2"
          cols="12"
          xs="12"
          sm="8"
          md="8"
          lg="5"
          class=""
        >
          <v-row align="center">
            <v-col
              :class="`d-flex justify-end `"
              :order="$vuetify.breakpoint.xs ? 2 : 1"
              cols="12"
              xs="12"
              sm="3"
              md="3"
              lg="1"
            >
              <v-btn
                x-large
                outlined
                color="accent"
                block
                :class="`text-none text-body-2 text-md-body-1 font-weight-bold px-8`"
                @click="$emit('skip')"
              >
                {{ $ml.get("skipStep") }}
              </v-btn>
            </v-col>
            <v-col
              :order="$vuetify.breakpoint.xs ? 1 : 2"
              cols="12"
              xs="12"
              sm="9"
              md="9"
              lg="11"
            >
              <ShiftSettingsRedirectDialog block />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-scale-transition>
</template>
<script>
import ShiftSettingsRedirectDialog from "@/components/activation/first_step/ShiftSettingsRedirectDialog";
import UsersWithoutShifts from "@/components/general/UsersWithoutShifts";

export default {
  name: "UsersAccessInstructionsStep",

  components: { ShiftSettingsRedirectDialog, UsersWithoutShifts },

  data() {
    return {
      shift: require("@/assets/images/initial.png"),
    };
  },

  computed: {
    getStartedList() {
      return [
        {
          title: "shiftTip",
        },
        {
          title: "shiftMembersTip",
        },
        {
          title: "shiftChanges",
        },
      ];
    },
  },
};
</script>
