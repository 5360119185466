<template>
  <v-card class="pb-5 py-3 rounted-t-0" width="100%">
    <v-row justify="center" align="center" class="mb-8">
      <v-col cols="12" sm="12" md="6">
        <SelectDomainIlustration />
      </v-col>

      <v-col cols="12" sm="12" md="6" class="">
        <v-card
          flat
          class="d-flex flex-column align-center justify-center"
          style="width: 100%"
        >
          <v-card-title
            class="primary--text text-h4 font-weight-bold text-center break-word text-wrap normal-line-height text-center"
          >
            {{ $ml.get("activation_domain") }}
          </v-card-title>

          <v-card-text
            class="break-word text-body-1 normal-line-height mb-3 mt-1"
          >
            {{ $ml.get("activation_domain_description") }}
          </v-card-text>

          <v-autocomplete
            v-model="domainSelected"
            :items="domainsList"
            item-text="name"
            item-value="key"
            :rules="hasSelectedDomain"
            auto-select-first
            filled
            dense
            clearable
            hide-no-data
            hide-selected
            :label="$ml.get('domain')"
            :placeholder="$ml.get('select_a_domain')"
            class="ma-0 px-5 mt-6"
            style="width: 100%"
          >
            <template v-slot:item="{ item }">
              {{ item }}
            </template>
            <template v-slot:selection="{ item }">
              <v-chip small>
                {{ item }}
              </v-chip>
            </template>
          </v-autocomplete>

          <v-list
            :class="`${$vuetify.breakpoint.mobile ? 'px-4' : 'px-0'} mt-4`"
          >
            <v-list-item
              v-for="({ title }, index) in domainChangeTips"
              :key="index"
              class="mb-5 yellow lighten-5 py-2"
            >
              <v-list-item-action class="mr-4">
                <v-icon v-text="'mdi-lightbulb-on-10'" :color="'accent'" />
              </v-list-item-action>

              <v-list-item-title
                class="text-wrap break-word normal-line-height"
                v-html="$ml.get(title)"
              />
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense align="center" class="px-4">
      <v-col
        :order="$vuetify.breakpoint.xs ? 2 : 1"
        cols="12"
        xs="12"
        sm="3"
        md="3"
        lg="3"
        :class="$vuetify.breakpoint.xs ? 'pt-2' : ''"
      >
        <v-btn
          text
          :large="$vuetify.breakpoint.xs"
          :block="$vuetify.breakpoint.xs"
          color="secondary lighten-2"
          class="mx-auto text-none text-body-2 text-md-body-1 font-weight-medium"
          @click="$emit('previous')"
        >
          {{ $ml.get("previous") }}
        </v-btn>
      </v-col>
      <v-col
        :order="$vuetify.breakpoint.xs ? 1 : 2"
        cols="12"
        xs="12"
        sm="9"
        md="9"
        lg="9"
      >
        <v-card-actions class="pa-0 ma-0 justify-end">
          <v-btn
            x-large
            color="accent"
            :class="`text-none text-body-2 text-md-body-1 font-weight-bold px-8`"
            :block="$vuetify.breakpoint.xs"
            @click="$emit('next')"
            :disabled="!domainSelected"
          >
            <span> {{ $ml.get("next") }}</span>
            <v-icon v-text="'mdi-chevron-right'" right />
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import SelectDomainIlustration from "@/components/activation/third_step/ilustrations/SelectDomainIlustration";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "SelectDomainStep",

  components: {
    SelectDomainIlustration,
  },

  data() {
    return {
      hasSelectedDomain: [(v) => !!v || this.$ml.get("select_a_domain")],
    };
  },

  computed: {
    ...mapGetters(["domainsList", "domainToActivate"]),

    domainChangeTips() {
      return [
        {
          title: "selectionDomainInfo",
        },
      ];
    },

    domainSelected: {
      get() {
        return this.domainToActivate;
      },
      set(value) {
        this.setDomainToActivate(value);
      },
    },
  },

  methods: {
    ...mapMutations(["setDomainToActivate"]),
  },
};
</script>
