<template>
  <svg
    class="animated"
    id="freepik_stories-feeling-sorry"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
  >
    <g
      id="freepik--background-complete--inject-2"
      class="animable"
      style="transform-origin: 244.565px 255.734px"
    >
      <path
        d="M95.36,353.55a43,43,0,0,0-18.46-6.36c-3-.31-6.31-.22-8.73,1.61a1.76,1.76,0,0,0-.9,1.59,1.91,1.91,0,0,0,.85,1.09c7.51,5.73,18.88,6.46,27.24,2.07"
        style="fill: rgb(224, 224, 224); transform-origin: 81.3146px 351.718px"
        id="ell55zao304wi"
        class="animable"
      ></path>
      <path
        d="M94.89,353A89.51,89.51,0,0,1,83,342.53a30.06,30.06,0,0,1-7.48-13.83c-.32-1.57-.42-3.37.59-4.62,9.51,7.42,17,17,18.81,28.94"
        style="fill: rgb(224, 224, 224); transform-origin: 85.1093px 338.55px"
        id="elb9ip2863icl"
        class="animable"
      ></path>
      <path
        d="M95.27,353.47c-.72-5.2-1-10.54.22-15.65s4-10,8.34-12.92c1.64,3.84.93,8.26-.22,12.27a59.8,59.8,0,0,1-8.27,17.41"
        style="fill: rgb(224, 224, 224); transform-origin: 99.7233px 339.74px"
        id="el524czoh4gaa"
        class="animable"
      ></path>
      <path
        d="M104.58,370.17c-5.65.69-10.58,3.67-14.8,7.5a27.89,27.89,0,0,0-8.47,14.54c5.55-.35,10.25-4.26,13.67-8.65s5.78-9.2,9.53-13.3"
        style="fill: rgb(224, 224, 224); transform-origin: 92.945px 381.19px"
        id="elpoo9kr2iwtn"
        class="animable"
      ></path>
      <path
        d="M106.61,372.84a36.48,36.48,0,0,1,4.22-34.54c3.22,11.46,2,24.41-4.22,34.54"
        style="fill: rgb(224, 224, 224); transform-origin: 108.311px 355.57px"
        id="elhz0tcrlwxb"
        class="animable"
      ></path>
      <path
        d="M118.92,387.5A26.4,26.4,0,0,0,93.67,402c-.31.62-.59,1.42-.17,2a1.9,1.9,0,0,0,1.68.49c5-.32,9.38-3.32,13-6.72s6.81-7.35,10.83-10.3"
        style="fill: rgb(224, 224, 224); transform-origin: 106.149px 395.987px"
        id="eltqgjxwqi5kk"
        class="animable"
      ></path>
      <path
        d="M118.53,386.71a47,47,0,0,1-.47-26.27c.92-3.39,2.48-6.95,5.6-8.53a1.07,1.07,0,0,1,.86-.15,1.09,1.09,0,0,1,.54.63,15.77,15.77,0,0,1,1.33,7.53,48,48,0,0,1-1.18,7.63c-1,4.67-1.51,9.68-4.07,13.7a27.22,27.22,0,0,0-1.63,3c-.48,1-.48,1.38-.93,2.19"
        style="fill: rgb(224, 224, 224); transform-origin: 121.422px 369.219px"
        id="elh9ptjrvl74"
        class="animable"
      ></path>
      <path
        d="M151.12,391.56c-2.41-9.18.15-20.32,5.94-27.85.42-.55,1-1.15,1.68-1.08,1.07.1,1.35,1.52,1.3,2.59a50.33,50.33,0,0,1-8.92,26.34"
        style="fill: rgb(224, 224, 224); transform-origin: 155.131px 377.092px"
        id="elisvt95u32x"
        class="animable"
      ></path>
      <path
        d="M154.06,393.57c11.16,2.9,24,.08,33.24-6.76a26.94,26.94,0,0,0-18-2.21c-6,1.38-11.5,4.07-15.24,9"
        style="fill: rgb(224, 224, 224); transform-origin: 170.68px 389.323px"
        id="el6i844y31i94"
        class="animable"
      ></path>
      <path
        d="M156.54,387.45c6.25-10.19,18.23-16.84,30.17-17.44-1.38,4.85-5.37,8.63-9.87,10.91s-9.53,3.27-14.48,4.23c-1.93.38-4.57.79-5.82,2.3"
        style="fill: rgb(224, 224, 224); transform-origin: 171.625px 378.73px"
        id="elejr2qr35p1p"
        class="animable"
      ></path>
      <path
        d="M151.74,357.78c1.69-5.8,3.39-11.65,3.86-17.67s-.39-12.31-3.52-17.48c-5.84,11.3-6.43,24.5-.51,35.75"
        style="fill: rgb(224, 224, 224); transform-origin: 151.563px 340.505px"
        id="elrit5037nnya"
        class="animable"
      ></path>
      <path
        d="M171.4,347.84c6.82-12.4,20.75-22.31,34.84-23.7-5.43,13.53-20.26,24-34.84,23.7"
        style="fill: rgb(224, 224, 224); transform-origin: 188.82px 335.993px"
        id="el486he3w3ktl"
        class="animable"
      ></path>
      <path
        d="M167.1,350.28c10.18,1.55,18.86,8.05,28.37,12a12.73,12.73,0,0,0,6.42,1.23,5,5,0,0,0,3.14-8.88,13.63,13.63,0,0,0-4.66-2.83C190,347.48,178.89,347.37,168,350"
        style="fill: rgb(224, 224, 224); transform-origin: 186.988px 355.893px"
        id="el7c3xu70w96f"
        class="animable"
      ></path>
      <path
        d="M161.21,357.42c2.3.66,3.09,1.85,4.92,3.4a53.1,53.1,0,0,0,21.51,11.06c.82.2,1.9.28,2.31-.46s-.1-1.43-.56-2a29.6,29.6,0,0,0-13-8.82c-5-1.85-9.27-4.08-15.19-3.6"
        style="fill: rgb(224, 224, 224); transform-origin: 175.65px 364.476px"
        id="elzpfpbs20q4a"
        class="animable"
      ></path>
      <path
        d="M154.56,314.29a40.72,40.72,0,0,0,31.21,10.62,2.16,2.16,0,0,0,1.43-.51c.78-.86-.16-2.18-1.08-2.89-9.17-7.16-20.29-10.12-31.56-7.22"
        style="fill: rgb(224, 224, 224); transform-origin: 171.025px 319.129px"
        id="elj6lwbzluci"
        class="animable"
      ></path>
      <path
        d="M162.44,311.4A43.5,43.5,0,0,1,201.39,305c-4.9,4.83-11.71,7.43-18.56,8.14a58.47,58.47,0,0,1-20.39-1.72"
        style="fill: rgb(224, 224, 224); transform-origin: 181.915px 308.169px"
        id="elod6ed50k98o"
        class="animable"
      ></path>
      <path
        d="M159.31,291.58c-.9-9.16-.32-16.39-.95-25.59-.05-.74-.21-1.62-.9-1.89-1-.36-1.76.81-2.07,1.79a29.31,29.31,0,0,0,3.91,25.49"
        style="fill: rgb(224, 224, 224); transform-origin: 156.681px 277.807px"
        id="elme7s0ivb7d"
        class="animable"
      ></path>
      <path
        d="M158.65,292.6c6.21-3.84,10.87-8.86,15.17-14.75,3-4.1,5.53-9,4.85-14.05-4.21-.08-8.21,2.24-10.95,5.44s-4.36,7.19-5.58,11.22-2.06,8.17-3.49,12.14"
        style="fill: rgb(224, 224, 224); transform-origin: 168.716px 278.199px"
        id="elzi14hun0jh9"
        class="animable"
      ></path>
      <path
        d="M173.56,288.45a122.07,122.07,0,0,0,37.34-22c-4.46-1.79-9.57-.72-14,1.08-10.18,4.12-18.51,11.06-23.51,20.84"
        style="fill: rgb(224, 224, 224); transform-origin: 192.145px 276.99px"
        id="elwl20e4smhu"
        class="animable"
      ></path>
      <path
        d="M162.84,297.61c6.9.75,13.9,1.24,20.74.1s13.07-3.79,19.36-6.43a2.3,2.3,0,0,0,1.31-1,1.52,1.52,0,0,0-.47-1.66,4,4,0,0,0-1.65-.77c-6.83-1.91-14.2-1-20.92,1.25a96.87,96.87,0,0,0-18.37,8.51"
        style="fill: rgb(224, 224, 224); transform-origin: 183.585px 292.599px"
        id="elwxokqglv3k"
        class="animable"
      ></path>
      <path
        d="M123.48,314.6c-4.7-9.49-4.4-20.57-.33-30.36,2.86,4.36,3.21,9.91,2.68,15.1s-2.1,10.52-2.2,15.73"
        style="fill: rgb(224, 224, 224); transform-origin: 123.032px 299.655px"
        id="elz87mko8uby"
        class="animable"
      ></path>
      <path
        d="M104.69,305.12a42.33,42.33,0,0,0-31.46,13.41c5.86.92,11.9-.59,17.23-3.2s10.07-6.25,14.76-9.88"
        style="fill: rgb(224, 224, 224); transform-origin: 89.225px 311.959px"
        id="eldmhvgkp77"
        class="animable"
      ></path>
      <path
        d="M92.06,300.9c-5.74-.47-11.27-1.18-16.79.52S64.72,307,62.46,312.27a30.31,30.31,0,0,0,15.75-3,91.91,91.91,0,0,0,13.85-8.4"
        style="fill: rgb(224, 224, 224); transform-origin: 77.26px 306.355px"
        id="elxv3roljp0lp"
        class="animable"
      ></path>
      <path
        d="M110.76,301.09c-5.16-3.38-10.47-6.83-16.49-8.23s-13-.37-17.18,4.17c4.87,2.85,10.78,3.19,16.42,3.19s12.57-.07,17.9,1.81"
        style="fill: rgb(224, 224, 224); transform-origin: 94.25px 297.154px"
        id="elczsyk96w58m"
        class="animable"
      ></path>
      <path
        d="M110.79,301.37c-8.83-8.68-12.18-24.26-8.63-36.12a55,55,0,0,1,8.63,36.12"
        style="fill: rgb(224, 224, 224); transform-origin: 105.943px 283.31px"
        id="elfthb75qooal"
        class="animable"
      ></path>
      <path
        d="M135.09,276.79c1.66-3.88,2.84-7.94,4.33-11.89A34,34,0,0,1,145.6,254a16.26,16.26,0,0,1,10.94-5.67,44.1,44.1,0,0,1-21.24,28.57"
        style="fill: rgb(224, 224, 224); transform-origin: 145.815px 262.615px"
        id="ellvdoogfimjb"
        class="animable"
      ></path>
      <path
        d="M130.69,281.91c-2.76-3.8-5.94-9-9.68-11.78s-8.61-4.55-13.17-3.46a30.68,30.68,0,0,0,22.85,15.24"
        style="fill: rgb(224, 224, 224); transform-origin: 119.265px 274.127px"
        id="elbovcxyyn3fm"
        class="animable"
      ></path>
      <path
        d="M131.57,277.1c-1.81-4.59-3.63-9.23-4.36-14.11s-.28-10.1,2.21-14.35c4.85,9.16,6.41,19,2.4,28.57"
        style="fill: rgb(224, 224, 224); transform-origin: 130.499px 262.925px"
        id="elj352vikkcnr"
        class="animable"
      ></path>
      <path
        d="M135.13,248.64c2.75-9.09,11.07-15.78,20.05-18.85a7.52,7.52,0,0,1,3.52-.53,3,3,0,0,1,2.59,2.16c.33,1.55-.95,2.93-2.15,4a68.44,68.44,0,0,1-24,13.26"
        style="fill: rgb(224, 224, 224); transform-origin: 148.236px 238.955px"
        id="ell5cdqaju5a"
        class="animable"
      ></path>
      <path
        d="M135.23,241.2c.35-5.86.72-11.8,2.62-17.35s5.58-10.76,10.92-13.19c.91,5.92-1.7,11.8-4.85,16.9s-6.94,9.9-8.83,15.58"
        style="fill: rgb(224, 224, 224); transform-origin: 142.022px 226.9px"
        id="elu9va1pf7uhg"
        class="animable"
      ></path>
      <path
        d="M135.09,245.24c-2.27-6.63-2.51-15-6.47-20.81-2.78-4-7.22-6.78-9.6-11.05a16.8,16.8,0,0,0,.55,12.55,36.36,36.36,0,0,0,7.34,10.5c2.94,3.13,5.45,5.49,8.18,8.81"
        style="fill: rgb(224, 224, 224); transform-origin: 126.576px 229.31px"
        id="elqhhkqljnpsj"
        class="animable"
      ></path>
      <path
        d="M135.13,440.28c-.17,0-.33-44.18-.34-98.67s.12-98.68.3-98.68.33,44.17.34,98.68S135.31,440.28,135.13,440.28Z"
        style="fill: rgb(224, 224, 224); transform-origin: 135.11px 341.605px"
        id="elqbo5ste6tne"
        class="animable"
      ></path>
      <path
        d="M173.74,307.93a8.4,8.4,0,0,1-1.66.63l-4.57,1.54c-3.86,1.31-9.15,3.21-14.9,5.57s-11.06,4.42-14.11,7.07a10.47,10.47,0,0,0-2.84,3.75c-.44,1-.51,1.67-.57,1.66a1.16,1.16,0,0,1,0-.46,6.74,6.74,0,0,1,.32-1.29,10,10,0,0,1,2.77-4,24.52,24.52,0,0,1,6.12-3.8c2.45-1.14,5.17-2.34,8.05-3.52,5.76-2.36,11.1-4.2,15-5.41,1.95-.61,3.54-1.06,4.64-1.35A9.83,9.83,0,0,1,173.74,307.93Z"
        style="fill: rgb(224, 224, 224); transform-origin: 154.403px 318.04px"
        id="elrodw75bfoa"
        class="animable"
      ></path>
      <path
        d="M186.4,276.85a3.83,3.83,0,0,1-.47.49l-1.42,1.34-5.29,4.8L161.6,299.17c-6.87,6.12-12.81,12-16.93,16.37-2.06,2.2-3.69,4-4.8,5.3l-1.28,1.46a2.54,2.54,0,0,1-.47.48,2.49,2.49,0,0,1,.37-.56c.27-.36.66-.89,1.19-1.54,1-1.33,2.63-3.2,4.66-5.44a222.3,222.3,0,0,1,16.84-16.55c6.9-6.1,13.18-11.59,17.74-15.54l5.43-4.66,1.5-1.24C186.19,277,186.38,276.83,186.4,276.85Z"
        style="fill: rgb(224, 224, 224); transform-origin: 162.26px 299.814px"
        id="el0slt3xthujg"
        class="animable"
      ></path>
      <path
        d="M154.18,305.39c-.18-.08,1.26-3.39,2.69-7.59s2.12-7.73,2.31-7.7-.24,3.66-1.71,7.91a53.69,53.69,0,0,1-2.13,5.31A8,8,0,0,1,154.18,305.39Z"
        style="fill: rgb(224, 224, 224); transform-origin: 156.693px 297.745px"
        id="el6idyljaa8rj"
        class="animable"
      ></path>
      <path
        d="M135.17,332.14a9.42,9.42,0,0,1-1.06-1.23l-2.71-3.49c-2.28-2.94-5.43-7-9-11.46s-6.78-8.45-9.13-11.34l-2.78-3.43a6.9,6.9,0,0,1-1-1.32,9.25,9.25,0,0,1,1.14,1.15c.71.77,1.71,1.9,2.93,3.31,2.45,2.82,5.75,6.77,9.29,11.23s6.64,8.57,8.83,11.59c1.09,1.51,2,2.74,2.55,3.61A7.13,7.13,0,0,1,135.17,332.14Z"
        style="fill: rgb(224, 224, 224); transform-origin: 122.33px 316.005px"
        id="elqoscxeqiire"
        class="animable"
      ></path>
      <path
        d="M126.71,321.27c-.11.11-2.37-2.57-3.55-6.64a19,19,0,0,1-.8-6.7l.28,0c-.13.51-.22.78-.27.77s-.05-.29,0-.82l.28,0a30.26,30.26,0,0,0,1.13,6.52C124.91,318.39,126.88,321.16,126.71,321.27Z"
        style="fill: rgb(224, 224, 224); transform-origin: 124.523px 314.577px"
        id="el9c2g7cnpet"
        class="animable"
      ></path>
      <path
        d="M115.78,307.52a11.36,11.36,0,0,0-2.48-1.44,16.59,16.59,0,0,0-6.87-.55,42.31,42.31,0,0,0-6.93,1.12,20.45,20.45,0,0,1-2.85.68,10.51,10.51,0,0,1,2.73-1.11,31.19,31.19,0,0,1,7-1.32,15,15,0,0,1,7.09.76A4.45,4.45,0,0,1,115.78,307.52Z"
        style="fill: rgb(224, 224, 224); transform-origin: 106.215px 306.144px"
        id="elp8jyx4osc6"
        class="animable"
      ></path>
      <path
        d="M113.19,304.19c0,.1-1.45-.58-3.8-1.38a38.73,38.73,0,0,0-9.46-1.95,39.29,39.29,0,0,0-9.65.49c-2.45.44-3.93.89-4,.79a3.58,3.58,0,0,1,1-.43,21.71,21.71,0,0,1,2.87-.8,33.41,33.41,0,0,1,9.79-.69,33.79,33.79,0,0,1,9.57,2.16,24.55,24.55,0,0,1,2.71,1.23A3.69,3.69,0,0,1,113.19,304.19Z"
        style="fill: rgb(224, 224, 224); transform-origin: 99.735px 302.16px"
        id="elasaixcb49lj"
        class="animable"
      ></path>
      <path
        d="M135.09,290.1c-.17,0-1.41-4.26-2.77-9.61s-2.32-9.73-2.15-9.77,1.41,4.26,2.77,9.61S135.26,290.05,135.09,290.1Z"
        style="fill: rgb(224, 224, 224); transform-origin: 132.63px 280.41px"
        id="el1zfq7kiir3e"
        class="animable"
      ></path>
      <path
        d="M135.26,290.1c-.19.06-1-2.82-3.06-5.76a25.2,25.2,0,0,0-4.52-4.65,4.1,4.1,0,0,1,1.75.89,14.3,14.3,0,0,1,3.29,3.4C134.88,287,135.42,290.09,135.26,290.1Z"
        style="fill: rgb(224, 224, 224); transform-origin: 131.483px 284.895px"
        id="el5pj9bhs768k"
        class="animable"
      ></path>
      <path
        d="M135.13,416.73c-.07,0-.2-1.07-.7-2.94a41,41,0,0,0-3-7.65c-3-6.24-8.6-14.15-15.66-22.07-3.5-4-6.89-7.77-9.77-11.37a79.55,79.55,0,0,1-7-10.08,48.21,48.21,0,0,1-3.53-7.48c-.32-.92-.56-1.64-.69-2.14a3.47,3.47,0,0,1-.18-.76c.06,0,.38,1,1.11,2.81a53.86,53.86,0,0,0,3.68,7.34,84.34,84.34,0,0,0,7.06,9.94c2.89,3.57,6.29,7.32,9.79,11.32,7.08,7.94,12.62,16,15.59,22.29a39.16,39.16,0,0,1,2.79,7.79c.2,1,.34,1.7.39,2.21A3.87,3.87,0,0,1,135.13,416.73Z"
        style="fill: rgb(224, 224, 224); transform-origin: 114.865px 384.485px"
        id="el2614hr8meno"
        class="animable"
      ></path>
      <path
        d="M170,377.32a17.26,17.26,0,0,1-1.83,1,39.37,39.37,0,0,0-4.74,3.13,54.65,54.65,0,0,0-6.23,5.68c-2.19,2.31-4.44,5-6.74,7.87-4.59,5.76-8.89,10.86-11.46,14.94-1.31,2-2.26,3.72-2.88,4.92a15.36,15.36,0,0,1-1,1.84,1.79,1.79,0,0,1,.17-.52c.14-.33.32-.82.62-1.43a43.94,43.94,0,0,1,2.72-5c2.49-4.17,6.76-9.35,11.35-15.1,2.3-2.88,4.58-5.57,6.81-7.88a50.8,50.8,0,0,1,6.38-5.63,32.54,32.54,0,0,1,4.9-3c.6-.31,1.1-.5,1.42-.64A1.79,1.79,0,0,1,170,377.32Z"
        style="fill: rgb(224, 224, 224); transform-origin: 152.56px 397.01px"
        id="elii9vu9tlhu"
        class="animable"
      ></path>
      <path
        d="M170.7,390.1a24,24,0,0,1-3.86.56,51.25,51.25,0,0,0-9.17,1.81,27.4,27.4,0,0,0-8.2,4.31c-1.9,1.46-2.79,2.67-2.88,2.6a3.13,3.13,0,0,1,.58-.89,14.25,14.25,0,0,1,2-2.06,24.88,24.88,0,0,1,8.3-4.57,41.28,41.28,0,0,1,9.31-1.64A21.6,21.6,0,0,1,170.7,390.1Z"
        style="fill: rgb(224, 224, 224); transform-origin: 158.645px 394.722px"
        id="elpapi22ltggn"
        class="animable"
      ></path>
      <path
        d="M147.72,398c-.2-.11,1.54-2.77,2.89-6.35s1.57-6.78,1.8-6.74a6.42,6.42,0,0,1-.07,2.07,22.29,22.29,0,0,1-1.13,4.89,23.49,23.49,0,0,1-2.24,4.48C148.28,397.4,147.79,398,147.72,398Z"
        style="fill: rgb(224, 224, 224); transform-origin: 150.083px 391.455px"
        id="el5ras8gc92zi"
        class="animable"
      ></path>
      <path
        d="M181.32,343a3.28,3.28,0,0,1-.62.33l-1.81.82c-.8.34-1.77.78-2.85,1.35s-2.37,1.14-3.7,1.94l-2.11,1.2c-.73.42-1.45.92-2.22,1.4-1.55.94-3.11,2.1-4.77,3.29a81.67,81.67,0,0,0-18.39,19c-1.12,1.7-2.23,3.3-3.12,4.89-.46.78-.93,1.52-1.33,2.27l-1.12,2.15c-.75,1.35-1.29,2.64-1.81,3.75s-.94,2.09-1.25,2.9-.57,1.35-.77,1.84a3.46,3.46,0,0,1-.3.63,2.71,2.71,0,0,1,.18-.67l.65-1.89c.28-.83.65-1.82,1.16-3s1-2.44,1.74-3.82l1.09-2.18c.39-.75.85-1.5,1.3-2.3.88-1.61,2-3.23,3.1-5a78.52,78.52,0,0,1,18.52-19.16c1.68-1.18,3.26-2.34,4.84-3.27.78-.47,1.51-1,2.26-1.38l2.14-1.17c1.35-.77,2.64-1.33,3.75-1.86a31.71,31.71,0,0,1,2.91-1.26l1.86-.71A2.74,2.74,0,0,1,181.32,343Z"
        style="fill: rgb(224, 224, 224); transform-origin: 158.235px 366.88px"
        id="elx7q9q3u72yh"
        class="animable"
      ></path>
      <path
        d="M153.71,361.5a4.4,4.4,0,0,1,1.52-2.26,9.56,9.56,0,0,1,6.11-2.35,16.61,16.61,0,0,1,6.44,1.26,9.41,9.41,0,0,1,2.44,1.18,14.54,14.54,0,0,1-2.59-.76,19.43,19.43,0,0,0-6.27-1,9.94,9.94,0,0,0-5.84,2.05A18,18,0,0,0,153.71,361.5Z"
        style="fill: rgb(224, 224, 224); transform-origin: 161.965px 359.195px"
        id="eljr5cd20n8h"
        class="animable"
      ></path>
      <path
        d="M149.58,365.76c-.17-.1,1.38-2.38,1.74-5.66s-.58-5.84-.39-5.89a3.7,3.7,0,0,1,.72,1.64,11.75,11.75,0,0,1-1,8.47C150.12,365.3,149.64,365.8,149.58,365.76Z"
        style="fill: rgb(224, 224, 224); transform-origin: 150.797px 359.986px"
        id="el9newgbulkb"
        class="animable"
      ></path>
      <path
        d="M104.62,423.06c.95,16.88,6,34.05,13.86,51.41h34c7.46-17.18,12.2-34.33,13-51.41Z"
        style="fill: rgb(224, 224, 224); transform-origin: 135.05px 448.765px"
        id="el6wlwld0ulda"
        class="animable"
      ></path>
      <path
        d="M160,450.38c0,.18-11.2.32-25,.32s-25-.14-25-.32,11.2-.32,25-.32S160,450.2,160,450.38Z"
        style="fill: rgb(250, 250, 250); transform-origin: 135px 450.38px"
        id="elalax0e3gpa"
        class="animable"
      ></path>
      <path
        d="M159.28,456.5c0,.17-10.5.31-23.46.31s-23.46-.14-23.46-.31,10.5-.32,23.46-.32S159.28,456.32,159.28,456.5Z"
        style="fill: rgb(250, 250, 250); transform-origin: 135.82px 456.495px"
        id="elfu8v5j6lgmw"
        class="animable"
      ></path>
      <path
        d="M119,434.14c.18,0,.32,3.51.32,7.84s-.14,7.84-.32,7.84-.32-3.51-.32-7.84S118.86,434.14,119,434.14Z"
        style="fill: rgb(250, 250, 250); transform-origin: 119px 441.98px"
        id="elhjqloplplto"
        class="animable"
      ></path>
      <path
        d="M137.27,434.14c.17,0,.32,3.64.32,8.12s-.15,8.12-.32,8.12-.32-3.64-.32-8.12S137.09,434.14,137.27,434.14Z"
        style="fill: rgb(250, 250, 250); transform-origin: 137.27px 442.26px"
        id="elwj98oh6h4pl"
        class="animable"
      ></path>
      <path
        d="M154.38,434c.18,0,.32,3.7.32,8.27s-.14,8.28-.32,8.28-.31-3.7-.31-8.28S154.21,434,154.38,434Z"
        style="fill: rgb(250, 250, 250); transform-origin: 154.385px 442.275px"
        id="el2wx03f3ag2"
        class="animable"
      ></path>
      <path
        d="M164.48,434.14c0,.18-13.15.32-29.35.32s-29.36-.14-29.36-.32,13.14-.32,29.36-.32S164.48,434,164.48,434.14Z"
        style="fill: rgb(250, 250, 250); transform-origin: 135.125px 434.14px"
        id="elwe2ywv1yyoh"
        class="animable"
      ></path>
      <rect
        x="52.16"
        y="54.96"
        width="80.26"
        height="83.66"
        style="fill: rgb(224, 224, 224); transform-origin: 92.29px 96.79px"
        id="el74ugsfje1vf"
        class="animable"
      ></rect>
      <rect
        x="46.72"
        y="54.96"
        width="80.26"
        height="83.66"
        style="fill: rgb(250, 250, 250); transform-origin: 86.85px 96.79px"
        id="el6q6zf1vu1kh"
        class="animable"
      ></rect>
      <path
        d="M127,138.65H46.7V54.94H127Zm-80.26-.05H127V55H46.75Z"
        style="fill: rgb(224, 224, 224); transform-origin: 86.85px 96.795px"
        id="elhwuo2p4nq4h"
        class="animable"
      ></path>
      <rect
        x="57.51"
        y="66.21"
        width="58.68"
        height="61.17"
        style="fill: rgb(255, 255, 255); transform-origin: 86.85px 96.795px"
        id="elug4zk9z1d3"
        class="animable"
      ></rect>
      <path
        d="M57.51,127.79a9.62,9.62,0,0,1-1.45,1.68c-.93,1-2.25,2.33-3.73,3.78S49.5,136,48.5,136.92a8.83,8.83,0,0,1-1.7,1.41,9.39,9.39,0,0,1,1.45-1.67c.93-1,2.25-2.34,3.73-3.79s2.84-2.76,3.84-3.68A8.87,8.87,0,0,1,57.51,127.79Z"
        style="fill: rgb(224, 224, 224); transform-origin: 52.155px 133.06px"
        id="eldahh0dk3yso"
        class="animable"
      ></path>
      <path
        d="M116.19,127.63a10.13,10.13,0,0,1,1.69,1.45c1,.94,2.32,2.26,3.78,3.73s2.75,2.84,3.67,3.84a9.38,9.38,0,0,1,1.41,1.69,9.23,9.23,0,0,1-1.68-1.44c-1-.93-2.33-2.26-3.79-3.74s-2.75-2.84-3.67-3.84A9.38,9.38,0,0,1,116.19,127.63Z"
        style="fill: rgb(224, 224, 224); transform-origin: 121.465px 132.985px"
        id="eljp8wxo66lwd"
        class="animable"
      ></path>
      <path
        d="M116,66.37a10.21,10.21,0,0,1,1.45-1.68c.94-1,2.25-2.33,3.73-3.78S124,58.16,125,57.24a8.71,8.71,0,0,1,1.7-1.41,9.42,9.42,0,0,1-1.44,1.67c-.94,1-2.26,2.33-3.74,3.79s-2.84,2.76-3.84,3.68A8.52,8.52,0,0,1,116,66.37Z"
        style="fill: rgb(224, 224, 224); transform-origin: 121.35px 61.1px"
        id="el4wt38e8lqsj"
        class="animable"
      ></path>
      <path
        d="M57.35,66.52a9.62,9.62,0,0,1-1.68-1.45c-1-.93-2.33-2.25-3.78-3.73s-2.75-2.83-3.67-3.83a8.86,8.86,0,0,1-1.41-1.69,8.9,8.9,0,0,1,1.67,1.44c1,.93,2.33,2.25,3.79,3.73S55,63.83,56,64.83A9.26,9.26,0,0,1,57.35,66.52Z"
        style="fill: rgb(224, 224, 224); transform-origin: 52.08px 61.17px"
        id="eli2l9mfrzxng"
        class="animable"
      ></path>
      <path
        d="M116,127.38s0-.11,0-.3,0-.49,0-.85q0-1.16,0-3.3c0-2.9,0-7.12-.06-12.47,0-10.71-.07-26-.11-44.25l.22.23-58.51,0h0l.26-.26c0,22.45,0,43.45,0,61.17l-.23-.22,42.37.11,11.91.06,3.15,0,.81,0H115l-3.1,0-11.85.06-42.52.11h-.22v-.22c0-17.72,0-38.72,0-61.17V66h.27L116,66h.23v.23c-.05,18.33-.09,33.65-.12,44.39,0,5.33,0,9.53-.06,12.42,0,1.41,0,2.5,0,3.26,0,.35,0,.62,0,.83A1.39,1.39,0,0,1,116,127.38Z"
        style="fill: rgb(224, 224, 224); transform-origin: 86.77px 96.735px"
        id="elgp19il8bhsp"
        class="animable"
      ></path>
      <rect
        x="77.06"
        y="87"
        width="19.58"
        height="19.58"
        style="fill: rgb(235, 235, 235); transform-origin: 86.85px 96.79px"
        id="eli6iymt5nfuj"
        class="animable"
      ></rect>
      <rect
        x="325.81"
        y="37.02"
        width="116.62"
        height="169.03"
        style="fill: rgb(224, 224, 224); transform-origin: 384.12px 121.535px"
        id="elb5m7lkk533"
        class="animable"
      ></rect>
      <rect
        x="319.96"
        y="37.02"
        width="116.62"
        height="169.03"
        style="fill: rgb(245, 245, 245); transform-origin: 378.27px 121.535px"
        id="elrsrpgngcmo"
        class="animable"
      ></rect>
      <rect
        x="334.45"
        y="55.32"
        width="87.64"
        height="132.42"
        style="fill: rgb(255, 255, 255); transform-origin: 378.27px 121.53px"
        id="elaqao0k44cuc"
        class="animable"
      ></rect>
      <path
        d="M422.09,187.74s0-.23,0-.65,0-1.06,0-1.88c0-1.66,0-4.1,0-7.26,0-6.35,0-15.59-.08-27.28,0-23.35-.08-56.41-.14-95.35l.29.29-87.63,0h0l.32-.32c0,49.95,0,96-.07,132.42l-.25-.25,63.79.13,17.63.06,4.62,0,1.6,0-1.55,0-4.57,0-17.57.07-63.95.13h-.25v-.25c0-36.41,0-82.47-.07-132.42V55h.33l87.63,0h.29v.29c-.06,39-.11,72.15-.14,95.54,0,11.66-.06,20.88-.08,27.21,0,3.14,0,5.56,0,7.21,0,.8,0,1.42,0,1.84S422.09,187.74,422.09,187.74Z"
        style="fill: rgb(224, 224, 224); transform-origin: 378.335px 121.425px"
        id="elm3wv83gc3fm"
        class="animable"
      ></path>
      <path
        d="M436.91,37a22.47,22.47,0,0,1-2.08,3c-1.36,1.81-3.28,4.27-5.47,6.94s-4.25,5-5.77,6.7A20.75,20.75,0,0,1,421,56.29a21.76,21.76,0,0,1,2.22-2.91c1.57-1.89,3.49-4.23,5.62-6.81l5.61-6.81A20.82,20.82,0,0,1,436.91,37Z"
        style="fill: rgb(224, 224, 224); transform-origin: 428.955px 46.645px"
        id="elq1syp3bkjg"
        class="animable"
      ></path>
      <path
        d="M422.54,187.31a108.7,108.7,0,0,0,15.28,19.12"
        style="fill: rgb(255, 255, 255); transform-origin: 430.18px 196.87px"
        id="eliqaryv52o9"
        class="animable"
      ></path>
      <path
        d="M437.82,206.43a17.74,17.74,0,0,1-2.66-2.43,84.27,84.27,0,0,1-10.84-13.56,17.11,17.11,0,0,1-1.78-3.13,24.79,24.79,0,0,1,2.15,2.88c1.28,1.81,3.09,4.28,5.2,6.91s4.12,5,5.59,6.6A22.89,22.89,0,0,1,437.82,206.43Z"
        style="fill: rgb(224, 224, 224); transform-origin: 430.18px 196.87px"
        id="el5mt29j7hh0x"
        class="animable"
      ></path>
      <path
        d="M320,205.91l15.27-18.84"
        style="fill: rgb(255, 255, 255); transform-origin: 327.635px 196.49px"
        id="el3su5d0lun2j"
        class="animable"
      ></path>
      <path
        d="M335.22,187.07c.14.11-3.17,4.41-7.39,9.62s-7.75,9.33-7.88,9.22,3.17-4.42,7.39-9.62S335.09,187,335.22,187.07Z"
        style="fill: rgb(224, 224, 224); transform-origin: 327.585px 196.491px"
        id="elc8xm04ewb7"
        class="animable"
      ></path>
      <path
        d="M320,37c.14-.11,3.46,3.85,7.42,8.86s7.05,9.15,6.91,9.26a109.65,109.65,0,0,1-7.41-8.87A108.61,108.61,0,0,1,320,37Z"
        style="fill: rgb(224, 224, 224); transform-origin: 327.167px 46.0589px"
        id="eleu76a7mbc9m"
        class="animable"
      ></path>
      <rect
        x="365.44"
        y="82.21"
        width="29.34"
        height="83.32"
        style="fill: rgb(224, 224, 224); transform-origin: 380.11px 123.87px"
        id="elnjal8fc7uv"
        class="animable"
      ></rect>
    </g>
    <g
      id="freepik--Plant--inject-2"
      class="animable"
      style="transform-origin: 360.205px 401.368px"
    >
      <path
        d="M374.24,383c-.1.11-.89-.36-1.78-1.07s-1.52-1.38-1.43-1.5.89.36,1.78,1.07S374.33,382.86,374.24,383Z"
        style="fill: rgb(38, 50, 56); transform-origin: 372.635px 381.714px"
        id="elsi3khcbt1w"
        class="animable"
      ></path>
      <path
        d="M398.36,346.91c.15-3.37.24-6.82-.82-10s-3.53-6.15-6.83-6.83c-3.77-.78-7.63,1.6-9.75,4.82s-2.83,7.12-3.46,10.92a386.21,386.21,0,0,0-4.73,79.29l15.43,2.16C395,399.57,397.13,375.38,398.36,346.91Z"
        style="fill: rgb(69, 90, 100); transform-origin: 385.428px 378.6px"
        id="el0etd55rmwm0r"
        class="animable"
      ></path>
      <path
        d="M388.57,329.94s0,.09-.07.25-.16.41-.26.7c-.25.65-.59,1.54-1,2.66-.21.58-.5,1.21-.73,1.93l-.75,2.33c-.27.84-.55,1.74-.86,2.69l-.8,3.05a135,135,0,0,0-3,15.27c-.83,5.8-1.38,12.22-1.81,19-.79,13.52-.91,25.78-1.26,34.65-.16,4.44-.31,8-.48,10.51-.09,1.2-.15,2.15-.2,2.85l-.06.74a1,1,0,0,1,0,.25.72.72,0,0,1,0-.25c0-.2,0-.45,0-.75q0-1.05.09-2.85c.11-2.48.2-6.07.31-10.51.25-8.87.31-21.13,1.1-34.67.43-6.76,1-13.2,1.86-19a126.88,126.88,0,0,1,3.11-15.3l.83-3.05.91-2.68.8-2.33c.24-.72.54-1.34.77-1.92.48-1.11.85-2,1.13-2.63l.3-.68A1,1,0,0,1,388.57,329.94Z"
        style="fill: rgb(38, 50, 56); transform-origin: 382.925px 378.38px"
        id="elxxoc03v8nm"
        class="animable"
      ></path>
      <path
        d="M390,330a11,11,0,0,1,1.89,3.19,23.65,23.65,0,0,1,1.41,10c-.41,8.52-1.18,20.26-2.19,33.23S389,401,388,409.52c-.46,4.23-.85,7.66-1.14,10-.15,1.14-.27,2.05-.35,2.72,0,.28-.08.51-.11.7a.68.68,0,0,1-.05.24.73.73,0,0,1,0-.24l.05-.71c.06-.68.14-1.59.25-2.74.23-2.42.56-5.84,1-10,.81-8.48,1.88-20.21,2.9-33.17s1.84-24.71,2.35-33.21a24,24,0,0,0-1.22-9.88A19.5,19.5,0,0,0,390,330Z"
        style="fill: rgb(38, 50, 56); transform-origin: 389.853px 376.59px"
        id="el4su7eo40215"
        class="animable"
      ></path>
      <path
        d="M400.69,369.87a8.6,8.6,0,0,1-2.92-.76,8.51,8.51,0,0,1-2.74-1.27,8.18,8.18,0,0,1,2.92.75A8.3,8.3,0,0,1,400.69,369.87Z"
        style="fill: rgb(38, 50, 56); transform-origin: 397.86px 368.855px"
        id="el0783boykqwyp"
        class="animable"
      ></path>
      <path
        d="M402.55,354.15a18.72,18.72,0,0,1-5.79,2.59,18.12,18.12,0,0,1,5.79-2.59Z"
        style="fill: rgb(38, 50, 56); transform-origin: 399.655px 355.445px"
        id="eldzlpzc1qe"
        class="animable"
      ></path>
      <path
        d="M401.58,341.32a5.1,5.1,0,0,1-2.3.12c-1.26-.09-2.27-.28-2.26-.44a5,5,0,0,1,2.3-.11A4.91,4.91,0,0,1,401.58,341.32Z"
        style="fill: rgb(38, 50, 56); transform-origin: 399.3px 341.162px"
        id="el9ufgi9zxva"
        class="animable"
      ></path>
      <path
        d="M385.67,333.1c-.13.08-.81-.76-1.51-1.88s-1.17-2.09-1-2.17.8.76,1.5,1.88S385.8,333,385.67,333.1Z"
        style="fill: rgb(38, 50, 56); transform-origin: 384.409px 331.075px"
        id="el1l3npbc93q2"
        class="animable"
      ></path>
      <path
        d="M380.41,340.86a4.64,4.64,0,0,1-2.19.22,4.51,4.51,0,0,1-2.18-.32,4.66,4.66,0,0,1,2.19-.23C379.43,340.56,380.41,340.7,380.41,340.86Z"
        style="fill: rgb(38, 50, 56); transform-origin: 378.225px 340.807px"
        id="ell9ilcisdi"
        class="animable"
      ></path>
      <path
        d="M376.63,356.38a5.31,5.31,0,0,1-2.3-.63c-1.22-.49-2.16-1-2.1-1.15a5.34,5.34,0,0,1,2.3.63C375.75,355.72,376.69,356.24,376.63,356.38Z"
        style="fill: rgb(38, 50, 56); transform-origin: 374.43px 355.49px"
        id="elqzhhh4y5mih"
        class="animable"
      ></path>
      <path
        d="M376.23,369.43c0,.15-.85.46-2,.7s-2,.3-2.08.15.85-.46,2-.69S376.2,369.28,376.23,369.43Z"
        style="fill: rgb(38, 50, 56); transform-origin: 374.188px 369.858px"
        id="elzytqg9ow52m"
        class="animable"
      ></path>
      <path
        d="M374.45,390.79a4.64,4.64,0,0,1-2.08.65c-1.18.21-2.16.26-2.18.11s.9-.44,2.08-.65A4.5,4.5,0,0,1,374.45,390.79Z"
        style="fill: rgb(38, 50, 56); transform-origin: 372.32px 391.171px"
        id="eln42f18yezds"
        class="animable"
      ></path>
      <path
        d="M373.27,402.57c.07.14-.71.69-1.74,1.23s-1.92.88-2,.75.7-.69,1.74-1.23S373.2,402.44,373.27,402.57Z"
        style="fill: rgb(38, 50, 56); transform-origin: 371.399px 403.56px"
        id="elmwdom4gt8tp"
        class="animable"
      ></path>
      <path
        d="M382.9,416c-.14.08-.81-.86-1.52-2.09a6.11,6.11,0,0,1-1-2.36c.13-.08.81.86,1.51,2.09A6.42,6.42,0,0,1,382.9,416Z"
        style="fill: rgb(38, 50, 56); transform-origin: 381.64px 413.775px"
        id="elqcv5qcq6uuf"
        class="animable"
      ></path>
      <path
        d="M385.67,393.72c.11.11-.65,1-1.68,2s-2,1.75-2.08,1.64.65-1,1.69-2S385.57,393.61,385.67,393.72Z"
        style="fill: rgb(38, 50, 56); transform-origin: 383.792px 395.54px"
        id="eld1xcrbv6s0i"
        class="animable"
      ></path>
      <path
        d="M385.06,382.05c-.11.11-.81-.47-1.57-1.28s-1.27-1.55-1.16-1.66.82.47,1.57,1.29S385.17,382,385.06,382.05Z"
        style="fill: rgb(38, 50, 56); transform-origin: 383.695px 380.58px"
        id="elojhco1nlepp"
        class="animable"
      ></path>
      <path
        d="M387,367.15c.11.1-.52,1-1.41,2s-1.7,1.72-1.81,1.61.51-1,1.4-2S386.85,367.05,387,367.15Z"
        style="fill: rgb(38, 50, 56); transform-origin: 385.39px 368.956px"
        id="elg8sgpfov188"
        class="animable"
      ></path>
      <path
        d="M388,356.33c-.1.11-1-.58-2-1.54s-1.68-1.83-1.57-1.94,1,.59,2,1.55S388.15,356.22,388,356.33Z"
        style="fill: rgb(38, 50, 56); transform-origin: 386.219px 354.59px"
        id="elkups77s6vg"
        class="animable"
      ></path>
      <path
        d="M390.15,341.71a5.42,5.42,0,0,1-1,2.19c-.68,1.13-1.33,2-1.46,1.9a5.47,5.47,0,0,1,1-2.19C389.37,342.48,390,341.63,390.15,341.71Z"
        style="fill: rgb(38, 50, 56); transform-origin: 388.92px 343.756px"
        id="elda917ulgq9d"
        class="animable"
      ></path>
      <path
        d="M394.13,328.16a5.37,5.37,0,0,1-.87,2.19c-.62,1.14-1.23,2-1.36,1.93a5.32,5.32,0,0,1,.87-2.19C393.39,329,394,328.09,394.13,328.16Z"
        style="fill: rgb(38, 50, 56); transform-origin: 393.015px 330.22px"
        id="eldh25qqzuns"
        class="animable"
      ></path>
      <path
        d="M398.63,334.31c.07.14-.55.57-1.37,1s-1.55.6-1.62.46.55-.56,1.38-1S398.57,334.18,398.63,334.31Z"
        style="fill: rgb(38, 50, 56); transform-origin: 397.135px 335.041px"
        id="el4u1ctnlkljp"
        class="animable"
      ></path>
      <path
        d="M398.74,383a6.32,6.32,0,0,1-3-.73,6.16,6.16,0,0,1-2.61-1.68,16.1,16.1,0,0,1,2.83,1.17A14.38,14.38,0,0,1,398.74,383Z"
        style="fill: rgb(38, 50, 56); transform-origin: 395.935px 381.795px"
        id="el8kzyx8rcztj"
        class="animable"
      ></path>
      <path
        d="M396.9,393.28c0,.15-.9.46-2.08.69s-2.15.29-2.17.14.9-.46,2.07-.69A4.75,4.75,0,0,1,396.9,393.28Z"
        style="fill: rgb(38, 50, 56); transform-origin: 394.775px 393.703px"
        id="elxwzyye4kwmd"
        class="animable"
      ></path>
      <path
        d="M394.64,411a6,6,0,0,1-2.35-2.06,6.06,6.06,0,0,1-1.48-2.76,17.48,17.48,0,0,1,1.91,2.41A18.49,18.49,0,0,1,394.64,411Z"
        style="fill: rgb(38, 50, 56); transform-origin: 392.725px 408.59px"
        id="el7els3jeoraa"
        class="animable"
      ></path>
      <path
        d="M369.84,419c-.1-.11.53-.81,1.4-1.56s1.66-1.25,1.76-1.14-.53.81-1.4,1.56S369.94,419.16,369.84,419Z"
        style="fill: rgb(38, 50, 56); transform-origin: 371.42px 417.656px"
        id="eln6fhl3zq7a"
        class="animable"
      ></path>
      <path
        d="M362.72,380.47c-1-3.14-2.89-6.79-6.18-6.84a5.45,5.45,0,0,0-4.63,3.1,11.35,11.35,0,0,0-1.25,5.67,148.14,148.14,0,0,0,7.07,41.09h12A142.92,142.92,0,0,0,362.72,380.47Z"
        style="fill: rgb(69, 90, 100); transform-origin: 360.19px 398.56px"
        id="eliuuei4z2lpa"
        class="animable"
      ></path>
      <path
        d="M340.94,360.73c-1.69-6.73-6.71-10.36-12.54-10.7-5.32.93-7.39,6.17-8,11.54s1,10.71,2.68,15.86Q331,402.21,338.91,427l15-1.64C349.15,403.23,346.43,382.7,340.94,360.73Z"
        style="fill: rgb(69, 90, 100); transform-origin: 337.089px 388.515px"
        id="el6zmzuc5tynt"
        class="animable"
      ></path>
      <path
        d="M356.54,373.63a11.47,11.47,0,0,0-1,1.71,11.07,11.07,0,0,0-.57,5.32c.24,2.29.5,5,.88,8.07s.94,6.34,1.62,9.79c1.36,6.92,2.89,13.13,3.87,17.66.49,2.26.86,4.09,1.1,5.37.1.58.18,1.07.25,1.47a1.83,1.83,0,0,1,.05.52,2.1,2.1,0,0,1-.16-.5c-.1-.39-.21-.87-.35-1.45l-1.27-5.33c-1.07-4.5-2.67-10.69-4-17.63-.67-3.47-1.21-6.8-1.57-9.84-.19-1.52-.32-3-.44-4.33s-.26-2.61-.33-3.77a11,11,0,0,1,.76-5.44A4.78,4.78,0,0,1,356.54,373.63Z"
        style="fill: rgb(38, 50, 56); transform-origin: 358.646px 398.585px"
        id="eleibkushw1fv"
        class="animable"
      ></path>
      <path
        d="M328.62,349.6a20.1,20.1,0,0,0-1.81,2.43,17.06,17.06,0,0,0-2.29,7.84,36.33,36.33,0,0,0,1.86,12.06c1.31,4.46,2.76,9.36,4.27,14.49l7.67,26.36c.95,3.34,1.71,6.06,2.27,8,.24.9.44,1.63.58,2.18a4.06,4.06,0,0,1,.17.77,3.94,3.94,0,0,1-.27-.74c-.18-.54-.4-1.26-.69-2.15-.59-1.94-1.42-4.64-2.43-8l-7.83-26.31c-1.5-5.14-2.93-10-4.23-14.51s-2-8.67-1.75-12.22a16.86,16.86,0,0,1,2.48-7.93A9.37,9.37,0,0,1,328.62,349.6Z"
        style="fill: rgb(38, 50, 56); transform-origin: 332.715px 386.665px"
        id="elumcx274yf8e"
        class="animable"
      ></path>
      <path
        d="M350.63,423.77a3.47,3.47,0,0,1-.34-.72c-.2-.48-.52-1.18-.86-2.11-.71-1.84-1.63-4.55-2.57-7.95s-1.89-7.51-2.79-12.08-1.83-9.62-2.83-14.91-2.2-10.26-3.54-14.72a82.62,82.62,0,0,0-4.37-11.54,61.91,61.91,0,0,0-4-7.28c-.52-.84-1-1.44-1.26-1.88a3.41,3.41,0,0,1-.42-.68,5.07,5.07,0,0,1,.51.61c.3.42.8,1,1.35,1.83a56.1,56.1,0,0,1,4.21,7.23,79,79,0,0,1,4.5,11.57,145.91,145.91,0,0,1,3.6,14.76c1,5.28,1.93,10.32,2.78,14.91s1.77,8.67,2.67,12.08,1.76,6.12,2.41,8c.3.94.59,1.65.75,2.14A6,6,0,0,1,350.63,423.77Z"
        style="fill: rgb(38, 50, 56); transform-origin: 339.14px 386.835px"
        id="el0nixxmxzrf6r"
        class="animable"
      ></path>
      <path
        d="M323.33,375c0,.15-.78.47-1.82.73s-1.92.33-1.95.19.78-.48,1.82-.73S323.29,374.8,323.33,375Z"
        style="fill: rgb(38, 50, 56); transform-origin: 321.445px 375.448px"
        id="elel0rdtufraj"
        class="animable"
      ></path>
      <path
        d="M321.74,364.73a19,19,0,0,1-5.07.2,8.65,8.65,0,0,1,5.07-.2Z"
        style="fill: rgb(38, 50, 56); transform-origin: 319.205px 364.729px"
        id="eldc3e7rlapje"
        class="animable"
      ></path>
      <path
        d="M323.43,355.39c-.1.11-.91-.41-1.82-1.18s-1.56-1.49-1.46-1.6.91.41,1.82,1.18S323.53,355.27,323.43,355.39Z"
        style="fill: rgb(38, 50, 56); transform-origin: 321.79px 354px"
        id="el8m2hn1uxte6"
        class="animable"
      ></path>
      <path
        d="M328,346.74c.15,0,.11.76.08,1.65s0,1.61-.18,1.63-.4-.71-.37-1.65S327.85,346.71,328,346.74Z"
        style="fill: rgb(38, 50, 56); transform-origin: 327.818px 348.38px"
        id="ele1yh67yu4qf"
        class="animable"
      ></path>
      <path
        d="M337.34,350.82c.12.08-.24.82-.82,1.64s-1.14,1.42-1.27,1.34.24-.83.82-1.65S337.21,350.73,337.34,350.82Z"
        style="fill: rgb(38, 50, 56); transform-origin: 336.294px 352.309px"
        id="ell3lc9wovtq"
        class="animable"
      ></path>
      <path
        d="M343.4,361.34c.05.15-.72.6-1.74,1s-1.88.63-1.94.49.72-.59,1.74-1S343.34,361.2,343.4,361.34Z"
        style="fill: rgb(38, 50, 56); transform-origin: 341.56px 362.085px"
        id="elzi3bmq4vj1l"
        class="animable"
      ></path>
      <path
        d="M347.07,372.18a6.94,6.94,0,0,1-5,1.12,10.37,10.37,0,0,1,2.47-.62A9.22,9.22,0,0,1,347.07,372.18Z"
        style="fill: rgb(38, 50, 56); transform-origin: 344.57px 372.783px"
        id="elowew31rywya"
        class="animable"
      ></path>
      <path
        d="M330.19,369.1c-.14,0,0-.79.24-1.67s.61-1.55.76-1.51,0,.8-.24,1.67S330.34,369.15,330.19,369.1Z"
        style="fill: rgb(38, 50, 56); transform-origin: 330.692px 367.51px"
        id="elph148sle28b"
        class="animable"
      ></path>
      <path
        d="M332.55,382.7a5.09,5.09,0,0,1,.68-2.25c.51-1.18,1-2.09,1.18-2a5.09,5.09,0,0,1-.68,2.25C333.22,381.85,332.69,382.76,332.55,382.7Z"
        style="fill: rgb(38, 50, 56); transform-origin: 333.48px 380.573px"
        id="elnlqltd8k29r"
        class="animable"
      ></path>
      <path
        d="M326.73,386.42c-.06.15-1-.11-2.16-.47s-2.07-.69-2-.84,1-.05,2.21.31S326.79,386.29,326.73,386.42Z"
        style="fill: rgb(38, 50, 56); transform-origin: 324.65px 385.753px"
        id="el91xyaz4topi"
        class="animable"
      ></path>
      <path
        d="M330.08,397.51c.09.13-.55.74-1.43,1.36s-1.66,1-1.75.92.55-.74,1.43-1.36S330,397.39,330.08,397.51Z"
        style="fill: rgb(38, 50, 56); transform-origin: 328.49px 398.645px"
        id="el1vrtzrvlmhs"
        class="animable"
      ></path>
      <path
        d="M332.77,408.57c0,.15-.75.47-1.77.71s-1.87.33-1.9.18.76-.46,1.77-.71S332.74,408.42,332.77,408.57Z"
        style="fill: rgb(38, 50, 56); transform-origin: 330.935px 409.014px"
        id="elz7ky3mjp0p"
        class="animable"
      ></path>
      <path
        d="M347.37,381.41c.08.13-.6.67-1.59,1s-1.85.42-1.88.27.75-.43,1.69-.78S347.29,381.28,347.37,381.41Z"
        style="fill: rgb(38, 50, 56); transform-origin: 345.638px 382.064px"
        id="elxon3rso29u"
        class="animable"
      ></path>
      <path
        d="M349.46,398.61c-.07.14-.88-.1-1.81-.54s-1.64-.91-1.57-1.05.87.11,1.8.55S349.52,398.48,349.46,398.61Z"
        style="fill: rgb(38, 50, 56); transform-origin: 347.769px 397.815px"
        id="elc9biqpumu28"
        class="animable"
      ></path>
      <path
        d="M353.14,410.92a5.64,5.64,0,0,1-2.39-.64c-1.26-.49-2.24-1-2.18-1.15a5.83,5.83,0,0,1,2.38.64A5.7,5.7,0,0,1,353.14,410.92Z"
        style="fill: rgb(38, 50, 56); transform-origin: 350.854px 410.025px"
        id="elz0pi2bmazd9"
        class="animable"
      ></path>
      <path
        d="M333.36,420.56c-.07-.13.91-.79,2.05-1.69s2-1.69,2.13-1.58-.61,1.09-1.79,2A4.83,4.83,0,0,1,333.36,420.56Z"
        style="fill: rgb(38, 50, 56); transform-origin: 335.456px 418.92px"
        id="elohs14conq9e"
        class="animable"
      ></path>
      <path
        d="M343.1,414.31c-.16,0-.37-.81-.75-1.77s-.76-1.72-.64-1.81.76.57,1.16,1.61S343.24,414.29,343.1,414.31Z"
        style="fill: rgb(38, 50, 56); transform-origin: 342.441px 412.516px"
        id="elxvu1liyx39r"
        class="animable"
      ></path>
      <path
        d="M340.52,399.5c-.09.13-.72-.17-1.41-.67s-1.17-1-1.08-1.11.72.17,1.4.67S340.61,399.38,340.52,399.5Z"
        style="fill: rgb(38, 50, 56); transform-origin: 339.275px 398.613px"
        id="eliu2hq2njer"
        class="animable"
      ></path>
      <path
        d="M363.2,376.31c.09.12-.52.72-1.37,1.35s-1.6,1-1.69.9.52-.72,1.37-1.35S363.11,376.18,363.2,376.31Z"
        style="fill: rgb(38, 50, 56); transform-origin: 361.67px 377.431px"
        id="elfsphp8f2zht"
        class="animable"
      ></path>
      <path
        d="M366.62,385.65c0,.15-.94.35-2.13.22s-2.08-.53-2-.67,1,0,2.09.12S366.61,385.5,366.62,385.65Z"
        style="fill: rgb(38, 50, 56); transform-origin: 364.552px 385.527px"
        id="elrpoysmba3zf"
        class="animable"
      ></path>
      <path
        d="M351.92,387.75c0,.14-.85.46-2,.54s-2.07-.12-2.05-.27.91-.21,2-.28S351.89,387.6,351.92,387.75Z"
        style="fill: rgb(38, 50, 56); transform-origin: 349.895px 387.985px"
        id="el5pv9g3emo7j"
        class="animable"
      ></path>
      <path
        d="M353.19,378.91c0,.15-.91.17-1.89-.28s-1.55-1.15-1.45-1.25.78.34,1.68.75S353.21,378.76,353.19,378.91Z"
        style="fill: rgb(38, 50, 56); transform-origin: 351.515px 378.186px"
        id="elkyo7f4r9ph"
        class="animable"
      ></path>
      <path
        d="M367.88,395.69a5,5,0,0,1-2.05-1c-1.05-.66-1.84-1.3-1.76-1.43s1,.3,2.05,1S368,395.56,367.88,395.69Z"
        style="fill: rgb(38, 50, 56); transform-origin: 365.978px 394.463px"
        id="el6h7a6z5sgrb"
        class="animable"
      ></path>
      <rect
        x="325.33"
        y="421.92"
        width="78.41"
        height="13.9"
        style="fill: rgb(255, 114, 94); transform-origin: 364.535px 428.87px"
        id="elff6naoq7kw"
        class="animable"
      ></rect>
      <polygon
        points="331.19 433.06 340.72 474.58 387.45 474.58 397.89 433.06 331.19 433.06"
        style="fill: rgb(255, 114, 94); transform-origin: 364.54px 453.82px"
        id="elrb6e1ffmamb"
        class="animable"
      ></polygon>
      <path
        d="M403.7,435.83c0,.15-17.69.28-39.5.28s-39.5-.13-39.5-.28,17.68-.28,39.5-.28S403.7,435.68,403.7,435.83Z"
        style="fill: rgb(38, 50, 56); transform-origin: 364.2px 435.83px"
        id="elfju8onm3ek"
        class="animable"
      ></path>
      <path
        d="M360.09,388.05c-.13.08-.66-.54-1.19-1.39s-.86-1.61-.73-1.69.66.54,1.19,1.39S360.22,388,360.09,388.05Z"
        style="fill: rgb(38, 50, 56); transform-origin: 359.13px 386.51px"
        id="el4t3mxrqx4rt"
        class="animable"
      ></path>
      <path
        d="M360.12,399.23a4.44,4.44,0,0,1,.41-2.11c.37-1.12.79-2,.94-1.93a4.45,4.45,0,0,1-.41,2.11C360.69,398.41,360.27,399.28,360.12,399.23Z"
        style="fill: rgb(38, 50, 56); transform-origin: 360.795px 397.209px"
        id="eln0jnw9lbcmn"
        class="animable"
      ></path>
      <path
        d="M364,411c-.14.07-.72-.87-1.31-2.1a5.7,5.7,0,0,1-.81-2.33c.14-.06.72.87,1.31,2.1A5.7,5.7,0,0,1,364,411Z"
        style="fill: rgb(38, 50, 56); transform-origin: 362.94px 408.785px"
        id="elhvgvz4sa1j"
        class="animable"
      ></path>
      <path
        d="M355.67,420.41c-.12-.1.35-.82,1-1.62s1.35-1.36,1.46-1.26-.35.82-1,1.62S355.78,420.51,355.67,420.41Z"
        style="fill: rgb(38, 50, 56); transform-origin: 356.899px 418.97px"
        id="el8zz9cidwaxi"
        class="animable"
      ></path>
      <path
        d="M353.93,403.27c0,.15-.78.14-1.68,0s-1.62-.41-1.59-.56.78-.14,1.68,0S354,403.12,353.93,403.27Z"
        style="fill: rgb(38, 50, 56); transform-origin: 352.297px 402.99px"
        id="elsq99pom2kl"
        class="animable"
      ></path>
    </g>
    <g
      id="freepik--speech-bubble--inject-2"
      class="animable"
      style="transform-origin: 126.074px 86.8692px"
    >
      <path
        d="M169.69,124.69l-8.94-15.76a42.26,42.26,0,1,0-10.12,11.25v0Z"
        style="fill: rgb(250, 250, 250); transform-origin: 126.105px 86.7489px"
        id="el38erqm801gb"
        class="animable"
      ></path>
      <path
        d="M169.69,124.69l-19.1-4.36-.19,0,.12-.16v0l.18.19a43.07,43.07,0,0,1-8.67,5.22,42.36,42.36,0,0,1-11.56,3.31,37.52,37.52,0,0,1-6.67.33c-1.15-.06-2.32-.09-3.5-.19l-3.56-.54a42.52,42.52,0,0,1-33.48-33.2,43,43,0,0,1,.5-18.76,41.58,41.58,0,0,1,9-17.34l1.72-1.89c.56-.64,1.24-1.17,1.85-1.75s1.22-1.18,1.92-1.67l2-1.51a18,18,0,0,1,2.09-1.39l2.14-1.3,2.24-1.08a19.75,19.75,0,0,1,2.26-1,43,43,0,0,1,18.9-2.9,41.64,41.64,0,0,1,17.35,5.12,42.82,42.82,0,0,1,20,24.63,46,46,0,0,1,1.83,13.72,42,42,0,0,1-6.3,20.91v-.13l8.83,15.82L160.64,109l0-.07,0-.06a42.92,42.92,0,0,0,4.15-9,42,42,0,0,0,2-11.75,45.5,45.5,0,0,0-1.86-13.59,42.38,42.38,0,0,0-19.86-24.33,41.24,41.24,0,0,0-17.15-5A42.61,42.61,0,0,0,109.25,48,20.32,20.32,0,0,0,107,49l-2.21,1.08-2.11,1.28a19,19,0,0,0-2.07,1.37l-2,1.5c-.68.48-1.25,1.1-1.89,1.64S95.46,57,94.9,57.59l-1.7,1.87a41.11,41.11,0,0,0-8.93,17.13,42.57,42.57,0,0,0-.51,18.53,41.61,41.61,0,0,0,7,16.11,42.22,42.22,0,0,0,26,16.79l3.53.54c1.17.1,2.33.14,3.47.2a36.87,36.87,0,0,0,6.61-.31,42.23,42.23,0,0,0,11.48-3.22,43.65,43.65,0,0,0,8.65-5.15l.78-.6-.6.78,0,0-.07-.2Z"
        style="fill: rgb(38, 50, 56); transform-origin: 126.074px 86.9191px"
        id="eltsxc4tcn8sa"
        class="animable"
      ></path>
      <path
        d="M96.93,91.21,98,88.83a8,8,0,0,0,4.51,1.43c1.87,0,2.64-.63,2.64-1.47,0-2.56-8-.8-8-5.88,0-2.32,1.87-4.25,5.76-4.25a9.15,9.15,0,0,1,4.74,1.21l-1,2.4A7.77,7.77,0,0,0,103,81.19c-1.88,0-2.6.71-2.6,1.57,0,2.52,8,.78,8,5.8,0,2.28-1.89,4.24-5.8,4.24A9.73,9.73,0,0,1,96.93,91.21Z"
        style="fill: rgb(38, 50, 56); transform-origin: 102.665px 85.7318px"
        id="elnxy680ki1q"
        class="animable"
      ></path>
      <path
        d="M109.47,87.31c0-3.17,2.44-5.41,5.78-5.41S121,84.14,121,87.31s-2.42,5.41-5.76,5.41S109.47,90.47,109.47,87.31Zm8.45,0a2.69,2.69,0,1,0-2.67,2.91A2.65,2.65,0,0,0,117.92,87.31Z"
        style="fill: rgb(38, 50, 56); transform-origin: 115.235px 87.31px"
        id="el3sr96fqeef6"
        class="animable"
      ></path>
      <path
        d="M129.56,81.9v2.81c-.25,0-.45,0-.68,0a2.59,2.59,0,0,0-2.8,2.93v5h-3V82.05H126v1.39A4.22,4.22,0,0,1,129.56,81.9Z"
        style="fill: rgb(38, 50, 56); transform-origin: 126.32px 87.2649px"
        id="elp143l1a495e"
        class="animable"
      ></path>
      <path
        d="M137.9,81.9v2.81c-.26,0-.45,0-.69,0-1.68,0-2.79.92-2.79,2.93v5h-3V82.05h2.91v1.39A4.25,4.25,0,0,1,137.9,81.9Z"
        style="fill: rgb(38, 50, 56); transform-origin: 134.66px 87.2651px"
        id="elip600tk2jkr"
        class="animable"
      ></path>
      <path
        d="M150.51,82.05l-4.74,11.16c-1,2.53-2.47,3.3-4.36,3.3a4.64,4.64,0,0,1-2.93-1l1.11-2.17a2.73,2.73,0,0,0,1.72.67c.84,0,1.31-.38,1.72-1.31l0-.1-4.55-10.59h3.14l3,7.13,3-7.13Z"
        style="fill: rgb(38, 50, 56); transform-origin: 144.495px 89.2602px"
        id="el07pzj0nfxnvu"
        class="animable"
      ></path>
      <path
        d="M151.31,90.94a1.88,1.88,0,0,1,3.75,0,1.88,1.88,0,0,1-3.75,0Zm.06-12H155l-.61,8.93H152Z"
        style="fill: rgb(38, 50, 56); transform-origin: 153.185px 85.8115px"
        id="eljr9s3ckxcsp"
        class="animable"
      ></path>
    </g>
    <g
      id="freepik--Character--inject-2"
      class="animable"
      style="transform-origin: 245.048px 261.662px"
    >
      <polygon
        points="211.6 150.5 227.13 144.75 265.59 141.93 275.71 145.02 262.06 203.82 228.51 211.07 212.95 190.42 211.6 150.5"
        style="fill: rgb(255, 190, 157); transform-origin: 243.655px 176.5px"
        id="el0899dnt4mvvs"
        class="animable"
      ></polygon>
      <path
        d="M298.74,152.9,273.67,144s-23.51,33.63-62.07,6.45c0,0-16.91,6.08-19.43,7.48s11.76,44.13,11.76,44.13l-1.68,53.13c22,11.35,57.2,7.13,87.84,2l-.88-59Z"
        style="fill: rgb(255, 114, 94); transform-origin: 245.306px 203.199px"
        id="eligba228dhb"
        class="animable"
      ></path>
      <path
        d="M289,104.7a9.31,9.31,0,0,0-13.8-12.3l-5,8.87A24.16,24.16,0,0,0,264.87,113a17.34,17.34,0,0,0,2.91,12.43A15,15,0,1,0,289,104.7Z"
        style="fill: rgb(38, 50, 56); transform-origin: 279.817px 110.868px"
        id="elbhkeabx9oge"
        class="animable"
      ></path>
      <path
        d="M276,105.39c-.15-3-.47-6,.38-8.91a12.26,12.26,0,0,0,.82-3.27,4,4,0,0,0-7.06-2.54L270,91c-1.73,1.4-2.56,3.61-3.24,5.73a89.46,89.46,0,0,0-2.56,10.07,16,16,0,0,0-.33,5.8,6.18,6.18,0,0,0,3.19,4.61c2.72,1.26,6.08-.48,7.57-3.07S276.18,108.37,276,105.39Z"
        style="fill: rgb(255, 114, 94); transform-origin: 270.472px 103.438px"
        id="elri3n9t7c50j"
        class="animable"
      ></path>
      <path
        d="M272.3,95.11c-.57-3.4-2.05-6.6-2.57-10-.68-4.47.33-9,.58-13.52s-.46-9.49-3.77-12.57c-3.53-3.29-8.84-3.44-13.59-4.3-5.4-1-10.52-3.11-15.83-4.52s-11.06-2.08-16.2-.15c-4.65,1.74-8.29,5.39-11.58,9.1C204,65.16,199,72.28,199,80.32c0,4.17,1.47,8.21,2,12.35.51,4,.19,8,1.12,12s3.57,7.8,7.51,8.52l43.22,4.32A19.19,19.19,0,0,0,272.3,95.11Z"
        style="fill: rgb(38, 50, 56); transform-origin: 235.786px 83.1857px"
        id="elgabg7ppvp9q"
        class="animable"
      ></path>
      <path
        d="M260.54,73.91l-.11-5.29-32-4.75c-9.82-.64-20,9.6-20.22,19.44-.24,10.93-.11,24.29,1.51,32.38,3.26,16.26,15.89,17.83,15.89,17.83v9.24a18.58,18.58,0,0,0,18.57,18.56h0a18.57,18.57,0,0,0,18.56-19.17Z"
        style="fill: rgb(255, 190, 157); transform-origin: 235.433px 112.581px"
        id="elxawyczv2fr"
        class="animable"
      ></path>
      <path
        d="M215,94.21a2.11,2.11,0,0,0,2.11,2,2,2,0,0,0,2.09-2,2.11,2.11,0,0,0-2.11-2A2,2,0,0,0,215,94.21Z"
        style="fill: rgb(38, 50, 56); transform-origin: 217.1px 94.21px"
        id="els6r7rkokw"
        class="animable"
      ></path>
      <path
        d="M212,90.12c.27.26,1.82-1,4.07-1.06s4,1,4.2.69c.11-.13-.17-.61-.92-1.11a5.81,5.81,0,0,0-3.37-.89,5.48,5.48,0,0,0-3.22,1.19C212.06,89.5,211.85,90,212,90.12Z"
        style="fill: rgb(38, 50, 56); transform-origin: 216.124px 88.9495px"
        id="eldmwtmsfe96k"
        class="animable"
      ></path>
      <path
        d="M236.37,93.49a2.11,2.11,0,0,0,2.11,2,2,2,0,0,0,2.09-2,2.11,2.11,0,0,0-2.11-2A2,2,0,0,0,236.37,93.49Z"
        style="fill: rgb(38, 50, 56); transform-origin: 238.47px 93.49px"
        id="elqezghvuxfpp"
        class="animable"
      ></path>
      <path
        d="M234.2,89.64c.27.26,1.81-1,4.07-1.06s4,1,4.19.69c.12-.13-.16-.61-.91-1.11a5.81,5.81,0,0,0-3.37-.89A5.49,5.49,0,0,0,235,88.46C234.28,89,234.07,89.52,234.2,89.64Z"
        style="fill: rgb(38, 50, 56); transform-origin: 238.325px 88.4695px"
        id="el1936o7njmal"
        class="animable"
      ></path>
      <path
        d="M226.68,106.72a15,15,0,0,0-3.69-.51c-.58,0-1.13-.13-1.25-.52a3,3,0,0,1,.32-1.72c.49-1.42,1-2.91,1.53-4.46C225.7,93.16,227.21,88,227,87.86s-2.18,5-4.29,11.35c-.51,1.56-1,3.05-1.46,4.48A3.36,3.36,0,0,0,221,106a1.44,1.44,0,0,0,1,.8,4,4,0,0,0,1,.1A14.47,14.47,0,0,0,226.68,106.72Z"
        style="fill: rgb(38, 50, 56); transform-origin: 223.959px 97.4011px"
        id="elr39x2tdkgq"
        class="animable"
      ></path>
      <path
        d="M225.65,133.56a43.32,43.32,0,0,0,22.23-6.79s-5.23,11.73-22.23,10.91Z"
        style="fill: rgb(235, 153, 110); transform-origin: 236.765px 132.246px"
        id="elnhdoehfl1o"
        class="animable"
      ></path>
      <path
        d="M227.86,112.13a4,4,0,0,1,3.58-1.66,3.68,3.68,0,0,1,2.63,1.27,2.36,2.36,0,0,1,.28,2.68,2.74,2.74,0,0,1-2.93.87,8.5,8.5,0,0,1-2.95-1.6,2.63,2.63,0,0,1-.68-.65.78.78,0,0,1,0-.84"
        style="fill: rgb(235, 153, 110); transform-origin: 231.155px 112.936px"
        id="eluiqj05nu9l"
        class="animable"
      ></path>
      <path
        d="M244.62,82.93c-.13-.64-2.41-.68-5-1.38s-4.6-1.73-5-1.23c-.19.23.09.86.86,1.57a9,9,0,0,0,7.63,2C244.12,83.64,244.67,83.23,244.62,82.93Z"
        style="fill: rgb(38, 50, 56); transform-origin: 239.593px 82.1262px"
        id="elbznmzxkmjrg"
        class="animable"
      ></path>
      <path
        d="M218.49,81.3c-.71-.21-2.33,1.22-4.55,2.4s-4.3,1.82-4.49,2.52c-.07.33.46.63,1.51.64a9,9,0,0,0,4.11-1.17,8.72,8.72,0,0,0,3.18-2.79C218.8,82,218.82,81.42,218.49,81.3Z"
        style="fill: rgb(38, 50, 56); transform-origin: 214.076px 84.0697px"
        id="eluzywwo3ai3q"
        class="animable"
      ></path>
      <path
        d="M254.57,58.3l-24.68,1.94-20.45,7.05c2.63,5.73,3.13,10.44,9.41,11a18.49,18.49,0,0,0,16.46-7.41c1.06,4.64,6.29,7,11,7.66a18.74,18.74,0,0,0,9.94-.9c3.09-1.32,5.71-4.1,6.13-7.44S257.89,58.85,254.57,58.3Z"
        style="fill: rgb(38, 50, 56); transform-origin: 235.923px 68.5701px"
        id="el2g14ea2eyjq"
        class="animable"
      ></path>
      <path
        d="M274.12,74.7a12,12,0,0,0-6.34-5.54l-.63-4.79-18.29,5.77C244.53,77,246.51,87,253.19,91.81l.08,3.47a21.44,21.44,0,0,0,2.92,10.34h0l5.8,12.63c4.26.1,5.23-4.51,6.92-8.28s1.71-8,1.56-12.14q-.11-3-.31-6c1.3-2.2,3.15-4.25,4.24-6.59A12,12,0,0,0,274.12,74.7Z"
        style="fill: rgb(38, 50, 56); transform-origin: 261.023px 91.3108px"
        id="elbs8iuckve"
        class="animable"
      ></path>
      <path
        d="M255.71,96.34c.25-.13,10-4,10.59,6.26s-9.79,8.74-9.82,8.45S255.71,96.34,255.71,96.34Z"
        style="fill: rgb(255, 190, 157); transform-origin: 261.017px 103.408px"
        id="elpzoqpsejov"
        class="animable"
      ></path>
      <path
        d="M259.37,107s.19.12.5.24a1.77,1.77,0,0,0,1.33-.06c1.06-.46,1.87-2.23,1.8-4.08a5.84,5.84,0,0,0-.65-2.52,2.06,2.06,0,0,0-1.42-1.29.91.91,0,0,0-1,.55c-.12.3-.05.5-.1.52s-.23-.16-.17-.59a1.12,1.12,0,0,1,.36-.66,1.33,1.33,0,0,1,1-.32,2.51,2.51,0,0,1,2,1.47,6.11,6.11,0,0,1,.79,2.82c.06,2.07-.88,4.06-2.34,4.57a1.87,1.87,0,0,1-1.64-.17C259.4,107.23,259.34,107,259.37,107Z"
        style="fill: rgb(235, 153, 110); transform-origin: 261.588px 103.275px"
        id="elts8y6i2lwp"
        class="animable"
      ></path>
      <path
        d="M273.05,73.48c.16,0,.26.51.1,1.34A7.23,7.23,0,0,1,271.5,78a8.5,8.5,0,0,1-4.68,2.74c-2.12.45-4.24,0-6,.19a14.73,14.73,0,0,0-2.74.67c-.48.14-1,.28-1.5.39s-1,.19-1.48.25a11.29,11.29,0,0,1-5.25-.43,6.89,6.89,0,0,1-3-2c-.54-.65-.68-1.11-.56-1.22.3-.3,1.56,1,4,1.43a11.89,11.89,0,0,0,4.39-.17c.42-.08.85-.18,1.28-.29s.85-.26,1.31-.42a15.19,15.19,0,0,1,3.23-.82c2.44-.21,4.36.29,5.88,0a7.74,7.74,0,0,0,3.8-1.7C272.15,75.07,272.66,73.38,273.05,73.48Z"
        style="fill: rgb(255, 255, 255); transform-origin: 259.741px 77.9245px"
        id="elq62b7e2k8w8"
        class="animable"
      ></path>
      <path
        d="M235.63,110.89c.06.29-1.94.9-4.16,2.08s-3.86,2.48-4.07,2.26,1.19-1.92,3.58-3.18S235.59,110.62,235.63,110.89Z"
        style="fill: rgb(38, 50, 56); transform-origin: 231.505px 113.017px"
        id="elx0c9xn0ag0s"
        class="animable"
      ></path>
      <path
        d="M237.42,112.45c-.14-.07.11-.78-.1-1.63s-.7-1.4-.61-1.51.89.34,1.12,1.39S237.53,112.54,237.42,112.45Z"
        style="fill: rgb(235, 153, 110); transform-origin: 237.293px 110.875px"
        id="elfxbxvqq1qjb"
        class="animable"
      ></path>
      <path
        d="M242.17,95.92c.12.06-.37,1-1.53,1.56s-2.21.32-2.17.18.95-.16,1.95-.65S242.05,95.83,242.17,95.92Z"
        style="fill: rgb(235, 153, 110); transform-origin: 240.328px 96.8725px"
        id="ely5w3g6i9jc7"
        class="animable"
      ></path>
      <path
        d="M243.12,96.71c.12.08-.26.75-1.07,1.19s-1.57.4-1.58.26.63-.33,1.33-.72S243,96.62,243.12,96.71Z"
        style="fill: rgb(235, 153, 110); transform-origin: 241.806px 97.4768px"
        id="elg4tmzsic4e"
        class="animable"
      ></path>
      <path
        d="M215.11,98.88c0,.14-.56.23-1.14-.07s-.83-.8-.72-.89.48.18,1,.42S215.12,98.73,215.11,98.88Z"
        style="fill: rgb(235, 153, 110); transform-origin: 214.167px 98.4562px"
        id="elhcfz5vqrfrg"
        class="animable"
      ></path>
      <path
        d="M215,100.09c0,.14-.64.15-1.32-.16s-1.09-.79-1-.89.6.14,1.21.42S215.05,100,215,100.09Z"
        style="fill: rgb(235, 153, 110); transform-origin: 213.835px 99.6002px"
        id="elveg96icddwk"
        class="animable"
      ></path>
      <path
        d="M229.55,80.09a7.6,7.6,0,0,1-8.27.82,19.07,19.07,0,0,0,4.21.3A19,19,0,0,0,229.55,80.09Z"
        style="fill: rgb(235, 153, 110); transform-origin: 225.415px 80.9288px"
        id="elfdlqz54dodj"
        class="animable"
      ></path>
      <path
        d="M230.52,81.14a3.22,3.22,0,0,1-2.14,1.62,3.16,3.16,0,0,1-2.68-.09,23.37,23.37,0,0,0,2.52-.41C229.56,81.85,230.41,81,230.52,81.14Z"
        style="fill: rgb(235, 153, 110); transform-origin: 228.11px 82.0699px"
        id="el3va34ehm3mv"
        class="animable"
      ></path>
      <path
        d="M212.61,74.83s-.1-.09-.25-.29a5.38,5.38,0,0,1-.57-.94,8.65,8.65,0,0,1-.81-3.93c0-3.43,2-8.11,6-11.52a18.05,18.05,0,0,1,6.36-3.57,18.25,18.25,0,0,1,5.89-.84,17,17,0,0,1,4,.53c.45.12.8.23,1,.32s.35.14.34.16a26.32,26.32,0,0,0-5.36-.65,18.83,18.83,0,0,0-5.75.94,18.12,18.12,0,0,0-6.16,3.5c-3.86,3.32-5.86,7.83-5.93,11.14a9,9,0,0,0,.63,3.84C212.34,74.38,212.65,74.8,212.61,74.83Z"
        style="fill: rgb(69, 90, 100); transform-origin: 222.773px 64.2828px"
        id="elmxdafkmj8yf"
        class="animable"
      ></path>
      <path
        d="M249.5,78.51a2.79,2.79,0,0,1-.87-.2,12.87,12.87,0,0,1-2.22-1,17.34,17.34,0,0,1-5.89-5.42,11,11,0,0,1-1.44-4.22,20.6,20.6,0,0,1-.21-3.61,16.82,16.82,0,0,1,.24-2.42,2.77,2.77,0,0,1,.22-.86c.09,0-.12,1.26-.09,3.29a22.69,22.69,0,0,0,.31,3.52,11.18,11.18,0,0,0,1.41,4A18.77,18.77,0,0,0,246.6,77C248.32,78.06,249.53,78.43,249.5,78.51Z"
        style="fill: rgb(69, 90, 100); transform-origin: 244.181px 69.645px"
        id="ellklveyxrnb"
        class="animable"
      ></path>
      <path
        d="M220.42,74.69a3.52,3.52,0,0,1-.32-1,12.23,12.23,0,0,1-.27-2.8,13.36,13.36,0,0,1,11.22-13.13,11.8,11.8,0,0,1,2.81-.16,3.61,3.61,0,0,1,1,.15,28.11,28.11,0,0,0-3.77.37,13.55,13.55,0,0,0-10.92,12.78A25.22,25.22,0,0,0,220.42,74.69Z"
        style="fill: rgb(69, 90, 100); transform-origin: 227.344px 66.1334px"
        id="elnnubh51oj9"
        class="animable"
      ></path>
      <path
        d="M253.55,55.37a19.74,19.74,0,0,0-6.44.15,19.54,19.54,0,0,0-5.87,2.67,4.8,4.8,0,0,1,1.44-1.3,12.67,12.67,0,0,1,9-2.06A5.08,5.08,0,0,1,253.55,55.37Z"
        style="fill: rgb(69, 90, 100); transform-origin: 247.395px 56.4408px"
        id="elbh3t4gsbnv"
        class="animable"
      ></path>
      <path
        d="M289.65,104.91c-.52.54-3.14-1.22-6.72-2.26s-6.72-1.11-6.85-1.85c-.05-.34.73-.81,2.13-1.06a12.6,12.6,0,0,1,10.26,3.11C289.49,103.83,289.88,104.66,289.65,104.91Z"
        style="fill: rgb(255, 255, 255); transform-origin: 282.896px 102.309px"
        id="elu61e4p8d95k"
        class="animable"
      ></path>
      <path
        d="M288.08,107.58s.45-.23,1.23-.68a10.63,10.63,0,0,0,2.85-2.44,9.62,9.62,0,0,0,2.16-5,9.4,9.4,0,0,0-1.7-6.4A9.23,9.23,0,0,0,287,89.47a13.26,13.26,0,0,0-5.55,0,36.2,36.2,0,0,0-4.95,1.58,5.82,5.82,0,0,1,1.24-.68,17.74,17.74,0,0,1,3.64-1.26,13.09,13.09,0,0,1,5.72-.1,9.57,9.57,0,0,1,5.91,3.71,9.56,9.56,0,0,1-.61,12,10.08,10.08,0,0,1-3,2.38,7.28,7.28,0,0,1-1,.41A1.48,1.48,0,0,1,288.08,107.58Z"
        style="fill: rgb(38, 50, 56); transform-origin: 285.669px 98.1609px"
        id="elmyay267qmhs"
        class="animable"
      ></path>
      <path
        d="M205.4,257.6s-21.29,44.86-21.08,65.51c.14,13,18.34,151.36,18.34,151.36h38.78l3.15-128.9,2.66-3.56,1.66,4.06s-5.85,125.21-4.48,128.4h43.35s13.78-99.28,15.61-114.32,4-22.24,3.06-44.12-16.28-58.43-16.28-58.43S231.38,267.17,205.4,257.6Z"
        style="fill: rgb(38, 50, 56); transform-origin: 245.498px 366.035px"
        id="eldcjy9ul15m"
        class="animable"
      ></path>
      <polygon
        points="207.36 257.9 203.76 270.28 214.14 272.51 217.06 260.05 207.36 257.9"
        style="fill: rgb(69, 90, 100); transform-origin: 210.41px 265.205px"
        id="el9mf9zy2k6zr"
        class="animable"
      ></polygon>
      <path
        d="M221,260.57l-2.1,11.94a200.37,200.37,0,0,0,24.46,1c12.17-.33,24.06-1.35,29.39-3.21l-1.55-10.65S231,263.34,221,260.57Z"
        style="fill: rgb(69, 90, 100); transform-origin: 245.825px 266.601px"
        id="el8ng4jm4p2n"
        class="animable"
      ></path>
      <path
        d="M276.39,259.05l2.57,10.5s8.3-.91,11.72-2.91l-2.86-9.43Z"
        style="fill: rgb(69, 90, 100); transform-origin: 283.535px 263.38px"
        id="elxsjs67kpe7e"
        class="animable"
      ></path>
      <path
        d="M221,472.74c-.1-2.86-1.52-44.06-3.37-97.86-.86-27-1.47-51.52-1.65-69.24,0-4.43,0-8.44-.05-12s.06-6.54.08-9,.13-4.38.17-5.7c0-.64,0-1.13.05-1.49s0-.5,0-.5,0,.17,0,.51,0,.84,0,1.48c0,1.33,0,3.24-.07,5.7s0,5.52,0,9,.07,7.53.12,12c.27,17.71.94,42.19,1.8,69.22s1.65,51.47,2.22,69.2c.27,8.86.45,16,.55,21,0,2.46.06,4.37.08,5.7,0,.64,0,1.13,0,1.49S221,472.74,221,472.74Z"
        style="fill: rgb(69, 90, 100); transform-origin: 218.459px 374.845px"
        id="elc7672kni26w"
        class="animable"
      ></path>
      <path
        d="M246.22,274.31a2.7,2.7,0,0,1,.05.59c0,.44,0,1,0,1.68,0,1.52,0,3.61.07,6.16.05,5.21.16,12.4.38,20.34s.54,15.13.78,20.33c.12,2.55.21,4.64.28,6.16q0,1,.06,1.68a2.14,2.14,0,0,1,0,.59,4.68,4.68,0,0,1-.09-.58l-.15-1.68c-.13-1.45-.28-3.55-.45-6.15-.33-5.2-.7-12.39-.93-20.33S246,288,246,282.74c0-2.6.05-4.71.09-6.17l.06-1.67A2.27,2.27,0,0,1,246.22,274.31Z"
        style="fill: rgb(69, 90, 100); transform-origin: 246.93px 303.075px"
        id="el91f2gc6xzyd"
        class="animable"
      ></path>
      <path
        d="M247.86,331.84a6.41,6.41,0,0,1-.65-.11l-1.85-.36a61.56,61.56,0,0,1-6.76-1.71,9.22,9.22,0,0,1-2.14-1,1.31,1.31,0,0,1-.46-.54c-.07-.21-.13-.41-.19-.61a10.7,10.7,0,0,1-.23-1.28c-.2-1.75-.09-3.59-.11-5.53,0-3.89.05-8.15.08-12.62.09-8.93.18-17,.24-22.93.06-2.89.11-5.25.14-6.95q0-1.17.06-1.89a2.89,2.89,0,0,1,.06-.66,3.44,3.44,0,0,1,0,.66q0,.72,0,1.89c0,1.7,0,4.06,0,6.95,0,5.92-.06,14-.09,22.93,0,4.48-.08,8.74-.12,12.62,0,1.94-.11,3.79.06,5.48a9.87,9.87,0,0,0,.21,1.23c.05.19.1.39.16.57a1.05,1.05,0,0,0,.32.36,8.73,8.73,0,0,0,2,.95c2.75.95,5.08,1.45,6.67,1.86l1.83.46A2.22,2.22,0,0,1,247.86,331.84Z"
        style="fill: rgb(69, 90, 100); transform-origin: 241.659px 303.745px"
        id="el4x2kvdp72pv"
        class="animable"
      ></path>
      <path
        d="M269.49,472.74s0-.17,0-.51.05-.87.09-1.52c.08-1.35.21-3.31.36-5.82.37-5.07.89-12.37,1.54-21.39,1.32-18.05,3.16-43,4.63-70.58s2-52.61,1.41-70.7c-.09-2.26-.19-4.41-.28-6.44s-.15-4-.33-5.75c-.32-3.58-.49-6.67-.81-9.18s-.61-4.43-.8-5.77c-.08-.65-.15-1.15-.19-1.51s-.05-.51-.05-.51,0,.17.1.51.14.85.25,1.49c.2,1.34.51,3.28.89,5.76s.55,5.61.9,9.19c.2,1.79.28,3.71.38,5.75s.2,4.19.31,6.45c.72,18.11.13,43.14-1.26,70.74s-3.37,52.53-4.79,70.58c-.71,9-1.29,16.31-1.69,21.38-.2,2.5-.36,4.46-.47,5.81-.06.65-.1,1.15-.13,1.51S269.49,472.74,269.49,472.74Z"
        style="fill: rgb(69, 90, 100); transform-origin: 273.844px 372.9px"
        id="eli4fyo6t2lu"
        class="animable"
      ></path>
      <path
        d="M244.11,273.93v2.32l13.44-.52a1.64,1.64,0,0,0,1.58-1.65V261.67a1.65,1.65,0,0,0-1.65-1.65H243.21v1.86l13.53.7v4.51l-.91,0a.71.71,0,0,0-.73.71h0a.71.71,0,0,0,.69.7l1,0V273Z"
        style="fill: rgb(224, 224, 224); transform-origin: 251.17px 268.135px"
        id="elndfxd34zn2"
        class="animable"
      ></path>
      <rect
        x="232.53"
        y="260.86"
        width="3.8"
        height="13"
        style="fill: rgb(38, 50, 56); transform-origin: 234.43px 267.36px"
        id="elwebddyc7ls"
        class="animable"
      ></rect>
      <path
        d="M255.55,269.28c-.05.05-.26,0-.48-.35a2.18,2.18,0,0,1-.38-1.36,2,2,0,0,1,.56-1.29c.25-.27.47-.34.51-.29s-.49.7-.55,1.62S255.66,269.19,255.55,269.28Z"
        style="fill: rgb(38, 50, 56); transform-origin: 255.224px 267.636px"
        id="elhkanaiiud6"
        class="animable"
      ></path>
      <path
        d="M244.75,266.64s.07-.08.22-.07a.69.69,0,0,1,.49.35,1,1,0,0,1,.06.92,1.06,1.06,0,0,1-1,.57,1,1,0,0,1-.85-.79.91.91,0,0,1,.32-.86c.43-.33.78-.17.75-.12s-.28.1-.49.38a.52.52,0,0,0-.11.5.5.5,0,0,0,.41.37.65.65,0,0,0,.54-.28.67.67,0,0,0,0-.52C245,266.75,244.76,266.71,244.75,266.64Z"
        style="fill: rgb(38, 50, 56); transform-origin: 244.631px 267.484px"
        id="elrleg5ngb0e"
        class="animable"
      ></path>
      <path
        d="M282.24,269.28a9.09,9.09,0,0,1,1-1.91c.37-.63.76-1.3,1.18-2l.71-1.18a9.1,9.1,0,0,0,.7-1.21,1.34,1.34,0,0,0-.2-1.17,4.8,4.8,0,0,0-1-.8l-1.89-1.35a9.19,9.19,0,0,1-1.66-1.36,8.5,8.5,0,0,1,1.87,1.05l1.95,1.27.56.37a2.13,2.13,0,0,1,.58.52,1.87,1.87,0,0,1,.28,1.63,3,3,0,0,1-.38.72l-.39.61-.74,1.16-1.27,1.93A8.71,8.71,0,0,1,282.24,269.28Z"
        style="fill: rgb(38, 50, 56); transform-origin: 283.738px 263.79px"
        id="eluoj7t07r60k"
        class="animable"
      ></path>
      <path
        d="M305,299.55a1.27,1.27,0,0,1-.35.19,5.42,5.42,0,0,1-1.11.33,8.37,8.37,0,0,1-4.19-.52,26,26,0,0,1-5.5-3,40.37,40.37,0,0,1-15.61-20,9.1,9.1,0,0,1-.43-1.48,7.53,7.53,0,0,1,.62,1.41c.38.92,1,2.22,1.76,3.8a44.6,44.6,0,0,0,8.12,11.06,43.68,43.68,0,0,0,5.81,4.84,27.34,27.34,0,0,0,5.36,3,8.62,8.62,0,0,0,4,.67C304.49,299.74,305,299.5,305,299.55Z"
        style="fill: rgb(69, 90, 100); transform-origin: 291.405px 287.606px"
        id="elfd6hw11r5m8"
        class="animable"
      ></path>
      <path
        d="M207.42,279.29a13.57,13.57,0,0,1-1.65,2.6,47.71,47.71,0,0,1-10.46,10.45,14.1,14.1,0,0,1-2.59,1.65,23.76,23.76,0,0,1,2.37-1.94,64.9,64.9,0,0,0,10.39-10.39A25.36,25.36,0,0,1,207.42,279.29Z"
        style="fill: rgb(69, 90, 100); transform-origin: 200.07px 286.64px"
        id="elskmo2ob6asp"
        class="animable"
      ></path>
      <path
        d="M242.31,178.87l-.7-33.39c-.34-4.29-4.05-3.49-4.05-3.49s-.24,11.84-1.23,18.49-4.57,14-4.69,15.49-.58,14.15-1,18.21a60.37,60.37,0,0,1-2.55,10.08l9.51,7.31,2.89-4.06s3.25-2.32,4-5.22S242.31,178.87,242.31,178.87Z"
        style="fill: rgb(255, 190, 157); transform-origin: 236.351px 176.75px"
        id="el8wsl17aqkm6"
        class="animable"
      ></path>
      <path
        d="M242.31,178.87s.05.18.11.54l.24,1.57c.21,1.38.5,3.41.84,6s.74,5.79,1.05,9.48c.09.93.15,1.88.2,2.87,0,.5.05,1,0,1.51a6.62,6.62,0,0,1-.16,1.58,8.35,8.35,0,0,1-1.58,2.87,14.58,14.58,0,0,1-2.46,2.37l0-.05-2.88,4.07-.14.18-.18-.14-9.52-7.29-.13-.1,0-.17a46.9,46.9,0,0,0,2.78-13c.31-4.65.5-9.46.74-14.39a6.11,6.11,0,0,1,.13-1.25c.11-.41.24-.79.37-1.18l.83-2.25c.56-1.49,1.1-3,1.59-4.45a42.32,42.32,0,0,0,2-8.86c.66-5.91.87-11.57,1-16.77v-.19l.18,0a4,4,0,0,1,2.29.26,3.16,3.16,0,0,1,1.58,1.6,5.28,5.28,0,0,1,.45,2.12c0,.69,0,1.37,0,2.05,0,2.69.1,5.25.14,7.66.08,4.81.15,9,.21,12.49s.08,6.17.1,8.06c0,.91,0,1.62,0,2.13,0,.22,0,.4,0,.55s0,.18,0,.18,0-.06,0-.18,0-.32,0-.54c0-.51,0-1.22-.08-2.13-.06-1.89-.13-4.61-.23-8.05s-.2-7.68-.32-12.49c0-2.41-.11-5-.18-7.66-.08-1.34.12-2.79-.47-4a2.64,2.64,0,0,0-1.37-1.38,3.52,3.52,0,0,0-2-.22l.18-.22c-.13,5.22-.32,10.87-1,16.82a43.18,43.18,0,0,1-2.05,9c-.49,1.49-1,3-1.59,4.46l-.83,2.25c-.13.37-.26.75-.35,1.12a5.2,5.2,0,0,0-.12,1.15c-.24,4.93-.43,9.75-.75,14.4a47.1,47.1,0,0,1-2.83,13.17l-.08-.27,9.49,7.32-.31.05,2.9-4.05,0,0,0,0a10.76,10.76,0,0,0,3.93-5,6.67,6.67,0,0,0,.15-1.49c0-.5,0-1,0-1.49,0-1-.1-1.93-.17-2.85-.29-3.68-.66-6.87-.95-9.48s-.56-4.62-.73-6c-.08-.68-.14-1.2-.19-1.58S242.31,178.87,242.31,178.87Z"
        style="fill: rgb(235, 153, 110); transform-origin: 236.236px 176.808px"
        id="el7a5xrpwfzog"
        class="animable"
      ></path>
      <path
        d="M241.65,148.45a12.91,12.91,0,0,0-.84-1.1,4.72,4.72,0,0,0-1.28-.9,1.46,1.46,0,0,0-1.74.23l.05-.16c.13,1,.33,2.2.43,3.48s.13,2.65.09,4.08a55.55,55.55,0,0,1-1.11,9c-.23,1.13-.43,2-.58,2.67a3.4,3.4,0,0,1-.29,1,32.56,32.56,0,0,1,.5-3.7,71.62,71.62,0,0,0,1-8.91c0-1.41,0-2.76-.07-4s-.25-2.42-.35-3.46v-.09l.05-.07a1.54,1.54,0,0,1,1.14-.52,2.25,2.25,0,0,1,1.06.26,4.26,4.26,0,0,1,1.31,1,3.94,3.94,0,0,1,.57.9C241.64,148.32,241.67,148.45,241.65,148.45Z"
        style="fill: rgb(235, 153, 110); transform-origin: 239.018px 156.375px"
        id="el7fhi8i766eq"
        class="animable"
      ></path>
      <path
        d="M241.92,161.41a12.5,12.5,0,0,1-.24-1.56,4,4,0,0,0-.59-1.64,2.94,2.94,0,0,0-1.93-1l.27-.2c-.06,1.9-.18,4.18-.42,6.61-.33,3.41-.82,6.49-1.24,8.7-.22,1.1-.42,2-.57,2.6a3.39,3.39,0,0,1-.29.94,34.75,34.75,0,0,1,.49-3.61c.34-2.21.77-5.28,1.09-8.68.24-2.42.39-4.69.5-6.58v-.26l.26.05a3.28,3.28,0,0,1,2.13,1.24,4.21,4.21,0,0,1,.55,1.8A5,5,0,0,1,241.92,161.41Z"
        style="fill: rgb(235, 153, 110); transform-origin: 239.449px 166.295px"
        id="elf6jssjdzrvc"
        class="animable"
      ></path>
      <path
        d="M237,179.46c-.09.11-.65-.31-.72-1.1s.39-1.32.5-1.22-.05.57,0,1.17S237.08,179.36,237,179.46Z"
        style="fill: rgb(235, 153, 110); transform-origin: 236.644px 178.303px"
        id="elqt1ymgd3wkm"
        class="animable"
      ></path>
      <path
        d="M235.47,171.22c-.13.05-.51-.7-.44-1.72s.55-1.71.67-1.64-.1.78-.15,1.68S235.61,171.17,235.47,171.22Z"
        style="fill: rgb(235, 153, 110); transform-origin: 235.378px 169.539px"
        id="elqzemm5t41sn"
        class="animable"
      ></path>
      <path
        d="M255.83,197.82c-.75-4-2.1-16.59-2.32-18.09s-4.3-8.56-5.76-15.12-2.53-18.36-2.53-18.36-3.75-.54-3.8,3.77l1.65,33.35s-1.48,20.68-.51,23.52,4.38,4.92,4.38,4.92l3.17,3.84,9-8A61.15,61.15,0,0,1,255.83,197.82Z"
        style="fill: rgb(255, 190, 157); transform-origin: 250.265px 180.939px"
        id="elkuyof5eowq"
        class="animable"
      ></path>
      <path
        d="M255.83,197.82s0,.05,0,.16.07.3.12.5c.12.45.28,1.1.54,2a75.69,75.69,0,0,0,2.66,7.22l0,.08-.07.07-8.93,8-.15.13-.12-.15-3.19-3.83.05,0a14.69,14.69,0,0,1-2.58-2.12,8.17,8.17,0,0,1-1.91-3,17.17,17.17,0,0,1-.32-3.64c0-1.23,0-2.48,0-3.75.11-5.08.41-10.49.79-16.16v0c-.28-5.54-.57-11.41-.87-17.43-.19-3.88-.38-7.68-.56-11.37,0-.93-.09-1.84-.14-2.75a14.86,14.86,0,0,1,0-2.75,3.57,3.57,0,0,1,1.35-2.43,3.88,3.88,0,0,1,2.66-.66l.19,0,0,.21c.36,3.82.77,7.51,1.26,11a65.55,65.55,0,0,0,1.93,9.92,71.76,71.76,0,0,0,3.3,8.36c.57,1.29,1.16,2.49,1.6,3.73a11.36,11.36,0,0,1,.29,1.91c.07.61.14,1.21.21,1.79.52,4.68.94,8.39,1.26,10.94.16,1.24.29,2.22.38,2.91l.11.75a1,1,0,0,1,0,.26,1.23,1.23,0,0,1-.07-.25c0-.19-.08-.44-.14-.75-.12-.66-.28-1.63-.47-2.9-.37-2.54-.84-6.25-1.4-10.92-.07-.58-.15-1.18-.22-1.79a13.58,13.58,0,0,0-.29-1.84c-.43-1.19-1.05-2.41-1.62-3.68a71.29,71.29,0,0,1-3.37-8.4,66.87,66.87,0,0,1-2-10q-.73-5.3-1.28-11l.21.22a3.42,3.42,0,0,0-2.29.57,3.1,3.1,0,0,0-1.15,2.11,14,14,0,0,0,0,2.66c0,.9.09,1.82.13,2.74.19,3.7.38,7.5.57,11.37.3,6,.58,11.88.86,17.42v0h0c-.39,5.65-.7,11.07-.83,16.13,0,1.27-.05,2.51,0,3.73a17.31,17.31,0,0,0,.29,3.52,7.72,7.72,0,0,0,1.79,2.8,14.56,14.56,0,0,0,2.49,2.07l0,0,0,0,3.17,3.85-.27,0,9-7.91,0,.15a67,67,0,0,1-2.55-7.28c-.24-.86-.39-1.52-.47-2l-.09-.51C255.83,197.88,255.82,197.82,255.83,197.82Z"
        style="fill: rgb(235, 153, 110); transform-origin: 250.118px 180.947px"
        id="eluvpztn3ovn"
        class="animable"
      ></path>
      <path
        d="M241.57,153.06a3.19,3.19,0,0,1,.41-.91,6.18,6.18,0,0,1,.64-1,2.41,2.41,0,0,1,1.33-.93,1.59,1.59,0,0,1,1.71,1,6.56,6.56,0,0,1,.17,2.2c0,1.5.08,3.15.24,4.87a81.36,81.36,0,0,0,1.38,8.75,31.21,31.21,0,0,1,.71,3.62,4.27,4.27,0,0,1-.35-.93c-.18-.61-.44-1.5-.72-2.6a60.35,60.35,0,0,1-1.54-8.79,47.37,47.37,0,0,1-.2-4.92,6.35,6.35,0,0,0-.09-2,1.21,1.21,0,0,0-1.24-.8,2.17,2.17,0,0,0-1.17.76,7.45,7.45,0,0,0-.7.9A4.37,4.37,0,0,1,241.57,153.06Z"
        style="fill: rgb(235, 153, 110); transform-origin: 244.865px 160.432px"
        id="el803p0r2zz2r"
        class="animable"
      ></path>
      <path
        d="M242.23,166.42a1.34,1.34,0,0,1-.08-.45,8.59,8.59,0,0,1,0-1.28,5.38,5.38,0,0,1,.47-1.95,3.19,3.19,0,0,1,2-1.73l.25-.08,0,.27c.23,1.83.52,4,.91,6.31.57,3.43,1.22,6.52,1.72,8.74a34.59,34.59,0,0,1,.76,3.62,3.45,3.45,0,0,1-.36-.93c-.2-.61-.46-1.49-.76-2.6-.59-2.21-1.3-5.29-1.87-8.74-.39-2.32-.66-4.5-.85-6.35l.29.19a2.82,2.82,0,0,0-1.79,1.46,5.59,5.59,0,0,0-.54,1.82C242.26,165.79,242.3,166.42,242.23,166.42Z"
        style="fill: rgb(235, 153, 110); transform-origin: 245.193px 170.4px"
        id="el9rpj8e6esgj"
        class="animable"
      ></path>
      <path
        d="M248.48,183.48c-.12-.09.11-.56.11-1.16s-.18-1.08-.06-1.17.6.38.58,1.18S248.57,183.59,248.48,183.48Z"
        style="fill: rgb(235, 153, 110); transform-origin: 248.778px 182.318px"
        id="elk5j9xbbjowk"
        class="animable"
      ></path>
      <path
        d="M249.39,175.16c-.15,0-.07-.79-.2-1.68s-.39-1.59-.26-1.66.64.58.78,1.59S249.52,175.2,249.39,175.16Z"
        style="fill: rgb(235, 153, 110); transform-origin: 249.319px 173.488px"
        id="elqvrj3mdt6c9"
        class="animable"
      ></path>
      <g id="elm1o69m787ad">
        <g
          style="opacity: 0.3; transform-origin: 245.17px 235.335px"
          class="animable"
        >
          <path
            d="M253.49,215.34c-1.85-2.14-4.19-4.29-7-4.27-2.51,0-4.65,1.75-6.43,3.51a72.48,72.48,0,0,0-13.87,20.12c-3.59,7.35-7.94,16.19-10.67,23.9,6-1.26,11.32-5,15.37-9.63s7-10.14,9.57-15.71c.9-1.92,2.19-4.15,4.31-4.2,1.56,0,2.84,1.21,3.82,2.43,2.81,3.55,4.7,7.73,6.68,11.8s4.14,8.15,7.36,11.34,7.69,5.4,12.19,4.9C269.68,243.88,264.29,227.78,253.49,215.34Z"
            id="el6iloqhim52l"
            class="animable"
            style="transform-origin: 245.17px 235.335px"
          ></path>
        </g>
      </g>
      <path
        d="M192.17,158s-13.11,8.26-13.5,24.53.58,59.64,3.49,65.45,5,26.72,23.82,18c10.55-4.89,22-20.76,29.5-34.08a144,144,0,0,0,9.12-19.36l-17.71-11.42-22.27,26.33-.69-25.32Z"
        style="fill: rgb(255, 114, 94); transform-origin: 211.594px 213.039px"
        id="el5zv8nq1oau2"
        class="animable"
      ></path>
      <path
        d="M204.62,227.42c0,.09-.8.14-2,.48a13,13,0,0,0-4.38,2.25,23.46,23.46,0,0,0-3.54,3.51,12.57,12.57,0,0,1-1.36,1.59,6.57,6.57,0,0,1,1.07-1.81,17.78,17.78,0,0,1,3.51-3.7,11.24,11.24,0,0,1,4.61-2.19A5.15,5.15,0,0,1,204.62,227.42Z"
        style="fill: rgb(38, 50, 56); transform-origin: 198.98px 231.309px"
        id="elrh80xiszd2p"
        class="animable"
      ></path>
      <path
        d="M204.51,227.22a38.75,38.75,0,0,0-5.22.21,41.17,41.17,0,0,0-5,1.41,10.28,10.28,0,0,1,4.94-1.92A10.14,10.14,0,0,1,204.51,227.22Z"
        style="fill: rgb(38, 50, 56); transform-origin: 199.4px 227.772px"
        id="elbachwwod7fg"
        class="animable"
      ></path>
      <path
        d="M244.6,212.51a2.35,2.35,0,0,1-.15.54l-.54,1.51c-.47,1.31-1.22,3.17-2.2,5.46a128.21,128.21,0,0,1-9.12,17.14,144.78,144.78,0,0,1-11.51,15.64c-1.6,1.9-2.93,3.41-3.88,4.42l-1.1,1.17a2.82,2.82,0,0,1-.41.38,2.36,2.36,0,0,1,.33-.45l1-1.23c.9-1.06,2.18-2.6,3.75-4.52a166.61,166.61,0,0,0,11.35-15.69,143.38,143.38,0,0,0,9.22-17c1-2.26,1.83-4.1,2.35-5.39.26-.59.46-1.08.63-1.47A2.23,2.23,0,0,1,244.6,212.51Z"
        style="fill: rgb(38, 50, 56); transform-origin: 230.145px 235.64px"
        id="el2bsfs47zwcy"
        class="animable"
      ></path>
      <path
        d="M203.93,202.1a2.7,2.7,0,0,1,0,.48c0,.36.05.82.08,1.39.05,1.26.12,3,.21,5.22.15,4.52.35,10.83.59,18.22l-.42-.15,9.74-11.55,12.53-14.77.13-.16.17.11,12.78,8.36,3.57,2.38.93.64a1.62,1.62,0,0,1,.31.24,1.48,1.48,0,0,1-.34-.18l-1-.58-3.64-2.28-12.87-8.19.29-.05-12.48,14.82-9.77,11.53-.42.48v-.63c-.16-7.4-.3-13.71-.4-18.23,0-2.2-.06-4-.08-5.23v-1.39A3.06,3.06,0,0,1,203.93,202.1Z"
        style="fill: rgb(38, 50, 56); transform-origin: 224.195px 214.42px"
        id="el7u4ypa5isoi"
        class="animable"
      ></path>
      <path
        d="M298.74,152.9s11.08,5.61,11.57,31.66,2.43,56.95,0,62.31c-1.84,4-6.16,16.25-14,20.43-2.36,1.25-4.15,1.31-7.37.84-10.7-1.56-24.74-14.07-35.41-34.61-8.12-15.63-8.62-22.87-8.62-22.87l14.9-15,29.09,36.07.54-34.58Z"
        style="fill: rgb(255, 114, 94); transform-origin: 278.209px 210.655px"
        id="eldeivm3bbqgn"
        class="animable"
      ></path>
      <path
        d="M289.21,198.19s0,.2,0,.6,0,1,0,1.75c0,1.56,0,3.81,0,6.7,0,5.84-.08,14.22-.14,24.49v.55l-.34-.43-29.14-36,.38,0-11.52,11.61-3.38,3.4.07-.24A95.21,95.21,0,0,0,259.59,243a84.25,84.25,0,0,0,19.94,20.84,28.13,28.13,0,0,0,5,3,14.77,14.77,0,0,0,2.52.87,22.43,22.43,0,0,0,2.49.38,13.17,13.17,0,0,0,8-1.57,12.94,12.94,0,0,0,3.79-3.58c.35-.48.61-.87.77-1.13s.27-.38.27-.38a14.65,14.65,0,0,1-1,1.57,12.59,12.59,0,0,1-3.76,3.69,13.35,13.35,0,0,1-8.14,1.68c-.81-.1-1.66-.17-2.53-.36a14.57,14.57,0,0,1-2.58-.88,27.65,27.65,0,0,1-5.06-3,83.91,83.91,0,0,1-20.12-20.86,95.23,95.23,0,0,1-14.56-32.57l0-.14.1-.1,3.38-3.41,11.53-11.6.2-.21.18.23c11,13.68,21,26.09,29,36.11l-.35.12c.14-10.27.24-18.64.32-24.48.05-2.89.09-5.14.12-6.7,0-.76,0-1.34,0-1.75A5.45,5.45,0,0,1,289.21,198.19Z"
        style="fill: rgb(38, 50, 56); transform-origin: 273.495px 231.848px"
        id="el1xzq6sxzkwx"
        class="animable"
      ></path>
      <path
        d="M300.78,245.16a19.07,19.07,0,0,1-1.5-2.15,47.21,47.21,0,0,0-3.9-4.94,59.51,59.51,0,0,0-4.49-4.41,15.71,15.71,0,0,1-1.93-1.79,11.18,11.18,0,0,1,2.16,1.51,38.42,38.42,0,0,1,4.65,4.34,34,34,0,0,1,3.82,5.09A9.71,9.71,0,0,1,300.78,245.16Z"
        style="fill: rgb(38, 50, 56); transform-origin: 294.87px 238.515px"
        id="el2pq5atr7b6n"
        class="animable"
      ></path>
      <path
        d="M297.55,158.12c.07.06-.8,1.13-2,3a39.92,39.92,0,0,0-6.07,16.09c-.32,2.2-.38,3.58-.47,3.58a4,4,0,0,1-.05-1,23.12,23.12,0,0,1,.16-2.65,34.7,34.7,0,0,1,6.13-16.25,24.09,24.09,0,0,1,1.63-2.09A3.6,3.6,0,0,1,297.55,158.12Z"
        style="fill: rgb(38, 50, 56); transform-origin: 293.251px 169.455px"
        id="elht8a6gh4645"
        class="animable"
      ></path>
      <path
        d="M201.49,184.8c-.08,0-.26-1.33-.66-3.48a75.06,75.06,0,0,0-2-8.29,79.25,79.25,0,0,0-2.94-8,34.45,34.45,0,0,1-1.39-3.26,3.6,3.6,0,0,1,.52.8c.31.53.72,1.32,1.2,2.31a56.71,56.71,0,0,1,3.11,8,58,58,0,0,1,1.87,8.38c.15,1.09.24,2,.28,2.58A3.22,3.22,0,0,1,201.49,184.8Z"
        style="fill: rgb(38, 50, 56); transform-origin: 198.011px 173.285px"
        id="elebvm2voa3dn"
        class="animable"
      ></path>
      <g id="elrpztrs3s1w">
        <g
          style="opacity: 0.3; transform-origin: 295.576px 238.906px"
          class="animable"
        >
          <path
            d="M302.23,245.09a10.77,10.77,0,0,0-3.15-5.5,37.57,37.57,0,0,0-10.19-7.73,55,55,0,0,1,12.05,13.44c.25.4.74.85,1.11.56A.8.8,0,0,0,302.23,245.09Z"
            id="elrgxd7xh9nhi"
            class="animable"
            style="transform-origin: 295.576px 238.906px"
          ></path>
        </g>
      </g>
      <g id="elpjy41xvzmhd">
        <g
          style="opacity: 0.3; transform-origin: 197.145px 233.059px"
          class="animable"
        >
          <path
            d="M204.43,227.39a13.81,13.81,0,0,0-14.85,8.42,3.85,3.85,0,0,0-.36,1.66,1.55,1.55,0,0,0,.89,1.35,1.61,1.61,0,0,0,1.74-.58,6.06,6.06,0,0,0,.9-1.73,15.15,15.15,0,0,1,12.32-9.1Z"
            id="elowzowwi1d3l"
            class="animable"
            style="transform-origin: 197.145px 233.059px"
          ></path>
        </g>
      </g>
    </g>
    <g
      id="freepik--Table--inject-2"
      class="animable"
      style="transform-origin: 250px 474.1px"
    >
      <path
        d="M460,474.1c0,.14-94,.26-210,.26s-210-.12-210-.26,94-.26,210.05-.26S460,474,460,474.1Z"
        style="fill: rgb(38, 50, 56); transform-origin: 250px 474.1px"
        id="ely5qscebw7og"
        class="animable"
      ></path>
    </g>
    <defs>
      <filter id="active" height="200%">
        <feMorphology
          in="SourceAlpha"
          result="DILATED"
          operator="dilate"
          radius="2"
        ></feMorphology>
        <feFlood
          flood-color="#32DFEC"
          flood-opacity="1"
          result="PINK"
        ></feFlood>
        <feComposite
          in="PINK"
          in2="DILATED"
          operator="in"
          result="OUTLINE"
        ></feComposite>
        <feMerge>
          <feMergeNode in="OUTLINE"></feMergeNode>
          <feMergeNode in="SourceGraphic"></feMergeNode>
        </feMerge>
      </filter>
      <filter id="hover" height="200%">
        <feMorphology
          in="SourceAlpha"
          result="DILATED"
          operator="dilate"
          radius="2"
        ></feMorphology>
        <feFlood
          flood-color="#ff0000"
          flood-opacity="0.5"
          result="PINK"
        ></feFlood>
        <feComposite
          in="PINK"
          in2="DILATED"
          operator="in"
          result="OUTLINE"
        ></feComposite>
        <feMerge>
          <feMergeNode in="OUTLINE"></feMergeNode>
          <feMergeNode in="SourceGraphic"></feMergeNode>
        </feMerge>
        <feColorMatrix
          type="matrix"
          values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 "
        ></feColorMatrix>
      </filter>
    </defs>
  </svg>
</template>
<script>
export default {
  name: "AcivationErrorIlustration",
};
</script>
<style>
svg#freepik_stories-feeling-sorry:not(.animated) .animable {
  opacity: 0;
}
svg#freepik_stories-feeling-sorry.animated
  #freepik--background-complete--inject-2 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) slideRight;
  animation-delay: 0s;
}
svg#freepik_stories-feeling-sorry.animated #freepik--Plant--inject-2 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) slideLeft;
  animation-delay: 0s;
}
svg#freepik_stories-feeling-sorry.animated #freepik--speech-bubble--inject-2 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) slideLeft;
  animation-delay: 0s;
}
svg#freepik_stories-feeling-sorry.animated #freepik--Character--inject-2 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) slideLeft;
  animation-delay: 0s;
}
svg#freepik_stories-feeling-sorry.animated #freepik--Table--inject-2 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) zoomOut;
  animation-delay: 0s;
}
@keyframes slideRight {
  0% {
    opacity: 0;
    transform: translateX(30px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes slideLeft {
  0% {
    opacity: 0;
    transform: translateX(-30px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes zoomOut {
  0% {
    opacity: 0;
    transform: scale(1.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
