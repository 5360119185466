<template>
  <v-form style="width: 100%" @submit.prevent="">
    <v-row class="ma-0 pa-0">
      <v-col cols="12">
        <PasswordField
          v-model="newPassword"
          :value="newPassword"
          ref="activationPassword"
          required
          :label="noChanges ? 'current_password' : 'new_password'"
          :min="minSize"
          max="30"
          :rules="companyRules"
          filled
          :loading="loading || localLoading"
          validate-on-blur
          @valid="setPasswordError"
          @update:password="passInputEventHandler"
        />
      </v-col>

      <v-scroll-x-transition>
        <v-col
          cols="12"
          v-if="getConfirmation && newPassword.length > 0"
          class="mt-3"
        >
          <PasswordField
            v-model="confirmNewPassword"
            :value="confirmNewPassword"
            ref="activationConfirmPassword"
            :label="noChanges ? 'confirm_password' : 'confirm_new_password'"
            :min="minSize"
            max="30"
            :error="newPassword != confirmNewPassword"
            hide-details
            filled
            :loading="loading || localLoading"
            @valid="setConfirmationError"
            @update:password="confirmPassInputEventHandler"
          />
          <v-input
            :value="newPassword == confirmNewPassword"
            :rules="[equalPasswords]"
          />
        </v-col>
      </v-scroll-x-transition>

      <!-- TODO: Implementar quando/se houver prioridade -->
      <!-- <v-col cols="12">
      <v-btn
        color="primary"
        text
        large
        class="text-none font-weight-medium text-body-1"
      >
        Gerar uma senha aleatória
      </v-btn>
    </v-col> -->

      <v-col cols="12">
        <v-list class="px-0">
          <v-list-item
            v-for="({ value, text }, index) in checkedRules"
            :key="index"
            class="px-0"
          >
            <v-list-item-action class="mr-4">
              <v-icon
                v-text="value ? 'mdi-check-circle' : 'mdi-close-circle'"
                :color="value ? 'success' : 'error'"
              />
            </v-list-item-action>

            <v-list-item-title class="text-wrap break-word">
              {{ text }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

import PasswordField from "@/components/forms/fields/PasswordField.vue";

export default {
  name: "DefaultPasswordForm",

  props: {
    backgroundColor: { type: String, default: "" },
    customClass: { type: String, default: "" },
    customStyle: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    filled: { type: Boolean, default: false },
    getConfirmation: { type: Boolean, default: false },
    label: { type: String, default: "default_password" },
    loading: { type: Boolean, default: false },
    max: { type: [String, Number], default: 5 },
    min: { type: [String, Number], default: 5 },
    outlined: { type: Boolean, default: false },
    solo: { type: Boolean, default: false },
    soloInverted: { type: Boolean, default: false },
    value: { type: String, default: "" },
  },

  components: { PasswordField },

  data() {
    return {
      appliedRules: [],

      confirmNewPassword: "",

      hasPasswordError: true,

      newPassword: "",

      localLoading: false,

      passwordInvalid: true,
      passwordConfirmationInvalid: true,

      rules: {
        min_len: (v) =>
          (!!v && v.length >= this.minSize) ||
          `${this.$ml.get("min_len_label")} ${this.minSize}`,

        has_upper: (v) =>
          /[A-Z]/.test(v) || this.$ml.get("must_have_a_upper_letter"),

        has_lower: (v) =>
          /[a-z]/.test(v) || this.$ml.get("must_have_a_lower_letter"),

        has_number: (v) => /\d/.test(v) || this.$ml.get("must_have_a_number"),
        //eslint-disable-next-line
        has_symbol: (v) =>
          /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(v) ||
          this.$ml.get("must_have_a_symbol"),
      },

      showText: false,
    };
  },

  computed: {
    ...mapGetters(["company", "passwordStrength", "defaultPassword"]),

    checkedRules() {
      return [
        {
          value: this.hasMinLen,
          text: `${this.$ml.get("min_len_label")} ${this.minimumSize}`,
          show: this.passwordStrength.min_len || false,
        },

        {
          value: this.hasUpper,
          text: this.$ml.get("must_have_a_upper_letter"),
          show: this.passwordStrength.has_upper || false,
        },

        {
          value: this.hasLower,
          text: this.$ml.get("must_have_a_lower_letter"),
          show: this.passwordStrength.has_lower || false,
        },

        {
          value: this.hasNumber,
          text: this.$ml.get("must_have_a_number"),
          show: this.passwordStrength.has_number || false,
        },

        {
          value: this.hasSymbol,
          text: this.$ml.get("must_have_a_symbol"),
          show: this.passwordStrength.has_symbol || false,
        },
      ].filter((item) => item.show);
    },

    companyRules() {
      return this.appliedRules;
    },

    hasLower() {
      return !!this.newPassword && /[a-z]/.test(this.newPassword);
    },

    hasMinLen() {
      return (
        this.passwordStrength["min_len"] &&
        typeof this.newPassword === "string" &&
        this.newPassword.length >= parseInt(this.passwordStrength["min_len"])
      );
    },

    hasNumber() {
      return !!this.newPassword && /\d/.test(this.newPassword);
    },

    hasSymbol() {
      return (
        !!this.newPassword &&
        /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(this.newPassword)
      );
    },

    hasUpper() {
      return !!this.newPassword && /[A-Z]/.test(this.newPassword);
    },

    minimumSize() {
      return this.passwordStrength.min_len;
    },

    minSize() {
      if (this.passwordStrength.min_len) {
        return this.passwordStrength.min_len;
      }
      return this.min;
    },

    noChanges() {
      return this.newPassword === this.defaultPassword;
    },
  },

  watch: {
    company() {
      this.reset();
    },

    newPassword() {
      this.$emit("update:password", this.newPassword);
    },

    value() {
      this.reset();
    },
  },

  methods: {
    ...mapActions(["editDefaultPassword"]),

    equalPasswords(value) {
      return value || this.$ml.get("passwords_do_not_match");
    },

    reset() {
      this.newPassword = this.defaultPassword;

      this.confirmNewPassword = "";
      this.hasPasswordError = this.defaultPassword ? false : true;
      this.localLoading = false;
      this.setRules();
    },

    setConfirmationError(status) {
      this.passwordConfirmationInvalid = !status;

      this.$emit(
        "confirmation-password:valid",
        this.passwordConfirmationInvalid
      );
    },

    async setDefaultPassword() {
      this.localLoading = true;

      const payload = {
        password: this.newPassword,
      };

      await this.editDefaultPassword(payload).finally(() => {
        this.reset();
      });

      this.localLoading = false;
    },

    setPasswordError(status) {
      this.passwordInvalid = !status;

      this.$emit("password:valid", this.passwordInvalid);
    },

    setRules() {
      this.appliedRules = [];

      if (this.passwordStrength) {
        for (let key in this.passwordStrength) {
          if (this.passwordStrength[key]) {
            this.appliedRules.push(this.rules[key]);
          }
        }
      }
    },

    passInputEventHandler(inputValue) {
      this.newPassword = inputValue;

      const diferent = this.newPassword !== this.confirmNewPassword;

      if (diferent && !this.passwordConfirmationInvalid) {
        this.passwordConfirmationInvalid = true;

        this.emitValidRulesForConfirmationInput();
      }
    },

    confirmPassInputEventHandler(inputValue) {
      this.confirmNewPassword = inputValue;

      const equals = this.newPassword === this.confirmNewPassword;

      if (equals && this.passwordConfirmationInvalid) {
        this.passwordConfirmationInvalid = false;
        this.emitValidRulesForConfirmationInput();
      }
    },

    emitValidRulesForConfirmationInput() {
      this.$emit(
        "confirmation-password:valid",
        this.passwordConfirmationInvalid
      );
    },
  },

  beforeMount() {
    this.reset();
  },
};
</script>
