<template>
  <div>
    <v-card class="pb-5 mb-5 rounted-t-0" width="100%">
      <v-row justify="center" align="center" class="mb-8 pt-7">
        <v-col :class="$vuetify.breakpoint.mdAndUp ? 'px-6' : ''">
          <v-card
            flat
            class="d-flex flex-column justify-center align-center mt-8"
            style="width: 100%"
          >
            <v-card-title
              class="primary--text text-h6 text-md-h4 font-weight-bold text-center break-word text-wrap normal-line-height"
            >
              {{ $ml.get("whatDoYouWantToDoWithLoggedUsers") }}
            </v-card-title>

            <v-item-group
              v-model="selectedLogoutOption"
              mandatory
              class="mt-4"
              :disabled="loading"
            >
              <v-row class="pa-0 ma-0" justify="center" align="stretch">
                <v-col
                  v-for="(logoutOption, index) in logoutOptions"
                  :key="index"
                  cols="12"
                  md="4"
                >
                  <v-item v-slot="{ active, toggle }">
                    <v-card
                      :class="`d-flex flex-column align-center justify-center py-4 fill-height mx-auto ${
                        active ? 'amber lighten-5' : ''
                      }`"
                      outlined
                      hover
                      height="100%"
                      :disabled="loading"
                      @click="toggle"
                    >
                      <v-icon
                        v-text="logoutOption.icon"
                        color="accent"
                        size="65"
                      />

                      <v-card-title
                        :class="`text-wrap break-word text-center text-body-1 text-md-h5 font-weight-medium ${
                          active
                            ? 'accent--text'
                            : 'secondary--text text--lighten-1'
                        }`"
                        style="max-width: 70%"
                      >
                        {{ $ml.get(logoutOption.title) }}
                      </v-card-title>

                      <v-card-text
                        class="mt-1 text-wrap break-word normal-line-height text-center text-body-2 text-md-body-1"
                      >
                        {{ $ml.get(logoutOption.subtitle) }}
                      </v-card-text>
                    </v-card>
                  </v-item>
                </v-col>
              </v-row>
            </v-item-group>
          </v-card>
        </v-col>
      </v-row>

      <v-row dense align="center" class="px-4">
        <v-col
          :order="$vuetify.breakpoint.xs ? 2 : 1"
          cols="12"
          xs="12"
          sm="1"
          md="3"
          lg="5"
        >
          <v-btn
            text
            color="secondary lighten-2"
            :block="$vuetify.breakpoint.xs"
            :large="$vuetify.breakpoint.xs"
            class="mx-auto text-none text-body-2 text-md-body-1 font-weight-medium"
            :disabled="loading"
            @click="$emit('previous')"
          >
            {{ $ml.get("previous") }}
          </v-btn>
        </v-col>
        <v-col
          :order="$vuetify.breakpoint.xs ? 1 : 2"
          cols="12"
          xs="12"
          sm="6"
          md="5"
          lg="4"
          class="d-flex justify-end"
        >
          <v-btn
            outlined
            x-large
            color="accent"
            :class="`text-none text-body-2 text-md-body-1 font-weight-bold px-11`"
            :loading="loading"
            :block="$vuetify.breakpoint.xs"
            @click="showScheduleActivation = true"
            :style="$vuetify.breakpoint.mdAndUp ? 'max-width: 100%' : ''"
          >
            {{ $ml.get("schedule_activation") }}
            <v-icon v-text="'mdi-chevron-right'" right />
          </v-btn>
        </v-col>
        <v-col
          :order="$vuetify.breakpoint.xs ? 1 : 2"
          cols="12"
          sm="5"
          md="4"
          lg="3"
          class="d-flex justify-end"
        >
          <v-btn
            x-large
            color="accent"
            :class="`text-none text-body-2 text-md-body-1 font-weight-bold px-11`"
            :loading="loading"
            :block="$vuetify.breakpoint.xs"
            @click="$emit('next', selectedLogoutOption)"
            :style="$vuetify.breakpoint.mdAndUp ? 'max-width: 100%' : ''"
          >
            {{ $ml.get("active_control") }}
            <v-icon v-text="'mdi-chevron-right'" right />
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <ScheduleActivationDialog
      :show="showScheduleActivation"
      @close="showScheduleActivation = false"
    />
  </div>
</template>
<script>
import ScheduleActivationDialog from "./ScheduleActivationDialog.vue";

export default {
  name: "SelectLogoutOptionStep",

  props: {
    loading: { type: Boolean, default: false },
  },

  components: { ScheduleActivationDialog },

  data() {
    return {
      selectedLogoutOption: "skip",
      showScheduleActivation: false,
    };
  },

  computed: {
    logoutOptions() {
      return [
        {
          title: "dont_force_logout",
          subtitle: "dontForceLogoutTip",
          icon: "mdi-account-multiple-check-outline",
          value: "skip",
        },
        {
          title: "force_logout_to_all_users",
          subtitle: "forceLogoutToAllUsersTip",
          icon: "mdi-account-multiple-remove-outline",
          value: "forceAll",
        },
        {
          title: "redirect_to_force_logout",
          subtitle: "redirectToForceLogoutTip",
          icon: "mdi-account-box-multiple-outline",
          value: "redirect",
        },
      ];
    },
  },
};
</script>
