<template>
  <v-dialog v-model="showHide" persistent :max-width="maxWidth" scrollable>
    <v-card class="py-3 d-flex flex-column justify-center align-center">
      <v-card-title class="text-h6 text-flex break-word d-flex justify-center">
        {{ $ml.get("redirectTitle") }}
      </v-card-title>

      <v-card-title
        class="text-h1 d-flex justify-center align-end accent--text"
        v-if="showInterval"
      >
        {{ timer }}

        <span class="text-body-1 ml-1 mb-2 secondary--text font-italic">
          {{
            timer === 1
              ? $ml.get("second").toLowerCase()
              : $ml.get("seconds").toLowerCase()
          }}
        </span>
      </v-card-title>

      <v-card-text v-if="customDescription" class="text-none text-center mt-5">
        <slot />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "RedirectDialog",

  props: {
    show: { type: Boolean, default: false },
    time: { type: Number, default: 6000 },
    maxWidth: { type: [Number, String], default: 500 },
    customDescription: { type: Boolean, default: false },
  },

  data() {
    return {
      timer: 6,
      timerInterval: null,
      showInterval: false,
    };
  },

  computed: {
    ...mapGetters([]),

    showHide: {
      get() {
        return this.show;
      },
      set() {
        return this.$emit("close");
      },
    },
  },

  watch: {
    showHide() {
      this.resetTimer();
      if (this.showHide) {
        this.showInterval = true;
      }
      this.setTimer();
    },
  },

  methods: {
    decrementTimer() {
      if (this.timer > 0) {
        this.timer--;
      } else {
        this.resetTimer();
      }
    },

    resetTimer() {
      clearInterval(this.timerInterval);
      this.timer = 6;
    },

    setTimer() {
      if (this.showInterval) {
        this.timerInterval = setInterval(this.decrementTimer, 1000);

        setTimeout(() => {
          if (this.showHide) {
            this.showInterval = false;
            this.showHide = false;
            this.showHelpWithPayment = true;
            this.$emit("redirect");
          }
        }, this.time);
      }
    },
  },

  beforeMount() {
    this.setTimer();
  },
};
</script>
