<template>
  <svg
    class="animated"
    id="freepik_stories-key"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
  >
    <defs>
      <clipPath id="freepik--clip-path--inject-2">
        <path
          d="M50.41,309.94s-13.85-2-14.56,6.17,9,26.87,23.07,24.23v-4.75s-13.68,2.58-17.36-15c-2-9.72,11-4.5,11-4.5Z"
          style="
            fill: #fff;
            stroke: #263238;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        ></path>
      </clipPath>
      <clipPath id="freepik--clip-path-2--inject-2">
        <path
          d="M91.66,340.34v0c-3.94,3.22-9.24,5.32-16.38,5.32-31,0-27.46-39.67-27.46-39.67h54.93S104.83,329.52,91.66,340.34Z"
          style="
            fill: #fff;
            stroke: #263238;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        ></path>
      </clipPath>
      <clipPath id="freepik--clip-path-3--inject-2">
        <polygon
          points="98.32 349.04 49.17 349.04 37.05 344.47 110.45 344.47 98.32 349.04"
          style="
            fill: #fff;
            stroke: #263238;
            stroke-linecap: round;
            stroke-linejoin: round;
          "
        ></polygon>
      </clipPath>
    </defs>
    <g id="freepik--World--inject-2">
      <path
        d="M305.8,188.32a64.17,64.17,0,0,1-4.63,7.48,63,63,0,0,1-80.59,18.91q-2.09-1.11-4.05-2.34t-3.79-2.55q-2.22-1.62-4.26-3.4l0,0a2.55,2.55,0,0,1-.24-.23l0,0a63.08,63.08,0,1,1,97.61-17.83Z"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M306,129.92c-.67.6-1.77.78-3.51.24-6.25-1.93-9.5,5.09-8.4,5.17s1.86,3.24-.39,4.76-7.49,1-7.15,2.57,2.92,5.47,2.92,5.47,2.3-1.08,3,.27,1.39,5-.3,5.66a2.24,2.24,0,0,0-1.32,2.5l-5.05-.3L285,160l-3.11,2.61-1.66-.88s-1.47-3.81-2.87-1.52l-1.4,2.3-.9,2.06s3.63.81,4.54,2.39l.9,1.58,7.88,2.22L289,173l1.45.77.37,3.94,2.95,1.54,2.94.24,3.59-5a35.9,35.9,0,0,1,1.89,4.34c.36,1.29-.66,4.5-.66,4.5a8.05,8.05,0,0,1-2.56,3c-1.43.74-3.18-1.23-3.05,1.18l.12,2.4-.37,2.67,2.22,1.17,2.09,1.1,1.22,1"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M187.61,168.21l.88.17,4-4.42,3.6-1.46,2.58,1.36c1.07.56,2.14-2.12,2.14-2.12l-.69-.63c-.69-.63.32-4.34.32-4.34a38,38,0,0,0,6,.42c1.59-.19,2.34-.13,3-1.47s.17-1.61.17-1.61a24.07,24.07,0,0,0,1.16-5.21c0-1.73,2.58,1.35,2.58,1.35l1.26-2.39,2.83-2.24,3.68-2.56s5.35-1.54,5.77-.38,2.57,2.1,2.57,2.1l-.92,1.75s-1.18,3.64-2.32,4.41.46,6.34.46,6.34a19.09,19.09,0,0,0-2.78,3.33c-.7,1.34-1.38,1.33-1.38,1.33l-3.49-.48s-4-2.79-5.32-3.49c0,0-2.34,2.51-2.11,4s-.82,1.56-.82,1.56l3.68,3.35.6,2.1,2.2,3-.71,1.35-1.34,2.54.59,2.77,2.39,3.21,1.34,2.92,1.46,4.71.46,2.61,4.3,1.93,1.9-1,3.7-2,1.75,3.48.69,1.75L230.67,199l1.09,2.27.36,2.58-.31,3.44-.63,2.2,2.57,1.35,4.56-.66-.16-3.49a27,27,0,0,1,4.74-2.22c2-.55,1.75-.52,4.14-1.25s1.66-.54,2.81-.93,2.27-.84,2.27-.84l.09-3.63,1.89-6.58,1.17.77c1.16.78,2.9-2,2.9-2l.82-5-2.31-1.21a25.94,25.94,0,0,0-4.16-.49l-3.3-1.74-2.75-4.86a15.9,15.9,0,0,1-2.3-3.25l1.27-2.4,1.56-3,1.63.86,1.5.79,1.59.84,2.6-1.69,1.45-4.06,2-1.78c1.31-1.18,2.21,1,2.21,1l2.14-2.69c2.15-2.68.38-1.44-.72-2.82a2.73,2.73,0,0,0-2.31-1.22l-3.91-4.67-3.07,1.12-.43,1.46c-.44,1.48,0,0-4.51-1.77l-1.49-4.31.89-1,.22-4s-2.6-1.26-4.56-2.32-2.43-3.78-2.43-3.78a9.85,9.85,0,0,1,.89-4.31l-1-2.84-3.69-7.21.63-3.25,2.14-1.35.85-4.32L235.67,111l1.55-2.94,1.1-2.09,1.29-2.44,1-4.95s-.24-.91-.48-1.94"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M208.48,206.42a1.38,1.38,0,0,0-.26-.25l0,0L209.3,204l-.44-3.95-1.47-.78s1.79-1.78,1.68-3.19A2.38,2.38,0,0,1,210.2,194l-.48-2.64-.8-.42c-.8-.43-.32-2.64-.32-2.64l-3.42.67-.93,3.6-3.48.89a4.09,4.09,0,0,1-1.67-2.92c.07-1.68.71-1.34.71-1.34H203l2-3.8-.4-1.82-1.45-.77"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M203.16,182.77h0c.16.09-.87-4.55-.87-4.55s-1.28-3.39-2.68-4-4.56,1.55-4.56,1.55l-3.53.5-.8,4.23"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M217.37,209.79l-.84,2.58q-2-1.22-3.79-2.55l3.95-3.91-.34-4.27s-.72-1.58.67-1.27a3,3,0,0,0,3.18-1.13c.83-.92.72-1.33,2.84-.21s2.26,1.88,2.57,2.73-3.65,6.93-3.65,6.93l1.4.74s-.82,1.9.57,2.31Z"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M264.36,220.33a63.06,63.06,0,0,1-22.15,1.19l.77-2.78s-.73-1.21,2.2-.94,5.16.55,5.16.55l2.33-4.41h4.09l1.34.71,1.15,1.68,2.49,2.4A28.23,28.23,0,0,1,264.36,220.33Z"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M262.32,178.17l2.46-.78,2.15,2.17s4.25.55,2.6,2.4-4.83,1.83-4.83,1.83L263.55,186l-2-2.43-1-1.95.76-1.43Z"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M265.86,168.88a5.62,5.62,0,0,0-1.09,2.06l-.15.94-4-2.11,2.17-4.13Z"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M399.44,82a6.93,6.93,0,1,1-6.93-6.92A6.92,6.92,0,0,1,399.44,82Z"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M360.29,191a4.37,4.37,0,1,1-4.37-4.38A4.37,4.37,0,0,1,360.29,191Z"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M234.75,52.52a4.38,4.38,0,1,1-4.37-4.37A4.37,4.37,0,0,1,234.75,52.52Z"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <circle
        cx="462.47"
        cy="184.55"
        r="10.86"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></circle>
      <circle
        cx="89.03"
        cy="76.7"
        r="6.69"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></circle>
      <circle
        cx="54.29"
        cy="211.9"
        r="11.68"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></circle>
      <circle
        cx="157.99"
        cy="132.27"
        r="3.37"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></circle>
      <path
        d="M233.93,82.11c.27,2.28.54,4.57.82,6.85"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M231.38,60.86,233.43,78"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M318.8,118.71c-1.85.83-3.7,1.65-5.57,2.45"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M357.5,99.44c-11.2,5.79-22.36,11.67-33.79,17"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M374.14,91c-4.19,2-8.35,4.16-12.5,6.29"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M381.68,87.39c-1.17.54-2.33,1.09-3.49,1.65"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M331.88,170.25q-6.17-.74-12.34-1.49"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M391.62,176.92q-27.47-3-54.93-6.11"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M447,182.84q-25.33-2.62-50.63-5.4"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M338.59,185c-7.39-2.3-15-4-22.2-6.93"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M348.39,188.56q-2.52-1.09-5.12-2"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M165,134.16l19.37,5.19"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M156.08,112.24c10.38,5.78,20.83,11.47,31.53,16.66"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M108.58,86.74C123.4,93.9,137.73,102,152.1,110"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M100.12,82.58c1.22.58,2.43,1.17,3.63,1.76"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M177.33,185.47l4.75-1.1"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M102,201.52c23.49-5.2,47-10.43,70.61-15"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M70.73,208.35q13.6-2.91,27.18-5.94"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
    </g>
    <g id="freepik--Desk--inject-2">
      <line
        x1="18.56"
        y1="349.6"
        x2="479.04"
        y2="349.6"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></line>
    </g>
    <g id="freepik--Coffee--inject-2">
      <path
        d="M50.41,309.94s-13.85-2-14.56,6.17,9,26.87,23.07,24.23v-4.75s-13.68,2.58-17.36-15c-2-9.72,11-4.5,11-4.5Z"
        style="fill: #fff"
      ></path>
      <g style="clip-path: url(#freepik--clip-path--inject-2)">
        <path
          d="M50.41,309.94s-13.85-2-14.56,6.17,9,26.87,23.07,24.23v-4.75s-13.68,2.58-17.36-15c-2-9.72,11-4.5,11-4.5Z"
          style="fill: #517dca; opacity: 0.4"
        ></path>
      </g>
      <path
        d="M50.41,309.94s-13.85-2-14.56,6.17,9,26.87,23.07,24.23v-4.75s-13.68,2.58-17.36-15c-2-9.72,11-4.5,11-4.5Z"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M91.66,340.34v0c-3.94,3.22-9.24,5.32-16.38,5.32-31,0-27.46-39.67-27.46-39.67h54.93S104.83,329.52,91.66,340.34Z"
        style="fill: #fff"
      ></path>
      <g style="clip-path: url(#freepik--clip-path-2--inject-2)">
        <path
          d="M91.66,340.34v0c-3.94,3.22-9.24,5.32-16.38,5.32-31,0-27.46-39.67-27.46-39.67H57.91s1.17,17.44,7.51,25.9S91.5,340.34,91.66,340.34Z"
          style="fill: #517dca; opacity: 0.4"
        ></path>
      </g>
      <path
        d="M91.66,340.34v0c-3.94,3.22-9.24,5.32-16.38,5.32-31,0-27.46-39.67-27.46-39.67h54.93S104.83,329.52,91.66,340.34Z"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <polygon
        points="98.32 349.04 49.17 349.04 37.05 344.47 110.45 344.47 98.32 349.04"
        style="fill: #fff"
      ></polygon>
      <g style="clip-path: url(#freepik--clip-path-3--inject-2)">
        <polygon
          points="98.32 349.04 49.17 349.04 37.05 344.47 110.45 344.47 98.32 349.04"
          style="fill: #517dca; opacity: 0.4"
        ></polygon>
      </g>
      <polygon
        points="98.32 349.04 49.17 349.04 37.05 344.47 110.45 344.47 98.32 349.04"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></polygon>
      <rect
        x="32.64"
        y="341.63"
        width="82.22"
        height="3.68"
        rx="1.03"
        style="
          fill: #fff;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></rect>
      <path
        d="M73.16,261.18c-12.77,10.8,9.25,28.17,0,35.44,0,0,9.06-3.52,3.43-15.49S73.16,261.18,73.16,261.18Z"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
    </g>
    <g id="freepik--Books--inject-2">
      <path
        d="M457.34,293.24V290.6H316.05a9.66,9.66,0,0,0,0,19.32H457.34v-2.63H454V293.24Z"
        style="fill: #fff"
      ></path>
      <path
        d="M394.17,293.24h63.17V290.6H316.05a9.66,9.66,0,0,0,0,19.32H457.34v-2.63H394.17a7,7,0,0,1-7-7,7,7,0,0,1,7-7Z"
        style="fill: #517dca; opacity: 0.6000000000000001"
      ></path>
      <path
        d="M389.2,295.3a6.93,6.93,0,0,0-2.06,5,7,7,0,0,0,7,7h63.17v2.63H394.27a9.66,9.66,0,0,1,0-19.32h63.07v2.64H394.17A6.94,6.94,0,0,0,389.2,295.3Z"
        style="fill: #517dca; opacity: 0.6000000000000001"
      ></path>
      <path
        d="M389.2,295.3a6.93,6.93,0,0,0-2.06,5,7,7,0,0,0,7,7h63.17v2.63H394.27a9.66,9.66,0,0,1,0-19.32h63.07v2.64H394.17A6.94,6.94,0,0,0,389.2,295.3Z"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <line
        x1="453.95"
        y1="293.24"
        x2="453.95"
        y2="307.29"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></line>
      <line
        x1="453.95"
        y1="295.79"
        x2="420.86"
        y2="295.79"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></line>
      <line
        x1="418.11"
        y1="295.79"
        x2="414.67"
        y2="295.79"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></line>
      <line
        x1="398.83"
        y1="300.26"
        x2="429.11"
        y2="300.26"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></line>
      <line
        x1="432.89"
        y1="300.26"
        x2="441.03"
        y2="300.26"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></line>
      <line
        x1="443.41"
        y1="300.26"
        x2="453.95"
        y2="300.26"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></line>
      <line
        x1="450.3"
        y1="304.2"
        x2="439.08"
        y2="304.2"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></line>
      <line
        x1="407.45"
        y1="304.2"
        x2="405.21"
        y2="304.2"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></line>
      <path
        d="M390.59,294.22s2.92,2.79,2.92,6-2.92,6.05-2.92,6.05"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M411.71,309.92H316.05a9.66,9.66,0,0,1,0-19.32h95.66"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M319.42,326.85v2.63h141.3a9.66,9.66,0,0,0,0-19.32H319.42v2.63h3.4v14.06Z"
        style="fill: #fff"
      ></path>
      <path
        d="M434.75,326.85H319.42v2.63h141.3a9.66,9.66,0,0,0,0-19.32H319.42v2.63H434.75a7,7,0,0,1,0,14.06Z"
        style="fill: #517dca; opacity: 0.6000000000000001"
      ></path>
      <path
        d="M460.72,310.16H434.65a9.66,9.66,0,0,1,0,19.32h26.07a9.66,9.66,0,0,0,0-19.32Z"
        style="fill: #517dca"
      ></path>
      <path
        d="M439.72,324.79a7,7,0,0,0-5-12H319.42v-2.63H434.65a9.66,9.66,0,0,1,0,19.32H319.42v-2.63H434.75A7,7,0,0,0,439.72,324.79Z"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <line
        x1="322.82"
        y1="326.85"
        x2="322.82"
        y2="312.79"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></line>
      <line
        x1="322.82"
        y1="324.29"
        x2="408.06"
        y2="324.29"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></line>
      <line
        x1="410.81"
        y1="324.29"
        x2="414.24"
        y2="324.29"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></line>
      <line
        x1="430.09"
        y1="319.82"
        x2="386.77"
        y2="319.82"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></line>
      <line
        x1="382.98"
        y1="319.82"
        x2="374.85"
        y2="319.82"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></line>
      <line
        x1="372.47"
        y1="319.82"
        x2="322.82"
        y2="319.82"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></line>
      <line
        x1="326.47"
        y1="315.88"
        x2="376.8"
        y2="315.88"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></line>
      <line
        x1="421.47"
        y1="315.88"
        x2="423.7"
        y2="315.88"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></line>
      <path
        d="M438.33,325.87s-2.93-2.8-2.93-6.05,2.93-6,2.93-6"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M417.2,310.16h43.52a9.66,9.66,0,0,1,0,19.32H417.2"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M469.08,332.35v-2.64H301.71a9.67,9.67,0,0,0,0,19.33H469.08V346.4h-3.4v-14Z"
        style="fill: #fff"
      ></path>
      <path
        d="M379.82,332.35h89.26v-2.64H301.71a9.67,9.67,0,0,0,0,19.33H469.08V346.4H379.82a7,7,0,0,1-7-7,7,7,0,0,1,7-7Z"
        style="fill: #517dca; opacity: 0.6000000000000001"
      ></path>
      <path
        d="M374.86,334.41a6.93,6.93,0,0,0-2.06,5,7,7,0,0,0,7,7h89.26V349H379.93a9.67,9.67,0,0,1,0-19.33h89.15v2.64H379.82A6.93,6.93,0,0,0,374.86,334.41Z"
        style="fill: #517dca; opacity: 0.6000000000000001"
      ></path>
      <path
        d="M374.86,334.41a6.93,6.93,0,0,0-2.06,5,7,7,0,0,0,7,7h89.26V349H379.93a9.67,9.67,0,0,1,0-19.33h89.15v2.64H379.82A6.93,6.93,0,0,0,374.86,334.41Z"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <line
        x1="465.68"
        y1="332.35"
        x2="465.68"
        y2="346.4"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></line>
      <line
        x1="465.68"
        y1="334.9"
        x2="406.52"
        y2="334.9"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></line>
      <line
        x1="403.77"
        y1="334.9"
        x2="400.33"
        y2="334.9"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></line>
      <line
        x1="384.48"
        y1="339.37"
        x2="414.77"
        y2="339.37"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></line>
      <line
        x1="418.55"
        y1="339.37"
        x2="439.72"
        y2="339.37"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></line>
      <line
        x1="442.11"
        y1="339.37"
        x2="465.68"
        y2="339.37"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></line>
      <line
        x1="462.03"
        y1="343.31"
        x2="437.78"
        y2="343.31"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></line>
      <line
        x1="393.11"
        y1="343.31"
        x2="390.87"
        y2="343.31"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></line>
      <path
        d="M376.25,333.33s2.92,2.79,2.92,6-2.92,6.05-2.92,6.05"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M397.37,349H301.71a9.67,9.67,0,1,1,0-19.33h95.66"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
    </g>
    <g id="freepik--Device--inject-2">
      <path
        d="M395.64,385.24H104.36c-9.84,0-39.12-4.15-39.12-4.15H434.76S405.48,385.24,395.64,385.24Z"
        style="
          fill: #999;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M108,192.91H392a3.62,3.62,0,0,1,3.62,3.62V370.4a0,0,0,0,1,0,0H104.36a0,0,0,0,1,0,0V196.52A3.62,3.62,0,0,1,108,192.91Z"
        style="fill: #263238"
      ></path>
      <path
        d="M434.76,381.09H65.24a1.15,1.15,0,0,1-.24-2.28l39.36-8.41H395.64L435,378.81A1.15,1.15,0,0,1,434.76,381.09Z"
        style="fill: #bfbfbf; stroke: #263238; stroke-miterlimit: 10"
      ></path>
      <rect
        x="107.58"
        y="200.69"
        width="284.84"
        height="157.85"
        style="
          fill: #fff;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></rect>
      <path
        d="M256.29,365.4H243.71a1.45,1.45,0,0,1-1.45-1.45h0a1.45,1.45,0,0,1,1.45-1.46h12.58a1.45,1.45,0,0,1,1.45,1.46h0A1.45,1.45,0,0,1,256.29,365.4Z"
        style="fill: #fff"
      ></path>
      <path
        d="M252.08,196.61a2.08,2.08,0,1,1-2.08-2.07A2.08,2.08,0,0,1,252.08,196.61Z"
        style="fill: #fff"
      ></path>
      <polygon
        points="411.84 375.74 88.16 375.74 105.16 371.74 394.84 371.74 411.84 375.74"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></polygon>
      <path
        d="M286.78,201.87s-65.52,88.81-177,76.74V201.87Z"
        style="fill: #517dca; opacity: 0.15"
      ></path>
      <path
        d="M109.82,341s17.31-43.85,108.44-57.7,162.43-50.34,171.92-71.7V355H109.82Z"
        style="fill: #517dca; opacity: 0.30000000000000004"
      ></path>
      <rect
        x="109.82"
        y="201.87"
        width="280.35"
        height="5.44"
        style="fill: #517dca; opacity: 0.5"
      ></rect>
      <line
        x1="109.82"
        y1="207.31"
        x2="390.18"
        y2="207.31"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></line>
      <line
        x1="113.67"
        y1="204.46"
        x2="121.8"
        y2="204.46"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></line>
      <line
        x1="130.69"
        y1="204.46"
        x2="138.01"
        y2="204.46"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></line>
      <line
        x1="147.49"
        y1="204.46"
        x2="154.62"
        y2="204.46"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></line>
      <g style="opacity: 0.7000000000000001">
        <rect
          x="145.15"
          y="339.35"
          width="13.45"
          height="13.45"
          rx="2.22"
          style="fill: #517dca"
        ></rect>
        <rect
          x="164.78"
          y="339.35"
          width="13.45"
          height="13.45"
          rx="2.22"
          style="fill: #517dca"
        ></rect>
        <rect
          x="184.4"
          y="339.35"
          width="13.45"
          height="13.45"
          rx="2.22"
          style="fill: #517dca"
        ></rect>
        <rect
          x="204.03"
          y="339.35"
          width="13.45"
          height="13.45"
          rx="2.22"
          style="fill: #517dca"
        ></rect>
        <rect
          x="223.65"
          y="339.35"
          width="13.45"
          height="13.45"
          rx="2.22"
          style="fill: #517dca"
        ></rect>
        <rect
          x="243.27"
          y="339.35"
          width="13.45"
          height="13.45"
          rx="2.22"
          style="fill: #517dca"
        ></rect>
        <rect
          x="262.9"
          y="339.35"
          width="13.45"
          height="13.45"
          rx="2.22"
          style="fill: #517dca"
        ></rect>
        <rect
          x="282.52"
          y="339.35"
          width="13.45"
          height="13.45"
          rx="2.22"
          style="fill: #517dca"
        ></rect>
        <rect
          x="302.15"
          y="339.35"
          width="13.45"
          height="13.45"
          rx="2.22"
          style="fill: #517dca"
        ></rect>
        <rect
          x="321.77"
          y="339.35"
          width="13.45"
          height="13.45"
          rx="2.22"
          style="fill: #517dca"
        ></rect>
        <rect
          x="341.39"
          y="339.35"
          width="13.45"
          height="13.45"
          rx="2.22"
          style="fill: #517dca"
        ></rect>
      </g>
      <path
        d="M367.69,355v-5.65a2.26,2.26,0,0,0-2.26-2.26H354.85v3.49a2.23,2.23,0,0,1-2.22,2.22h-9a2.23,2.23,0,0,1-2.22-2.22v-3.49h-6.17v3.49A2.23,2.23,0,0,1,333,352.8h-9a2.23,2.23,0,0,1-2.22-2.22v-3.49H315.6v3.49a2.23,2.23,0,0,1-2.22,2.22h-9a2.22,2.22,0,0,1-2.21-2.22v-3.49H296v3.49a2.22,2.22,0,0,1-2.21,2.22h-9a2.23,2.23,0,0,1-2.22-2.22v-3.49h-6.17v3.49a2.23,2.23,0,0,1-2.22,2.22h-9a2.23,2.23,0,0,1-2.22-2.22v-3.49h-6.17v3.49a2.23,2.23,0,0,1-2.22,2.22h-9a2.23,2.23,0,0,1-2.22-2.22v-3.49H237.1v3.49a2.23,2.23,0,0,1-2.22,2.22h-9a2.23,2.23,0,0,1-2.22-2.22v-3.49h-6.17v3.49a2.23,2.23,0,0,1-2.22,2.22h-9a2.22,2.22,0,0,1-2.21-2.22v-3.49h-6.18v3.49a2.22,2.22,0,0,1-2.21,2.22h-9a2.23,2.23,0,0,1-2.22-2.22v-3.49h-6.17v3.49A2.23,2.23,0,0,1,176,352.8h-9a2.23,2.23,0,0,1-2.22-2.22v-3.49h-6.17v3.49a2.23,2.23,0,0,1-2.22,2.22h-9a2.23,2.23,0,0,1-2.22-2.22v-3.49H134.57a2.26,2.26,0,0,0-2.26,2.26V355"
        style="fill: #517dca; opacity: 0.5"
      ></path>
      <path
        d="M367.69,355v-5.65a2.26,2.26,0,0,0-2.26-2.26H354.85v3.49a2.23,2.23,0,0,1-2.22,2.22h-9a2.23,2.23,0,0,1-2.22-2.22v-3.49h-6.17v3.49A2.23,2.23,0,0,1,333,352.8h-9a2.23,2.23,0,0,1-2.22-2.22v-3.49H315.6v3.49a2.23,2.23,0,0,1-2.22,2.22h-9a2.22,2.22,0,0,1-2.21-2.22v-3.49H296v3.49a2.22,2.22,0,0,1-2.21,2.22h-9a2.23,2.23,0,0,1-2.22-2.22v-3.49h-6.17v3.49a2.23,2.23,0,0,1-2.22,2.22h-9a2.23,2.23,0,0,1-2.22-2.22v-3.49h-6.17v3.49a2.23,2.23,0,0,1-2.22,2.22h-9a2.23,2.23,0,0,1-2.22-2.22v-3.49H237.1v3.49a2.23,2.23,0,0,1-2.22,2.22h-9a2.23,2.23,0,0,1-2.22-2.22v-3.49h-6.17v3.49a2.23,2.23,0,0,1-2.22,2.22h-9a2.22,2.22,0,0,1-2.21-2.22v-3.49h-6.18v3.49a2.22,2.22,0,0,1-2.21,2.22h-9a2.23,2.23,0,0,1-2.22-2.22v-3.49h-6.17v3.49A2.23,2.23,0,0,1,176,352.8h-9a2.23,2.23,0,0,1-2.22-2.22v-3.49h-6.17v3.49a2.23,2.23,0,0,1-2.22,2.22h-9a2.23,2.23,0,0,1-2.22-2.22v-3.49H134.57a2.26,2.26,0,0,0-2.26,2.26V355"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <rect
        x="145.15"
        y="339.35"
        width="13.45"
        height="13.45"
        rx="2.22"
        style="fill: none; stroke: #263238; stroke-miterlimit: 10"
      ></rect>
      <rect
        x="164.78"
        y="339.35"
        width="13.45"
        height="13.45"
        rx="2.22"
        style="fill: none; stroke: #263238; stroke-miterlimit: 10"
      ></rect>
      <rect
        x="184.4"
        y="339.35"
        width="13.45"
        height="13.45"
        rx="2.22"
        style="fill: none; stroke: #263238; stroke-miterlimit: 10"
      ></rect>
      <rect
        x="204.03"
        y="339.35"
        width="13.45"
        height="13.45"
        rx="2.22"
        style="fill: none; stroke: #263238; stroke-miterlimit: 10"
      ></rect>
      <rect
        x="223.65"
        y="339.35"
        width="13.45"
        height="13.45"
        rx="2.22"
        style="fill: none; stroke: #263238; stroke-miterlimit: 10"
      ></rect>
      <rect
        x="243.27"
        y="339.35"
        width="13.45"
        height="13.45"
        rx="2.22"
        style="fill: none; stroke: #263238; stroke-miterlimit: 10"
      ></rect>
      <rect
        x="262.9"
        y="339.35"
        width="13.45"
        height="13.45"
        rx="2.22"
        style="fill: none; stroke: #263238; stroke-miterlimit: 10"
      ></rect>
      <rect
        x="282.52"
        y="339.35"
        width="13.45"
        height="13.45"
        rx="2.22"
        style="fill: none; stroke: #263238; stroke-miterlimit: 10"
      ></rect>
      <rect
        x="302.15"
        y="339.35"
        width="13.45"
        height="13.45"
        rx="2.22"
        style="fill: none; stroke: #263238; stroke-miterlimit: 10"
      ></rect>
      <rect
        x="321.77"
        y="339.35"
        width="13.45"
        height="13.45"
        rx="2.22"
        style="fill: none; stroke: #263238; stroke-miterlimit: 10"
      ></rect>
      <rect
        x="341.39"
        y="339.35"
        width="13.45"
        height="13.45"
        rx="2.22"
        style="fill: none; stroke: #263238; stroke-miterlimit: 10"
      ></rect>
      <path
        d="M322,279H178a10.12,10.12,0,0,1-10.12-10.12h0A10.13,10.13,0,0,1,178,258.76H322a10.13,10.13,0,0,1,10.12,10.12h0A10.12,10.12,0,0,1,322,279Z"
        style="fill: #fff"
      ></path>
      <path
        d="M322,279H178a10.12,10.12,0,0,1-10.12-10.12h0A10.13,10.13,0,0,1,178,258.76H322a10.13,10.13,0,0,1,10.12,10.12h0A10.12,10.12,0,0,1,322,279Z"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M181.33,268.42h0l-.66,6.31h-2.55l-1.32-11.84h1.81l1,9.34h0l.88-9.34h1.8l.91,9.41h0l1-9.41h1.63l-1.32,11.84H182Z"
        style="fill: #263238"
      ></path>
      <path
        d="M190.85,268.42h0l-.66,6.31h-2.55l-1.32-11.84h1.81l1,9.34h0l.88-9.34h1.8l.91,9.41h0l1-9.41h1.63L194,274.73h-2.47Z"
        style="fill: #263238"
      ></path>
      <path
        d="M200.38,268.42h0l-.66,6.31h-2.56l-1.32-11.84h1.82l1,9.34h0l.88-9.34h1.79l.92,9.41h0l1-9.41h1.62l-1.32,11.84H201Z"
        style="fill: #263238"
      ></path>
      <path d="M206.88,272.94v1.79h-1.79v-1.79Z" style="fill: #263238"></path>
      <path
        d="M208.24,262.89h2.94c1.86,0,2.78,1,2.78,2.93v6c0,1.89-.92,2.92-2.78,2.92h-2.94Zm1.86,1.69V273h1.05c.59,0,.95-.3.95-1.15v-6.16c0-.85-.36-1.15-.95-1.15Z"
        style="fill: #263238"
      ></path>
      <path
        d="M215,265.73c0-1.89,1-3,2.83-3s2.82,1.09,2.82,3v6.16c0,1.9-1,3-2.82,3s-2.83-1.08-2.83-3Zm1.86,6.28c0,.85.37,1.17,1,1.17s1-.32,1-1.17v-6.4c0-.84-.37-1.16-1-1.16s-1,.32-1,1.16Z"
        style="fill: #263238"
      ></path>
      <path
        d="M225.77,271.3h0l1.26-8.41h2.59v11.84h-1.76v-8.49h0l-1.25,8.49h-1.76l-1.35-8.37h0v8.37h-1.62V262.89h2.59Z"
        style="fill: #263238"
      ></path>
      <path
        d="M237,274.73h-1.87l-.32-2.15h-2.29l-.32,2.15h-1.71l1.9-11.84h2.72ZM232.78,271h1.79l-.88-6h0Z"
        style="fill: #263238"
      ></path>
      <path d="M237.89,262.89h1.86v11.84h-1.86Z" style="fill: #263238"></path>
      <path
        d="M242.8,266.15h0v8.58h-1.67V262.89h2.33L245.3,270h0v-7.09H247v11.84h-1.91Z"
        style="fill: #263238"
      ></path>
      <path
        d="M250,266.15h0v8.58h-1.68V262.89h2.34l1.88,7.09h0v-7.09h1.66v11.84h-1.91Z"
        style="fill: #263238"
      ></path>
      <path
        d="M261.58,274.73H259.7l-.32-2.15H257.1l-.32,2.15h-1.71L257,262.89h2.73ZM257.33,271h1.8l-.88-6h0Z"
        style="fill: #263238"
      ></path>
      <path
        d="M266.37,271.3h0l1.25-8.41h2.59v11.84h-1.76v-8.49h0l-1.25,8.49h-1.76l-1.35-8.37h0v8.37h-1.62V262.89H265Z"
        style="fill: #263238"
      ></path>
      <path
        d="M273.46,267.88H276v1.69h-2.56V273h3.22v1.69H271.6V262.89h5.08v1.69h-3.22Z"
        style="fill: #263238"
      ></path>
      <path
        d="M280.34,262.75c1.81,0,2.74,1.09,2.74,3v.37h-1.76v-.49c0-.84-.34-1.16-.93-1.16s-.93.32-.93,1.16.37,1.51,1.59,2.57c1.55,1.38,2,2.36,2,3.71,0,1.9-.94,3-2.77,3s-2.78-1.08-2.78-3v-.73h1.76V272c0,.85.38,1.15,1,1.15s1-.3,1-1.15-.37-1.51-1.59-2.57c-1.56-1.37-2.05-2.36-2.05-3.71C277.59,263.84,278.53,262.75,280.34,262.75Z"
        style="fill: #263238"
      ></path>
      <path d="M285.87,272.94v1.79h-1.79v-1.79Z" style="fill: #263238"></path>
      <path
        d="M292.45,270.32v1.57c0,1.9-.94,3-2.77,3s-2.78-1.08-2.78-3v-6.16c0-1.89,1-3,2.78-3s2.77,1.09,2.77,3v1.15h-1.76v-1.27c0-.84-.37-1.16-1-1.16s-1,.32-1,1.16V272c0,.85.38,1.15,1,1.15s1-.3,1-1.15v-1.69Z"
        style="fill: #263238"
      ></path>
      <path
        d="M293.44,265.73c0-1.89,1-3,2.82-3s2.83,1.09,2.83,3v6.16c0,1.9-1,3-2.83,3s-2.82-1.08-2.82-3ZM295.3,272c0,.85.37,1.17,1,1.17s1-.32,1-1.17v-6.4c0-.84-.38-1.16-1-1.16s-1,.32-1,1.16Z"
        style="fill: #263238"
      ></path>
      <path
        d="M304.22,271.3h0l1.25-8.41h2.59v11.84h-1.76v-8.49h0L305,274.73h-1.75l-1.36-8.37h0v8.37h-1.63V262.89h2.59Z"
        style="fill: #263238"
      ></path>
      <path
        d="M322.1,268.89a3.15,3.15,0,0,0-.48-3.83,3.16,3.16,0,0,0-4.47,4.46A3.22,3.22,0,0,0,321,270a2.59,2.59,0,0,0,.63-.47A2.63,2.63,0,0,0,322.1,268.89Z"
        style="fill: none"
      ></path>
      <path
        d="M326.29,272.78,323,269.44a4.17,4.17,0,0,0-.62-5.09,4.16,4.16,0,1,0-.8,6.5l3.34,3.34a1,1,0,0,0,1.42,0A1,1,0,0,0,326.29,272.78ZM321,270a3.22,3.22,0,0,1-3.84-.47,3.16,3.16,0,1,1,4.47-4.46,3.15,3.15,0,0,1,.48,3.83,2.63,2.63,0,0,1-.48.63A2.59,2.59,0,0,1,321,270Z"
        style="fill: #263238"
      ></path>
    </g>
    <g id="freepik--left-hand--inject-2">
      <path
        d="M236.2,404.44c-.77-3.86-1.35-5-7.71-6.56a25.22,25.22,0,0,0-12.32.59l-.58.18s-1.56-5.39-2.71-6.84-6.55-15.9-7.12-17.25a8.19,8.19,0,0,0-2.53-2.45c-1.89-1.35-4.51-2.66-6.74-2.67h-.15c-3.82.07-17.77-.88-17.77-.88s-2.12-3.92-3.85-5.37-8.29-3.08-10.21-2.69a3.46,3.46,0,0,0-.67.24h0c-2.58,1.19-8.56,5.91-8.56,5.91s-.21-.25-.55-.58a4.55,4.55,0,0,0-2.54-1.44c-1.22,0-6.39-1.5-8.5-.42a1.83,1.83,0,0,0-.56.42c-.9,1.06-5,5.38-8.7,9.1a51.45,51.45,0,0,1-6.33,5.68c-2.7,1.59-7.34,2.34-10,3.13s-11,9-11,9c-7.13,1.41-32.1,2.92-48.69,3.79-8.91.48-25.41,2.77-25.41,2.77L58.9,475.55l5.49-1.91c15-5.25,56.22-19.49,62.34-20.35,7.52-1.06,11.57-4.72,17.16-5.37s10.59-1.46,18.85,0,20.65-4,30-6.1,13.27-9.22,15.12-12.64,4.62-5.66,9.63-8,6.75-4.82,10.22-6.94c3.21-2,8.38-5.38,8.54-8.93A3.34,3.34,0,0,0,236.2,404.44Zm-33,0s-1,.5-1.64-.06a22,22,0,0,0-3.54-1.77c.57-1.55-.44-3.51-1.24-5.12a46.58,46.58,0,0,1-1.78-4.75s3.59,5.1,4.65,5.87a4.54,4.54,0,0,0,1.57.87,15.12,15.12,0,0,0,1.94,4.9Z"
        style="fill: #fff"
      ></path>
      <path
        d="M58.39,395.31c16.59-.87,41.56-2.38,48.69-3.79,0,0,8.3-8.17,11-9s7.32-1.54,10-3.13a51.45,51.45,0,0,0,6.33-5.68c-3.07,4.62-7,10.18-9.38,12.33-4.4,4-5.89,0-5.89,0C114.11,394.87,70.85,395.36,58.39,395.31Z"
        style="fill: #517dca; opacity: 0.4"
      ></path>
      <path
        d="M143.67,364.22c2.11-1.08,7.28.42,8.5.42a4.55,4.55,0,0,1,2.54,1.44c-1.41,1.19-2.35,2.1-2.35,2.1s-2.5-1.52-5.56-3.53A4,4,0,0,0,143.67,364.22Z"
        style="fill: #517dca; opacity: 0.4"
      ></path>
      <path
        d="M163.84,360.74a3.46,3.46,0,0,1,.67-.24c1.92-.39,8.48,1.25,10.21,2.69s3.85,5.37,3.85,5.37,14,.95,17.77.88h.15c1.43,5.35-5.8,7.63-5.8,7.63s-1.76-7.88-8.78-8.29-9.36,1.32-9.36,1.32c-.13-6.47-2.47-7.44-6.7-9.19A3.8,3.8,0,0,0,163.84,360.74Z"
        style="fill: #517dca; opacity: 0.4"
      ></path>
      <path
        d="M195,392.78a46.58,46.58,0,0,0,1.78,4.75c.8,1.61,1.81,3.57,1.24,5.12a22,22,0,0,1,3.54,1.77c-2.22-1.16-6.56-3.19-6.56-3.19,1.38-3.16-2.17-14.93-2.17-14.93a25.15,25.15,0,0,0,10.37-4.7c2.66-2.2,1.42-6.44,0-9.49a8.19,8.19,0,0,1,2.53,2.45c.57,1.35,6,15.81,7.12,17.25s2.71,6.84,2.71,6.84l.58-.18c-4,2.17-9.95,5.31-13,5.95a15.12,15.12,0,0,1-1.94-4.9,4.54,4.54,0,0,1-1.57-.87C198.62,397.88,195,392.78,195,392.78Z"
        style="fill: #517dca; opacity: 0.4"
      ></path>
      <path
        d="M232.29,403a10.09,10.09,0,0,1,4,2.38c-.16,3.55-5.33,7-8.54,8.93-3.47,2.12-5.21,4.62-10.22,6.94s-7.79,4.55-9.63,8-5.76,10.52-15.12,12.64-21.75,7.56-30,6.1-13.25-.64-18.85,0-9.64,4.31-17.16,5.37c-6.12.86-47.3,15.1-62.34,20.35,15-16.15,63.17-31.45,70.18-30.62,7.65.92,20.57-2.23,26.11-3.32s6.46-3.39,6.46-3.39c0,1.84-2,4.23-2,4.23a21.18,21.18,0,0,0,3.29,2.36c1.85,1.06,25.45-4.88,29.59-8.44s9.84-10.68,11.95-12.3,13.88-5.06,15.56-8.28-2.11-9.88-2.9-10.93S224.9,400.44,232.29,403Z"
        style="fill: #517dca; opacity: 0.4"
      ></path>
      <path
        d="M239,412.74v-1A6.82,6.82,0,0,1,239,412.74Z"
        style="fill: #517dca; opacity: 0.4"
      ></path>
      <path
        d="M33,398.08s63.14-4.39,74.1-6.56c0,0,8.31-8.17,11-9s7.32-1.54,10-3.13,13.49-13,15-14.78,7.71,0,9.06,0,3.08,2,3.08,2,7.33-5.78,9.25-6.16,8.48,1.25,10.22,2.69,3.85,5.37,3.85,5.37,13.94.95,17.76.88,8.87,3.78,9.43,5.12,6,15.81,7.12,17.25,2.71,6.84,2.71,6.84,6.54-2.31,12.9-.77,6.93,2.7,7.7,6.56-5,7.7-8.48,9.82-5.2,4.62-10.21,6.94-7.79,4.55-9.64,8-5.75,10.52-15.11,12.64-21.75,7.56-30,6.1-13.25-.64-18.85,0-9.64,4.31-17.16,5.37-67.84,22.26-67.84,22.26"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M178.57,368.56a14.14,14.14,0,0,0-7.32,2.29"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M186.91,370.85c2.56,1.19,4.51,8.57,4.51,8.57"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M194,388.44s1.4,7,2.82,9.09a8.06,8.06,0,0,1,1.23,4.62"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M194,400.77a56.91,56.91,0,0,1,7.26,3.68c1.8,1.34,7.61-3.29,14.34-5.8"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M194.56,391a10.36,10.36,0,0,1,4.25-1.69c2.89-.58,5.31-4.92,6-7.71"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M203.23,404.48a15,15,0,0,1-2-5,4.44,4.44,0,0,1-1.57-.87c-1.06-.77-4.65-5.87-4.65-5.87"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M201.25,399.52s5,.48,8-1.93"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M155.25,366.66a26.5,26.5,0,0,1-5.78,3"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M143.74,364.18c3.23-.3,5.73,2.1,6.12,2.48"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M164.5,360.5s5.59,1.54,6.75,3.08a5.65,5.65,0,0,1,1.15,3.08"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M107.07,391.52s5.1,0,8.14-1.92"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M143.89,447.92s11-3.4,15.41-4.17a10.29,10.29,0,0,0,6.55-4.05"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M222.13,406.36s2.83,3.71,1.92,7.52"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M203.23,410.12a14.56,14.56,0,0,1,6,9"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M155.25,372S144.86,383.19,139,386.3"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M172.4,375.14s-10.79,11.37-15.22,13.3"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
    </g>
    <g id="freepik--right-hand--inject-2">
      <path
        d="M263.74,365.33c.16,3.55,5.33,7,8.54,8.93,3.47,2.12,5.21,4.62,10.22,6.94s7.79,4.55,9.63,8,5.76,10.52,15.12,12.64,21.75,7.56,30,6.1,13.25-.64,18.85,0,9.64,4.31,17.16,5.37c6.12.86,47.3,15.1,62.34,20.35l5.49,1.91L467,358.08s-6.5-.29-15.41-.77c-16.59-.87-51.56-4.38-58.69-5.79,0,0-8.3-8.17-11-9s-7.32-1.54-10-3.13a51.45,51.45,0,0,1-6.33-5.68c-3.75-3.72-7.8-8-8.7-9.1a1.83,1.83,0,0,0-.56-.42c-2.11-1.08-7.28.42-8.5.42a4.55,4.55,0,0,0-2.54,1.44c-.34.33-.55.58-.55.58s-6-4.72-8.56-5.91h0a3.46,3.46,0,0,0-.67-.24c-1.92-.39-8.48,1.25-10.21,2.69s-3.85,5.37-3.85,5.37-13.95.95-17.77.88h-.15c-2.23,0-4.85,1.32-6.74,2.67a8.19,8.19,0,0,0-2.53,2.45c-.57,1.35-6,15.81-7.12,17.25s-2.71,6.84-2.71,6.84l-.58-.18a25.22,25.22,0,0,0-12.32-.59c-6.36,1.55-6.94,2.7-7.71,6.56A3.34,3.34,0,0,0,263.74,365.33Zm33.07-.91a15.12,15.12,0,0,0,1.94-4.9,4.54,4.54,0,0,0,1.57-.87c1.06-.77,4.65-5.87,4.65-5.87a46.58,46.58,0,0,1-1.78,4.75c-.8,1.61-1.81,3.57-1.24,5.12a22,22,0,0,0-3.54,1.77c-.59.56-1.64.06-1.64.06Z"
        style="fill: #fff"
      ></path>
      <path
        d="M451.61,357.31c-16.59-.87-51.56-4.38-58.69-5.79,0,0-8.3-8.17-11-9s-7.32-1.54-10-3.13a51.45,51.45,0,0,1-6.33-5.68c3.07,4.62,7,10.18,9.38,12.33,4.4,4,5.89,0,5.89,0C385.89,354.87,439.15,357.36,451.61,357.31Z"
        style="fill: #517dca; opacity: 0.4"
      ></path>
      <path
        d="M356.33,324.22c-2.11-1.08-7.28.42-8.5.42a4.55,4.55,0,0,0-2.54,1.44c1.41,1.19,2.35,2.1,2.35,2.1s2.5-1.52,5.56-3.53A4,4,0,0,1,356.33,324.22Z"
        style="fill: #517dca; opacity: 0.4"
      ></path>
      <path
        d="M336.16,320.74a3.46,3.46,0,0,0-.67-.24c-1.92-.39-8.48,1.25-10.21,2.69s-3.85,5.37-3.85,5.37-13.95.95-17.77.88h-.15c-1.43,5.35,5.8,7.63,5.8,7.63s1.76-7.88,8.78-8.29,9.36,1.32,9.36,1.32c.13-6.47,2.47-7.44,6.7-9.19A3.8,3.8,0,0,1,336.16,320.74Z"
        style="fill: #517dca; opacity: 0.4"
      ></path>
      <path
        d="M305,352.78a46.58,46.58,0,0,1-1.78,4.75c-.8,1.61-1.81,3.57-1.24,5.12a22,22,0,0,0-3.54,1.77c2.22-1.16,6.56-3.19,6.56-3.19-1.38-3.16,2.17-14.93,2.17-14.93a25.15,25.15,0,0,1-10.37-4.7c-2.66-2.2-1.42-6.44,0-9.49a8.19,8.19,0,0,0-2.53,2.45c-.57,1.35-6,15.81-7.12,17.25s-2.71,6.84-2.71,6.84l-.58-.18c4,2.17,9.95,5.31,13,5.95a15.12,15.12,0,0,0,1.94-4.9,4.54,4.54,0,0,0,1.57-.87C301.38,357.88,305,352.78,305,352.78Z"
        style="fill: #517dca; opacity: 0.4"
      ></path>
      <path
        d="M267.71,363a10.09,10.09,0,0,0-4,2.38c.16,3.55,5.33,7,8.54,8.93,3.47,2.12,5.21,4.62,10.22,6.94s7.79,4.55,9.63,8,5.76,10.52,15.12,12.64,21.75,7.56,30,6.1,13.25-.64,18.85,0,9.64,4.31,17.16,5.37c6.12.86,47.3,15.1,62.34,20.35-15-16.15-63.17-31.45-70.18-30.62-7.65.92-20.57-2.23-26.11-3.32s-6.46-3.39-6.46-3.39c0,1.84,2,4.23,2,4.23a21.18,21.18,0,0,1-3.29,2.36C329.69,404,306.09,398,302,394.46s-9.84-10.68-11.95-12.3-13.88-5.06-15.56-8.28,2.11-9.88,2.9-10.93S275.1,360.44,267.71,363Z"
        style="fill: #517dca; opacity: 0.4"
      ></path>
      <path
        d="M261,372.74v-1A6.82,6.82,0,0,0,261,372.74Z"
        style="fill: #517dca; opacity: 0.4"
      ></path>
      <path
        d="M467,358.08s-63.14-4.39-74.1-6.56c0,0-8.31-8.17-11-9s-7.32-1.54-10-3.13-13.49-13-15-14.78-7.71,0-9.06,0-3.08,2-3.08,2-7.33-5.78-9.25-6.16-8.48,1.25-10.22,2.69-3.85,5.37-3.85,5.37-13.94.95-17.76.88-8.87,3.78-9.43,5.12-6,15.81-7.12,17.25-2.71,6.84-2.71,6.84-6.54-2.31-12.9-.77-6.93,2.7-7.7,6.56,5,7.7,8.48,9.82,5.2,4.62,10.21,6.94,7.79,4.55,9.64,8,5.75,10.52,15.11,12.64,21.75,7.56,30,6.1,13.25-.64,18.85,0,9.64,4.31,17.16,5.37,67.84,22.26,67.84,22.26"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M321.43,328.56a14.14,14.14,0,0,1,7.32,2.29"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M313.09,330.85c-2.56,1.19-4.51,8.57-4.51,8.57"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M306,348.44s-1.4,7-2.82,9.09a8.06,8.06,0,0,0-1.23,4.62"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M306,360.77a56.91,56.91,0,0,0-7.26,3.68c-1.8,1.34-7.61-3.29-14.34-5.8"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M305.44,351a10.36,10.36,0,0,0-4.25-1.69c-2.89-.58-5.31-4.92-6-7.71"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M296.77,364.48a15,15,0,0,0,2-5,4.44,4.44,0,0,0,1.57-.87c1.06-.77,4.65-5.87,4.65-5.87"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M298.75,359.52s-5,.48-8-1.93"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M344.75,326.66a26.5,26.5,0,0,0,5.78,3"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M356.26,324.18c-3.23-.3-5.73,2.1-6.12,2.48"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M335.5,320.5s-5.59,1.54-6.75,3.08a5.65,5.65,0,0,0-1.15,3.08"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M392.93,351.52s-5.1,0-8.14-1.92"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M356.11,407.92s-11-3.4-15.41-4.17a10.29,10.29,0,0,1-6.55-4.05"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M277.87,366.36s-2.83,3.71-1.92,7.52"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M296.77,370.12a14.56,14.56,0,0,0-6,9"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M344.75,332S355.14,343.19,361,346.3"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
      <path
        d="M327.6,335.14s10.79,11.37,15.22,13.3"
        style="
          fill: none;
          stroke: #263238;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
      ></path>
    </g>
  </svg>
</template>
<script>
export default {
  name: "PasswordChangedIlustration",
};
</script>
<style>
svg#freepik_stories-key:not(.animated) .animable {
  opacity: 0;
}
svg#freepik_stories-key.animated #freepik--background-complete--inject-10 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) fadeIn;
  animation-delay: 0s;
}
svg#freepik_stories-key.animated #freepik--Floor--inject-10 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) slideLeft;
  animation-delay: 0s;
}
svg#freepik_stories-key.animated #freepik--speech-bubble--inject-10 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) fadeIn;
  animation-delay: 0s;
}
svg#freepik_stories-key.animated #freepik--Character--inject-10 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) slideLeft;
  animation-delay: 0s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes slideLeft {
  0% {
    opacity: 0;
    transform: translateX(-30px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
</style>
