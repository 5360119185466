<template>
  <v-slide-x-transition>
    <Alert
      v-if="hasUsersWithoutGroup"
      type="warning"
      :prominent="false"
      :text="false"
      dense
      dark
      btn-width="105"
      :column-layout="true"
      :show-action="!hideAction"
      :action-text="$ml.get('show_users_without_group')"
      @action="redirectToFixUsersWithoutShifts"
    >
      {{ $ml.get("attention") }}:
      {{ $ml.get("activate_sso_warning") }}
      {{ totalUsersWithoutGroup }}
      {{
        totalUsersWithoutGroup === 1
          ? $ml.get("user").toLowerCase()
          : $ml.get("users").toLowerCase()
      }}
      {{ $ml.get("activate_sso_warning2") }}
      {{ $ml.get("activate_sso_warning3") }}
    </Alert>
  </v-slide-x-transition>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "UsersWithoutShift",

  props: {
    hideAction: { type: Boolean, default: false },
  },

  computed: {
    ...mapGetters(["totalUsersWithoutGroup"]),

    hasUsersWithoutGroup() {
      return this.totalUsersWithoutGroup > 0;
    },
  },

  methods: {
    redirectToFixUsersWithoutShifts() {
      this.$router.push({
        name: "Users",
        params: { applyNoGroupFilter: "noGroup" },
      });
    },
  },
};
</script>
