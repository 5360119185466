<template>
  <v-dialog
    v-model="showActivationScheduleMeeting"
    max-width="1000"
    @keydown.esc="setScheduleMeetingDialog(false)"
    @click:outside="setScheduleMeetingDialog(false)"
  >
    <v-card tile>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon v-text="'mdi-close'" />
        </v-btn>
      </v-card-actions>

      <v-row class="ma-0">
        <v-col v-if="$vuetify.breakpoint.mdAndUp" class="text-center">
          <v-img
            max-width="450"
            :src="image"
            alt="callcenter"
            class="mb-6"
          ></v-img>
          <a href="https://storyset.com/communication" class="caption mb-4">
            {{ $ml.get("illustration_by") }} Storyset
          </a>
        </v-col>
        <v-col class="d-flex justify-center align-center">
          <div>
            <div
              v-if="!$vuetify.breakpoint.mdAndUp"
              class="d-flex justify-center align-center"
            >
              <v-img
                max-width="250"
                :src="image"
                alt="callcenter"
                class="mb-6"
              ></v-img>
            </div>
            <v-card-subtitle
              class="text-h5 text--bold primary--text font-weight-bold"
            >
              {{ $ml.get("control_activation_schedule_title") }}
            </v-card-subtitle>
            <v-card-text class="py-4 body-1">
              {{ $ml.get("schedule_explanation") }}
            </v-card-text>
            <v-card-text class="mt-5">
              <v-btn
                color="accent"
                class="white--text text-none text-body-1 font-weight-medium"
                width="100%"
                large
                elevation="0"
                rounded
                @click="goToScheduleMeeting"
              >
                {{ $ml.get("schedule_meeting_button") }}
                <v-icon v-text="'mdi-arrow-right'" class="next-icon-1" right />
              </v-btn>

              <v-btn
                color="secondary lighten-1"
                class="white--text text-none text-body-1 font-weight-medium mt-5"
                width="100%"
                large
                outlined
                elevation="0"
                rounded
                @click="close"
              >
                {{ $ml.get("close") }}
              </v-btn>
            </v-card-text>

            <a
              v-if="!$vuetify.breakpoint.mdAndUp"
              href="https://storyset.com/communication"
              class="caption mb-4"
            >
              {{ $ml.get("illustration_by") }} Storyset
            </a>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import { getAnalytics, logEvent } from "firebase/analytics";
import { calendarSchedule } from "@/helpers/links";
import { formatDate, today } from "@/helpers/utils";

export default {
  name: "ActivationScheduleDialog",

  props: {
    activationProgress: { type: Number, default: 0 },
  },

  data() {
    return {
      image: require("@/assets/images/meeting.png"),
      nextPage: null,
    };
  },

  computed: {
    ...mapGetters([
      "showActivationScheduleMeeting",
      "currentUser",
      "companyInSuite",
    ]),

    userFirstName() {
      return this.currentUser.first_name;
    },
  },

  methods: {
    ...mapMutations(["setScheduleMeetingDialog"]),

    goToScheduleMeeting() {
      const analytics = getAnalytics();
      const clicked_where = "opened from activation page";

      logEvent(analytics, "schedule_meeting", {
        main_domain: this.companyInSuite.main_domain,
        clicked_where,
        date: formatDate(today),
      });

      window.open(calendarSchedule, "_blank");
    },

    close() {
      this.setScheduleMeetingDialog(false);
      this.$emit("next");
    },
  },
};
</script>
