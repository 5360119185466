<template>
  <v-card class="py-3 rounded-lg">
    <v-row justify="center" align="center" class="mb-3">
      <v-col cols="12" sm="12" md="6">
        <GetStartedIlustration />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="6"
        :class="$vuetify.breakpoint.lg ? 'pt-10' : ''"
      >
        <v-card
          flat
          class="d-flex flex-column justify-center align-center mr-5"
        >
          <v-card-title
            class="primary--text text-h5 text-sm-h4 font-weight-bold text-center break-word text-wrap normal-line-height"
            style="max-width: 450px"
          >
            {{ $ml.get("getStartedTitle") }}
          </v-card-title>

          <v-subheader
            class="text-none text-body-1 text-sm-h6 font-weight-medium text-center"
          >
            {{ $ml.get("beforeStartActivationProccess") }}
          </v-subheader>

          <v-list class="px-0">
            <v-list-item
              v-for="({ title }, index) in getStartedList"
              :key="index"
            >
              <v-list-item-action class="mr-4">
                <v-icon v-text="'mdi-check-circle'" size="26" color="success" />
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title
                  class="text-wrap break-word normal-line-height text-body-1 secondary--text"
                >
                  {{ $ml.get(title) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>

      <v-col cols="12" class="d-flex justify-center">
        <v-btn
          height="58"
          color="accent"
          class="mx-auto text-none text-h6 font-weight-bold px-8"
          @click="$emit('started')"
        >
          {{ $ml.get("letsStart") }}

          <v-icon right v-text="'mdi-arrow-right'" class="ml-4" size="22" />
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import GetStartedIlustration from "@/components/activation/init/GetStartedIlustration";

export default {
  name: "GetStarted",

  components: { GetStartedIlustration },

  data() {
    return {};
  },

  computed: {
    getStartedList() {
      return [
        {
          title: "getStartedSteps",
        },
        {
          title: "getStartedActivationProccess",
        },
        {
          title: "getStartedProgress",
        },
        {
          title: "getStartedLogoutUsersTip",
        },
      ];
    },
  },
};
</script>
