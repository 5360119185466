<template>
  <div>
    <v-row justify="center" align="center" class="mb-8 pt-7">
      <v-col :class="$vuetify.breakpoint.mdAndUp ? 'px-6' : ''">
        <v-card
          flat
          class="d-flex flex-column justify-center align-center mt-8"
          style="width: 100%"
        >
          <v-card-title
            class="primary--text text-h6 text-md-h4 font-weight-bold text-center break-word text-wrap normal-line-height"
          >
            {{ $ml.get("whatDoYouWantToDoWithUsersFirstAccess") }}
          </v-card-title>

          <v-item-group
            v-model="selectedPasswordOption"
            mandatory
            class="mt-4"
            :disabled="loading"
          >
            <v-row class="pa-0 ma-0" justify="center" align="stretch">
              <v-col
                v-for="(logoutOption, index) in passwordOptions"
                :key="index"
                cols="12"
                md="4"
              >
                <v-item v-slot="{ active, toggle }">
                  <v-card
                    :class="`d-flex flex-column align-center justify-center py-4 fill-height mx-auto ${
                      active ? 'amber lighten-5' : ''
                    }`"
                    outlined
                    hover
                    height="100%"
                    :disabled="loading"
                    @click="toggle"
                  >
                    <v-icon
                      v-text="logoutOption.icon"
                      color="accent"
                      size="65"
                    />

                    <v-card-title
                      :class="`text-wrap break-word text-center text-body-1 text-md-h5 font-weight-medium ${
                        active
                          ? 'accent--text'
                          : 'secondary--text text--lighten-1'
                      }`"
                      style="max-width: 70%"
                    >
                      {{ $ml.get(logoutOption.title) }}
                    </v-card-title>

                    <v-card-text
                      class="mt-1 text-wrap break-word normal-line-height text-center text-body-2 text-md-body-1"
                    >
                      {{ $ml.get(logoutOption.subtitle) }}
                    </v-card-text>
                  </v-card>
                </v-item>
              </v-col>
            </v-row>
          </v-item-group>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "SelectPasswordOptions",

  props: {
    loading: { type: Boolean, default: false },
    passwordOption: {
      type: String,
      default: () => "sendLinkToChangePasswords",
    },
  },

  computed: {
    ...mapGetters(["isMicrosoft"]),

    passwordOptions() {
      const options = [
        {
          title: "generate_link_to_change_passwords",
          subtitle: "tip_about_send_link_to_chenge_password",
          icon: "mdi-form-textbox-password",
          value: "sendLinkToChangePasswords",
        },
        {
          title: "generateRandomPasswords",
          subtitle: "tipAboutExclusivePassword",
          icon: "mdi-lock-question",
          value: "createRandomPasswords",
        },
        {
          title: "defineTemporaryPassword",
          subtitle: "defineTemporaryPasswordTip",
          icon: "mdi-lock-clock",
          value: "configureTemporaryPassword",
        },
      ];

      return options;
    },

    selectedPasswordOption: {
      get() {
        return this.passwordOptions.findIndex(
          (option) => option.value === this.passwordOption
        );
      },
      set(value) {
        this.$emit(
          "password-option:update",
          this.passwordOptions[value || 0].value
        );
      },
    },
  },
};
</script>
