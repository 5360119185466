<template>
  <v-card class="grid text-center pa-4 mb-3" outlined width="80%" @click="copy">
    <v-card-actions class="align-center">
      <v-spacer />

      <span
        class="text-h6 text-sm-h5 text-md-64 font-weight-regular secondary--text text--lighten-2"
        id="temporary-password"
      >
        {{ defaultPassword }}
      </span>

      <v-spacer />

      <v-icon v-text="'mdi-content-copy'" />
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "PasswordCopy",

  computed: {
    ...mapGetters(["defaultPassword"]),
  },

  methods: {
    ...mapActions(["setSnackbarDefault"]),

    copy() {
      var elm = document.getElementById("temporary-password");

      var selection = window.getSelection();
      var range2 = document.createRange();
      range2.selectNodeContents(elm);
      selection.removeAllRanges();
      selection.addRange(range2);
      document.execCommand("Copy");

      this.setSnackbarDefault({
        message: "password_successful_copy",
        color: "success",
        show: true,
      });
    },
  },
};
</script>
