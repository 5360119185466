<template>
  <div v-if="currentActivationStep > 1">
    <v-stepper
      v-if="$vuetify.breakpoint.smAndUp"
      :alt-label="$vuetify.breakpoint.sm"
      readonly
      class="transparent elevation-0"
      :value="currentStep"
    >
      <v-stepper-header>
        <v-stepper-step step="1" :complete="currentStep > 1" color="accent">
          <div
            :class="`d-flex text-center text-wrap normal-line-height ${
              currentStep === 1 ? 'font-weight-medium white--text' : ''
            } ${currentStep > 1 ? 'font-weight-medium white--text' : ''}`"
          >
            {{ $ml.get("activationStepOneTitle") }}
          </div>
        </v-stepper-step>

        <v-stepper-step step="2" :complete="currentStep > 2" color="accent">
          <div
            :class="`d-flex text-center text-wrap normal-line-height ${
              currentStep === 2 ? 'font-weight-bold white--text' : ''
            } ${currentStep > 2 ? 'font-weight-medium white--text' : ''}`"
          >
            {{ $ml.get("activationStepTwoTitle") }}
          </div>
        </v-stepper-step>

        <v-stepper-step step="3" :complete="currentStep > 3" color="accent">
          <div
            :class="`d-flex text-center text-wrap normal-line-height ${
              currentStep === 3 ? 'font-weight-bold white--text' : ''
            } ${currentStep > 3 ? 'font-weight-medium white--text' : ''}`"
          >
            {{ $ml.get("activationStepThreeTitle") }}
          </div>
        </v-stepper-step>
      </v-stepper-header>
    </v-stepper>
    <v-progress-linear
      background-color="grey lighten-2"
      color="accent"
      :value="progress"
      height="7"
      :class="`rounded-t-xl`"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "ActivationProgressBar",

  data() {
    return {
      stepsTitle: {
        2: "activationStepOneTitle",
        3: "activationStepTwoTitle",
        4: "activationStepThreeTitle",
      },
    };
  },

  computed: {
    ...mapGetters(["currentActivationStep", "activationStepsNumber"]),

    progress() {
      return parseInt((this.currentStep * 100) / this.activationStepsNumber);
    },

    currentStep() {
      return parseInt(this.currentActivationStep) - 1;
    },
  },
};
</script>
