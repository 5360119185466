<template>
  <svg
    class="animated"
    id="freepik_stories-secure-data"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
  >
    <g
      id="freepik--Shield--inject-2"
      class="animable"
      style="transform-origin: 197.64px 218.108px"
    >
      <path
        d="M104.88,137.14A131.47,131.47,0,0,0,77.78,205L59,202.26A150.22,150.22,0,0,1,90.3,125Z"
        style="
          fill: none;
          stroke: gray;
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 81.94px 165px;
        "
        id="el2b1uejfett"
        class="animable"
      ></path>
      <path
        d="M269.19,356.69A151.1,151.1,0,0,1,109,331.1l12.59-14.2a132.18,132.18,0,0,0,140.06,22.36Z"
        style="
          fill: none;
          stroke: gray;
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 189.095px 343.003px;
        "
        id="elut89w0ta34e"
        class="animable"
      ></path>
      <path
        d="M261.64,339.26A132.18,132.18,0,0,1,121.58,316.9L109,331.1A151.06,151.06,0,0,1,65.8,265.7l18.25-5.33,7.69-2.24,13.46-3.93,90.87-26.54h0l6.2-1.81-66.1,74.57a109.44,109.44,0,0,0,28.51,18.33l41.54-94.05,2.56-.75-1.51-1.63-1-1-.62-.67-1.81-1.94-.53-.57-4-4.33-68.12-73.31-9.53-10.26-5.45-5.87L103.3,110.42A150.52,150.52,0,0,1,209.14,67.11v151L197.32,108.74a109.25,109.25,0,0,0-53.1,20.57l64.92,88.8L221,327.48a109,109,0,0,0,31.93-8.42l5.56,12.85Z"
        style="
          fill: none;
          stroke: gray;
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 163.72px 208.61px;
        "
        id="elc5a63ztx4tl"
        class="animable"
      ></path>
      <path
        d="M111,142.28a123.28,123.28,0,0,0-25.32,63.85L77.78,205a131.47,131.47,0,0,1,27.1-67.84Z"
        style="
          fill: none;
          stroke: gray;
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 94.39px 171.645px;
        "
        id="el3q8zr754a9b"
        class="animable"
      ></path>
      <polyline
        points="202.28 225.85 204.51 223.33 206.02 221.63 206.32 221.29 209.14 218.11 203.36 218.11 201.8 218.11 168.45 218.11 99.14 218.11"
        style="
          fill: none;
          stroke: gray;
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 154.14px 221.98px;
        "
        id="elx81aekvyjm"
        class="animable"
      ></polyline>
      <polyline
        points="85.14 218.11 77.14 218.11 47.14 218.11"
        style="
          fill: none;
          stroke: gray;
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 66.14px 218.11px;
        "
        id="ele5n09nrknro"
        class="animable"
      ></polyline>
      <line
        x1="108.99"
        y1="331.1"
        x2="92.14"
        y2="350.11"
        style="
          fill: none;
          stroke: gray;
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 100.565px 340.605px;
        "
        id="el71moa82o54o"
        class="animable"
      ></line>
      <polyline
        points="121.58 316.9 126.89 310.91 136.18 300.42"
        style="
          fill: none;
          stroke: gray;
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 128.88px 308.66px;
        "
        id="elk4znpj31ahs"
        class="animable"
      ></polyline>
      <path
        d="M341.14,218.11a132,132,0,0,1-79.5,121.15l-3.19-7.35a124,124,0,0,0,27.89-210.83l5-6.27A131.79,131.79,0,0,1,341.14,218.11Z"
        style="
          fill: none;
          stroke: gray;
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 299.795px 227.035px;
        "
        id="elxu8llf2m0w"
        class="animable"
      ></path>
      <path
        d="M286.34,121.08l-8.72,11a109.25,109.25,0,0,0-24-14.56l5.66-12.82A123.88,123.88,0,0,1,286.34,121.08Z"
        style="
          fill: none;
          stroke: gray;
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 269.98px 118.39px;
        "
        id="eld7bgm2team"
        class="animable"
      ></path>
      <polyline
        points="291.33 114.81 318.14 81.11 348.14 81.11"
        style="
          fill: none;
          stroke: gray;
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 319.735px 97.96px;
        "
        id="el2garm6qgk64"
        class="animable"
      ></polyline>
      <line
        x1="277.62"
        y1="132.03"
        x2="209.14"
        y2="218.11"
        style="
          fill: none;
          stroke: gray;
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 243.38px 175.07px;
        "
        id="elkmqb1l8oxt"
        class="animable"
      ></line>
      <path
        d="M199.54,129.29l-2.22-20.55a109.25,109.25,0,0,0-53.1,20.57L156.42,146A88.86,88.86,0,0,1,199.54,129.29Z"
        style="
          fill: none;
          stroke: gray;
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 171.88px 127.37px;
        "
        id="elu8o204irmi"
        class="animable"
      ></path>
      <path
        d="M219.09,310.19,221,327.48a109,109,0,0,0,31.93-8.42l-7-16.2A89.44,89.44,0,0,1,219.09,310.19Z"
        style="
          fill: none;
          stroke: gray;
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 236.01px 315.17px;
        "
        id="elhhrslmsd2r7"
        class="animable"
      ></path>
      <path
        d="M221,327.48a110.42,110.42,0,0,1-56.27-8.73l41.54-94.05-3.95,1.15-66.1,74.57a109.81,109.81,0,0,1-37-82.31c0-3.36.15-6.69.46-10a109.49,109.49,0,0,1,22.18-56.88,110.13,110.13,0,0,1,9.44-10.79,108.86,108.86,0,0,1,13-11.16l64.92,88.8Z"
        style="
          fill: none;
          stroke: gray;
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 160.11px 228.695px;
        "
        id="elhbyd0iyk9w"
        class="animable"
      ></path>
      <line
        x1="262.14"
        y1="98.11"
        x2="259.25"
        y2="104.65"
        style="
          fill: none;
          stroke: gray;
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 260.695px 101.38px;
        "
        id="eldhxtzbx7yub"
        class="animable"
      ></line>
      <line
        x1="253.59"
        y1="117.47"
        x2="209.14"
        y2="218.11"
        style="
          fill: none;
          stroke: gray;
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 231.365px 167.79px;
        "
        id="eltd0uo7k2iwk"
        class="animable"
      ></line>
      <polyline
        points="195.04 87.69 195.81 94.82 197.32 108.74"
        style="
          fill: none;
          stroke: gray;
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 196.18px 98.215px;
        "
        id="elgc9zapqzmit"
        class="animable"
      ></polyline>
      <line
        x1="209.14"
        y1="218.11"
        x2="89.14"
        y2="165.11"
        style="
          fill: none;
          stroke: gray;
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 149.14px 191.61px;
        "
        id="elo8xb6pkxg2i"
        class="animable"
      ></line>
      <path
        d="M99.77,229.93l-13.92,1.51a124.94,124.94,0,0,1-.71-13.33h14A109.9,109.9,0,0,0,99.77,229.93Z"
        style="
          fill: none;
          stroke: gray;
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 92.455px 224.775px;
        "
        id="el2pf6nvdsv0e"
        class="animable"
      ></path>
      <line
        x1="85.85"
        y1="231.44"
        x2="78.72"
        y2="232.21"
        style="
          fill: none;
          stroke: gray;
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 82.285px 231.825px;
        "
        id="elyx453xovuzo"
        class="animable"
      ></line>
      <line
        x1="209.14"
        y1="218.11"
        x2="103.24"
        y2="295.53"
        style="
          fill: none;
          stroke: gray;
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 156.19px 256.82px;
        "
        id="elmfti3qynebf"
        class="animable"
      ></line>
      <polyline
        points="156.14 338.11 159.03 331.57 164.69 318.75"
        style="
          fill: none;
          stroke: gray;
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 160.415px 328.43px;
        "
        id="elbqahil5e3qv"
        class="animable"
      ></polyline>
      <polyline
        points="206.23 224.7 206.69 223.65 207.16 222.59 207.28 222.32 209.14 218.11"
        style="
          fill: none;
          stroke: gray;
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 207.685px 221.405px;
        "
        id="ely9ch9mhp9ob"
        class="animable"
      ></polyline>
      <polyline
        points="224.26 366.23 222.47 341.4 220.96 327.48"
        style="
          fill: none;
          stroke: gray;
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 222.61px 346.855px;
        "
        id="elwv7osl7s6d"
        class="animable"
      ></polyline>
      <polyline
        points="286.56 324.01 282.32 318.22 274.06 306.91"
        style="
          fill: none;
          stroke: gray;
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 280.31px 315.46px;
        "
        id="elezxhioukenc"
        class="animable"
      ></polyline>
      <line
        x1="209.14"
        y1="218.11"
        x2="329.14"
        y2="271.11"
        style="
          fill: none;
          stroke: gray;
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 269.14px 244.61px;
        "
        id="elgv6fx7085uv"
        class="animable"
      ></line>
      <line
        x1="209.14"
        y1="218.11"
        x2="339.56"
        y2="204.01"
        style="
          fill: none;
          stroke: gray;
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 274.35px 211.06px;
        "
        id="el9g0z3mvfj4v"
        class="animable"
      ></line>
      <line
        x1="315.04"
        y1="140.69"
        x2="309.25"
        y2="144.93"
        style="
          fill: none;
          stroke: gray;
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 312.145px 142.81px;
        "
        id="eljjoj5145ek"
        class="animable"
      ></line>
      <line
        x1="297.94"
        y1="153.19"
        x2="209.14"
        y2="218.11"
        style="
          fill: none;
          stroke: gray;
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 253.54px 185.65px;
        "
        id="elx0hjvfyeg7"
        class="animable"
      ></line>
      <path
        d="M209.14,135.09c-12.28,11.44-43.71,23-80.36,47.51,0,0,2.29,82.85,80.36,128.14Z"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 168.96px 222.915px;
        "
        id="elki7g1orgdb"
        class="animable"
      ></path>
      <path
        d="M209.14,135.09c12.28,11.44,43.71,23,80.36,47.51,0,0-2.29,82.85-80.36,128.14Z"
        style="
          fill: rgb(38, 50, 56);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 249.32px 222.915px;
        "
        id="elnhk0qtz3ips"
        class="animable"
      ></path>
      <path
        d="M209.14,142.14c-14.82,10.29-40.51,20.09-74.47,42.81,0,0,2.13,76.77,74.47,118.73C281.48,261.72,283.6,185,283.6,185,249.65,162.23,224.08,152.43,209.14,142.14Z"
        style="
          fill: rgb(38, 50, 56);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 209.135px 222.91px;
        "
        id="elwetgqg79w5"
        class="animable"
      ></path>
      <path
        d="M283.6,185c-34-22.72-59.52-32.52-74.46-42.81V303.68C281.48,261.72,283.6,185,283.6,185Z"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 246.37px 222.935px;
        "
        id="elj7pi1piqdpp"
        class="animable"
      ></path>
      <path
        d="M237.35,219.54h-55.5a4.56,4.56,0,0,1-4.61-4.51V189.44c0-17.47,14.51-31.69,32.36-31.69S242,172,242,189.44V215A4.57,4.57,0,0,1,237.35,219.54Zm-50.89-9h46.28V189.44a23.15,23.15,0,0,0-46.28,0Z"
        style="
          fill: rgb(255, 255, 255);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 209.62px 188.645px;
        "
        id="elbn952ym5ygb"
        class="animable"
      ></path>
      <rect
        x="173.81"
        y="207.21"
        width="70.66"
        height="58.45"
        style="
          fill: rgb(255, 255, 255);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 209.14px 236.435px;
        "
        id="eltbp7yf3khkj"
        class="animable"
      ></rect>
      <path
        d="M216.49,231.48a7.7,7.7,0,0,0-15.39,0,7.51,7.51,0,0,0,4,6.63l-1.29,13.28h9.89l-1.29-13.28A7.51,7.51,0,0,0,216.49,231.48Z"
        style="
          fill: rgb(38, 50, 56);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 208.795px 237.724px;
        "
        id="elf29g0t7f6lc"
        class="animable"
      ></path>
    </g>
    <g
      id="freepik--Folders--inject-2"
      class="animable animator-hidden"
      style="transform-origin: 310.79px 228.16px"
    >
      <polygon
        points="383.72 93.45 403.79 93.45 403.79 81.11 392.14 81.11 390.96 78.1 383.72 78.1 383.72 93.45"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 393.755px 85.775px;
        "
        id="elhnlsmxwehws"
        class="animable"
      ></polygon>
      <polygon
        points="383.72 113.39 403.79 113.39 403.79 101.06 392.14 101.06 390.96 98.04 383.72 98.04 383.72 113.39"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 393.755px 105.715px;
        "
        id="eljx4sdhx8tcc"
        class="animable"
      ></polygon>
      <polygon
        points="383.72 133.34 403.79 133.34 403.79 121 392.14 121 390.96 117.98 383.72 117.98 383.72 133.34"
        style="
          fill: rgb(38, 50, 56);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 393.755px 125.66px;
        "
        id="elhbzmt933gim"
        class="animable"
      ></polygon>
      <polygon
        points="383.72 258.45 403.79 258.45 403.79 246.11 392.14 246.11 390.96 243.1 383.72 243.1 383.72 258.45"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 393.755px 250.775px;
        "
        id="ellny4yh4iok"
        class="animable"
      ></polygon>
      <polygon
        points="383.72 278.39 403.79 278.39 403.79 266.06 392.14 266.06 390.96 263.04 383.72 263.04 383.72 278.39"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 393.755px 270.715px;
        "
        id="elvzvy5cw1wyo"
        class="animable"
      ></polygon>
      <polygon
        points="383.72 298.34 403.79 298.34 403.79 286 392.14 286 390.96 282.99 383.72 282.99 383.72 298.34"
        style="
          fill: rgb(38, 50, 56);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 393.755px 290.665px;
        "
        id="elm1vp0llrfni"
        class="animable"
      ></polygon>
      <polygon
        points="383.72 319.45 403.79 319.45 403.79 307.11 392.14 307.11 390.96 304.1 383.72 304.1 383.72 319.45"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 393.755px 311.775px;
        "
        id="elc7bae0z1tdt"
        class="animable"
      ></polygon>
      <polygon
        points="383.72 339.39 403.79 339.39 403.79 327.06 392.14 327.06 390.96 324.04 383.72 324.04 383.72 339.39"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 393.755px 331.715px;
        "
        id="el69y9gkkwh96"
        class="animable"
      ></polygon>
      <polygon
        points="383.72 359.34 403.79 359.34 403.79 347 392.14 347 390.96 343.99 383.72 343.99 383.72 359.34"
        style="
          fill: rgb(38, 50, 56);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 393.755px 351.665px;
        "
        id="el8bj8ae1urik"
        class="animable"
      ></polygon>
      <path
        d="M353.89,192.52a41.3,41.3,0,0,1,0-6"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 353.835px 189.52px;
        "
        id="elhuhltrl1i0u"
        class="animable"
      ></path>
      <path
        d="M360.4,192a35.75,35.75,0,0,1,0-5.07"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 360.355px 189.465px;
        "
        id="elr1qs1x9fm4"
        class="animable"
      ></path>
      <line
        x1="353.76"
        y1="189.53"
        x2="360.29"
        y2="189.48"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 357.025px 189.505px;
        "
        id="elr82xpn9vv1l"
        class="animable"
      ></line>
      <path
        d="M353.9,185.74a44.28,44.28,0,0,1,.92-5.93"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 354.36px 182.775px;
        "
        id="eldplgktyelp"
        class="animable"
      ></path>
      <path
        d="M360.41,186.28a38.38,38.38,0,0,1,.78-5"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 360.8px 183.78px;
        "
        id="elttcwul1tzeo"
        class="animable"
      ></path>
      <line
        x1="354.26"
        y1="182.76"
        x2="360.71"
        y2="183.76"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 357.485px 183.26px;
        "
        id="el9j9uyrfqrfw"
        class="animable"
      ></line>
      <path
        d="M355,179.05a39.69,39.69,0,0,1,1.87-5.71"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 355.935px 176.195px;
        "
        id="el3xkxv34ujhh"
        class="animable"
      ></path>
      <path
        d="M361.34,180.63a35.23,35.23,0,0,1,1.57-4.82"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 362.125px 178.22px;
        "
        id="elb2ajevv47e8"
        class="animable"
      ></path>
      <line
        x1="355.83"
        y1="176.16"
        x2="362.04"
        y2="178.19"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 358.935px 177.175px;
        "
        id="el4ix2u208114"
        class="animable"
      ></line>
      <path
        d="M357.17,172.62a42,42,0,0,1,2.76-5.33"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 358.55px 169.955px;
        "
        id="elrwstvlrzknj"
        class="animable"
      ></path>
      <path
        d="M363.17,175.2a36.15,36.15,0,0,1,2.33-4.5"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 364.335px 172.95px;
        "
        id="elrvlpf4n9ip"
        class="animable"
      ></path>
      <line
        x1="358.45"
        y1="169.91"
        x2="364.25"
        y2="172.91"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 361.35px 171.41px;
        "
        id="el9ov7loqlnw"
        class="animable"
      ></line>
      <path
        d="M360.34,166.63c.53-.85,1.1-1.67,1.71-2.47l1.88-2.33"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 362.135px 164.23px;
        "
        id="el0ctxuuba844s"
        class="animable"
      ></path>
      <path
        d="M365.85,170.14a25.09,25.09,0,0,1,1.45-2.09l1.59-2"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 367.37px 168.095px;
        "
        id="el9vcxfsnul6q"
        class="animable"
      ></path>
      <line
        x1="362.05"
        y1="164.16"
        x2="367.3"
        y2="168.05"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 364.675px 166.105px;
        "
        id="el0xi3tnroqa1"
        class="animable"
      ></line>
      <path
        d="M364.42,161.22c.68-.73,1.4-1.43,2.1-2.15.36-.35.69-.72,1.07-1l1.15-1"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 366.58px 159.145px;
        "
        id="el1pi6d2sokve"
        class="animable"
      ></path>
      <path
        d="M369.3,165.56l1.78-1.81c.3-.3.58-.62.9-.9l1-.8"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 371.14px 163.805px;
        "
        id="elwutmqrn2vmo"
        class="animable"
      ></path>
      <line
        x1="366.52"
        y1="159.07"
        x2="371.08"
        y2="163.75"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 368.8px 161.41px;
        "
        id="elt6zy3v1zfvp"
        class="animable"
      ></line>
      <path
        d="M369.35,156.56a20.34,20.34,0,0,1,2.39-1.82c.83-.55,1.65-1.13,2.5-1.66"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 371.795px 154.82px;
        "
        id="elpxbxsu7u2m"
        class="animable"
      ></path>
      <path
        d="M373.47,161.62a16.64,16.64,0,0,1,2-1.53c.71-.46,1.4-1,2.11-1.4"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 375.525px 160.155px;
        "
        id="elc01k5nhky1r"
        class="animable"
      ></path>
      <line
        x1="371.74"
        y1="154.74"
        x2="375.48"
        y2="160.09"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 373.61px 157.415px;
        "
        id="elx7l0mb4737d"
        class="animable"
      ></line>
      <path
        d="M374.93,152.71l2.64-1.43c.91-.41,1.84-.78,2.76-1.17"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 377.63px 151.41px;
        "
        id="elopp4iisy5n9"
        class="animable"
      ></path>
      <polyline
        points="378.18 158.37 380.41 157.17 382.74 156.18"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 380.46px 157.275px;
        "
        id="eldxjl5a2acid"
        class="animable"
      ></polyline>
      <line
        x1="377.57"
        y1="151.28"
        x2="380.41"
        y2="157.17"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 378.99px 154.225px;
        "
        id="eln2oh3ys1zya"
        class="animable"
      ></line>
      <path
        d="M381.06,149.8c.92-.37,1.9-.61,2.85-.93l1.43-.44,1.47-.31"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 383.935px 148.96px;
        "
        id="elhcpji3lmbb"
        class="animable"
      ></path>
      <path
        d="M383.35,155.92c.79-.32,1.61-.52,2.41-.78l1.22-.38,1.24-.26"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 385.785px 155.21px;
        "
        id="eln6qrnxq5ts"
        class="animable"
      ></path>
      <line
        x1="383.91"
        y1="148.87"
        x2="385.76"
        y2="155.14"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 384.835px 152.005px;
        "
        id="elkn240dc3ax"
        class="animable"
      ></line>
      <path
        d="M387.58,148a23,23,0,0,1,2.95-.49c1-.1,2-.24,3-.3"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 390.555px 147.605px;
        "
        id="elb6n61tjw69h"
        class="animable"
      ></path>
      <path
        d="M388.87,154.37a16.11,16.11,0,0,1,2.5-.42c.84-.08,1.68-.21,2.52-.26"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 391.38px 154.03px;
        "
        id="eltyvt8oux628"
        class="animable"
      ></path>
      <line
        x1="390.53"
        y1="147.47"
        x2="391.37"
        y2="153.95"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 390.95px 150.71px;
        "
        id="elccbf14p2o6i"
        class="animable"
      ></line>
      <polyline
        points="394.3 147.15 397.3 147.11 400.3 147.35"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 397.3px 147.23px;
        "
        id="el2f5tblcscwt"
        class="animable"
      ></polyline>
      <polyline
        points="394.55 153.68 397.09 153.64 399.62 153.85"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 397.085px 153.745px;
        "
        id="eldtiqva1h5vq"
        class="animable"
      ></polyline>
      <line
        x1="397.3"
        y1="147.11"
        x2="397.09"
        y2="153.64"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 397.195px 150.375px;
        "
        id="elns35c60qcx"
        class="animable"
      ></line>
      <path
        d="M401.08,147.41c1,.12,2,.34,3,.51.49.09,1,.17,1.47.27l1.45.41"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 404.04px 148.005px;
        "
        id="el1xzuo4gbo9i"
        class="animable"
      ></path>
      <path
        d="M400.28,153.9c.84.1,1.67.29,2.5.43.41.07.83.14,1.25.23l1.21.34"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 402.76px 154.4px;
        "
        id="elpptmwzj4ux"
        class="animable"
      ></path>
      <line
        x1="404.04"
        y1="147.92"
        x2="402.78"
        y2="154.33"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 403.41px 151.125px;
        "
        id="elv0536cca2es"
        class="animable"
      ></line>
      <path
        d="M407.71,148.82a43.15,43.15,0,0,1,5.64,2.05"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 410.53px 149.845px;
        "
        id="el0hwfujuvdk6"
        class="animable"
      ></path>
      <path
        d="M405.88,155.09a38.63,38.63,0,0,1,4.76,1.72"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 408.26px 155.95px;
        "
        id="eld7w4e0ojz84"
        class="animable"
      ></path>
      <line
        x1="410.56"
        y1="149.75"
        x2="408.29"
        y2="155.88"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 409.425px 152.815px;
        "
        id="elt9h1xccrktl"
        class="animable"
      ></line>
      <path
        d="M414.05,151.23l2.67,1.37c.87.49,1.69,1.07,2.53,1.61"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 416.65px 152.72px;
        "
        id="ele1bh0voix9v"
        class="animable"
      ></path>
      <path
        d="M411.23,157.12l2.26,1.16c.73.41,1.42.91,2.14,1.36"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 413.43px 158.38px;
        "
        id="eltbsfjzw3kif"
        class="animable"
      ></path>
      <line
        x1="416.72"
        y1="152.6"
        x2="413.49"
        y2="158.28"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 415.105px 155.44px;
        "
        id="ely1de0fi8mb"
        class="animable"
      ></line>
      <path
        d="M419.91,154.63a26.3,26.3,0,0,1,2.39,1.81,24.94,24.94,0,0,1,2.28,1.95"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 422.245px 156.51px;
        "
        id="el6dv30de0q84"
        class="animable"
      ></path>
      <path
        d="M416.18,160a20.21,20.21,0,0,1,2,1.53,19.19,19.19,0,0,1,1.94,1.65"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 418.15px 161.59px;
        "
        id="elrcue74vlals"
        class="animable"
      ></path>
      <line
        x1="422.3"
        y1="156.44"
        x2="418.2"
        y2="161.53"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 420.25px 158.985px;
        "
        id="elbp2w1w0p09j"
        class="animable"
      ></line>
      <path
        d="M425.14,158.94c.7.71,1.45,1.38,2.11,2.13l1.91,2.32"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 427.15px 161.165px;
        "
        id="elcqk5ukbn97k"
        class="animable"
      ></path>
      <path
        d="M420.61,163.64c.59.61,1.23,1.17,1.78,1.8l1.62,2"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 422.31px 165.54px;
        "
        id="elcqq7su60pf7"
        class="animable"
      ></path>
      <line
        x1="427.25"
        y1="161.07"
        x2="422.39"
        y2="165.44"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 424.82px 163.255px;
        "
        id="elag71yctl6r"
        class="animable"
      ></line>
      <path
        d="M429.66,164a46.21,46.21,0,0,1,3.21,5.08"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 431.265px 166.54px;
        "
        id="elktf15cqd0o8"
        class="animable"
      ></path>
      <path
        d="M424.43,167.9a40,40,0,0,1,2.7,4.29"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 425.78px 170.045px;
        "
        id="el19ldm3qg7zp"
        class="animable"
      ></path>
      <line
        x1="431.34"
        y1="166.48"
        x2="425.85"
        y2="170.01"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 428.595px 168.245px;
        "
        id="eluhicwz44zd"
        class="animable"
      ></line>
      <path
        d="M433.24,169.76l.7,1.32.59,1.39c.37.92.81,1.83,1.11,2.78"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 434.44px 172.505px;
        "
        id="elcjp15v444po"
        class="animable"
      ></path>
      <path
        d="M427.44,172.77l.6,1.12.49,1.17c.31.79.7,1.55,1,2.35"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 428.485px 175.09px;
        "
        id="el7k8ih5nbcf6"
        class="animable"
      ></path>
      <line
        x1="434.53"
        y1="172.47"
        x2="428.53"
        y2="175.06"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 431.53px 173.765px;
        "
        id="elo8g313v0iwk"
        class="animable"
      ></line>
      <path
        d="M435.88,176c.29,1,.61,1.9.86,2.87l.62,2.94"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 436.62px 178.905px;
        "
        id="elybqqlt6i52e"
        class="animable"
      ></path>
      <path
        d="M429.67,178.05c.24.81.52,1.61.74,2.43l.52,2.48"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 430.3px 180.505px;
        "
        id="elr267w7pbg7"
        class="animable"
      ></path>
      <line
        x1="436.74"
        y1="178.87"
        x2="430.41"
        y2="180.48"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 433.575px 179.675px;
        "
        id="elczlwzxynub6"
        class="animable"
      ></line>
      <path
        d="M437.52,182.58a47.72,47.72,0,0,1,.48,6"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 437.76px 185.58px;
        "
        id="elr3nnm8x9mn"
        class="animable"
      ></path>
      <path
        d="M431.07,183.61a42.77,42.77,0,0,1,.4,5"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 431.27px 186.11px;
        "
        id="el5tsl7c9qjhb"
        class="animable"
      ></path>
      <line
        x1="437.85"
        y1="185.56"
        x2="431.34"
        y2="186.13"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 434.595px 185.845px;
        "
        id="eldw2xhpc7gm6"
        class="animable"
      ></line>
      <path
        d="M438,189.34c0,.5,0,1,0,1.5l-.12,1.49c-.1,1-.11,2-.3,3"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 437.79px 192.335px;
        "
        id="el21vl8qsf6ysj"
        class="animable"
      ></path>
      <path
        d="M431.48,189.33c0,.42,0,.84,0,1.26l-.1,1.27a23.64,23.64,0,0,1-.26,2.52"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 431.3px 191.855px;
        "
        id="elfsvo63rsq1n"
        class="animable"
      ></path>
      <line
        x1="437.89"
        y1="192.33"
        x2="431.38"
        y2="191.86"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 434.635px 192.095px;
        "
        id="eldwfawo3hbbg"
        class="animable"
      ></line>
      <path
        d="M437.45,196.09c-.19,1-.33,2-.56,2.95l-.82,2.89"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 436.76px 199.01px;
        "
        id="elttrz4iu7vb"
        class="animable"
      ></path>
      <path
        d="M431,195c-.17.83-.28,1.67-.48,2.49l-.69,2.44"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 430.415px 197.465px;
        "
        id="el3ckgypxiriw"
        class="animable"
      ></path>
      <line
        x1="436.89"
        y1="199.04"
        x2="430.53"
        y2="197.52"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 433.71px 198.28px;
        "
        id="elebnjvjice9j"
        class="animable"
      ></line>
      <path
        d="M435.85,202.68a44.9,44.9,0,0,1-2.36,5.52"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 434.67px 205.44px;
        "
        id="elkxw1k9kbcj"
        class="animable"
      ></path>
      <path
        d="M429.66,200.6a39.7,39.7,0,0,1-2,4.65"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 428.66px 202.925px;
        "
        id="el0i4p3ag17imq"
        class="animable"
      ></path>
      <line
        x1="434.76"
        y1="205.48"
        x2="428.73"
        y2="202.96"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 431.745px 204.22px;
        "
        id="el6p4v0nta75c"
        class="animable"
      ></line>
      <path
        d="M433.13,208.9l-.69,1.33c-.25.43-.54.84-.8,1.27L430,214"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 431.565px 211.45px;
        "
        id="elbnkt7hyc8ia"
        class="animable"
      ></path>
      <path
        d="M427.36,205.84l-.58,1.13c-.21.37-.46.71-.68,1.07l-1.39,2.13"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 426.035px 208.005px;
        "
        id="elda03qk9st5b"
        class="animable"
      ></path>
      <line
        x1="431.64"
        y1="211.5"
        x2="426.1"
        y2="208.04"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 428.87px 209.77px;
        "
        id="elgy78u7j8rtc"
        class="animable"
      ></line>
      <path
        d="M429.51,214.62,427.62,217c-.63.78-1.38,1.45-2.07,2.17"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 427.53px 216.895px;
        "
        id="els5zukmic1l"
        class="animable"
      ></path>
      <path
        d="M424.29,210.68l-1.59,2c-.53.66-1.17,1.22-1.75,1.83"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 422.62px 212.595px;
        "
        id="elxixs38n8hnl"
        class="animable"
      ></path>
      <line
        x1="427.62"
        y1="216.95"
        x2="422.7"
        y2="212.66"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 425.16px 214.805px;
        "
        id="elrwykcr59idp"
        class="animable"
      ></line>
      <path
        d="M425,219.68a23.7,23.7,0,0,1-2.25,2,25,25,0,0,1-2.37,1.84"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 422.69px 221.6px;
        "
        id="el3n03j17mk6y"
        class="animable"
      ></path>
      <path
        d="M420.48,215a17.83,17.83,0,0,1-1.9,1.68,20.1,20.1,0,0,1-2,1.56"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 418.53px 216.62px;
        "
        id="elqdn3zws5pal"
        class="animable"
      ></path>
      <line
        x1="422.75"
        y1="221.67"
        x2="418.58"
        y2="216.64"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 420.665px 219.155px;
        "
        id="el5zhchdtztic"
        class="animable"
      ></line>
      <path
        d="M419.74,224c-.85.54-1.64,1.16-2.53,1.63L414.57,227"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 417.155px 225.5px;
        "
        id="elgczfppqkset"
        class="animable"
      ></path>
      <path
        d="M416,218.56c-.72.46-1.38,1-2.13,1.38l-2.23,1.21"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 413.82px 219.855px;
        "
        id="el3temaza0j1p"
        class="animable"
      ></path>
      <line
        x1="417.21"
        y1="225.58"
        x2="413.91"
        y2="219.94"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 415.56px 222.76px;
        "
        id="elf0vc7kgbsd7"
        class="animable"
      ></line>
      <path
        d="M413.87,227.35a43.7,43.7,0,0,1-5.61,2.13"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 411.065px 228.415px;
        "
        id="els2s3tuuvs5"
        class="animable"
      ></path>
      <path
        d="M411.09,221.44a38.79,38.79,0,0,1-4.74,1.8"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 408.72px 222.34px;
        "
        id="elficwbjk2fc4"
        class="animable"
      ></path>
      <line
        x1="411.1"
        y1="228.52"
        x2="408.75"
        y2="222.42"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 409.925px 225.47px;
        "
        id="el1xfjf2i79wa"
        class="animable"
      ></line>
      <path
        d="M407.51,229.71a22.08,22.08,0,0,1-2.91.72,26.77,26.77,0,0,1-3,.52"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 404.555px 230.33px;
        "
        id="el66c01pbquhs"
        class="animable"
      ></path>
      <path
        d="M405.71,223.43a17,17,0,0,1-2.46.6,19.42,19.42,0,0,1-2.49.45"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 403.235px 223.955px;
        "
        id="elbxz7su42u3b"
        class="animable"
      ></path>
      <line
        x1="404.6"
        y1="230.43"
        x2="403.25"
        y2="224.03"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 403.925px 227.23px;
        "
        id="elk811yw3quv"
        class="animable"
      ></line>
      <path
        d="M400.87,231c-1,.09-2,.28-3,.27l-3,0"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 397.87px 231.135px;
        "
        id="elwyjunk3lom"
        class="animable"
      ></path>
      <path
        d="M400.1,224.55a23.78,23.78,0,0,1-2.53.23l-2.54,0"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 397.565px 224.665px;
        "
        id="elu09yeq3um8"
        class="animable"
      ></path>
      <line
        x1="397.88"
        y1="231.31"
        x2="397.57"
        y2="224.78"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 397.725px 228.045px;
        "
        id="elyjlkrgl8tk"
        class="animable"
      ></line>
      <path
        d="M394.1,231.33a42.52,42.52,0,0,1-6-.71"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 391.1px 230.975px;
        "
        id="elurdivm34as"
        class="animable"
      ></path>
      <path
        d="M394.37,224.8a37.11,37.11,0,0,1-5-.6"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 391.87px 224.5px;
        "
        id="el83xgqmdopuv"
        class="animable"
      ></path>
      <line
        x1="391.11"
        y1="231.09"
        x2="391.84"
        y2="224.6"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 391.475px 227.845px;
        "
        id="eld3lrwna9p4a"
        class="animable"
      ></line>
      <path
        d="M387.37,230.48a24.24,24.24,0,0,1-2.91-.72,23.21,23.21,0,0,1-2.86-.91"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 384.485px 229.665px;
        "
        id="elgtu4l0robze"
        class="animable"
      ></path>
      <path
        d="M388.69,224.08a19.21,19.21,0,0,1-2.46-.61,20.41,20.41,0,0,1-2.41-.76"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 386.255px 223.395px;
        "
        id="eljxkx8md17ep"
        class="animable"
      ></path>
      <line
        x1="384.46"
        y1="229.76"
        x2="386.23"
        y2="223.47"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 385.345px 226.615px;
        "
        id="elvwlwru92nt"
        class="animable"
      ></line>
      <path
        d="M380.87,228.57c-.93-.39-1.89-.7-2.78-1.15L375.42,226"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 378.145px 227.285px;
        "
        id="el03bwyfklexa5"
        class="animable"
      ></path>
      <path
        d="M383.2,222.47c-.78-.33-1.59-.58-2.34-1l-2.25-1.16"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 380.905px 221.39px;
        "
        id="ela1t2skmsscg"
        class="animable"
      ></path>
      <line
        x1="378.09"
        y1="227.42"
        x2="380.86"
        y2="221.5"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 379.475px 224.46px;
        "
        id="elx7i5u522hjh"
        class="animable"
      ></line>
      <path
        d="M374.73,225.68c-1.66-1.12-3.4-2.11-5-3.39"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 372.23px 223.985px;
        "
        id="el1uljvnguj5uh"
        class="animable"
      ></path>
      <path
        d="M378,220c-1.4-1-2.89-1.78-4.19-2.86"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 375.905px 218.57px;
        "
        id="elbzqdezx10ni"
        class="animable"
      ></path>
      <line
        x1="372.21"
        y1="224.04"
        x2="375.89"
        y2="218.64"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 374.05px 221.34px;
        "
        id="el7glesk70msd"
        class="animable"
      ></line>
      <path
        d="M369.17,221.81a41.51,41.51,0,0,1-4.36-4.13"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 366.99px 219.745px;
        "
        id="elaky71aci78b"
        class="animable"
      ></path>
      <path
        d="M373.31,216.76a36.83,36.83,0,0,1-3.68-3.49"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 371.47px 215.015px;
        "
        id="eld8yu0so0ng"
        class="animable"
      ></path>
      <line
        x1="366.92"
        y1="219.82"
        x2="371.41"
        y2="215.07"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 369.165px 217.445px;
        "
        id="el8abo3trx2an"
        class="animable"
      ></line>
      <path
        d="M364.28,217.11a44.2,44.2,0,0,1-3.63-4.78"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 362.465px 214.72px;
        "
        id="elundcz5saf1f"
        class="animable"
      ></path>
      <path
        d="M369.18,212.78a36.3,36.3,0,0,1-3.07-4"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 367.645px 210.78px;
        "
        id="elmdts747jq4g"
        class="animable"
      ></path>
      <line
        x1="362.38"
        y1="214.78"
        x2="367.58"
        y2="210.82"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 364.98px 212.8px;
        "
        id="el8ozqt1jrpey"
        class="animable"
      ></line>
      <path
        d="M360.22,211.67a42.69,42.69,0,0,1-2.82-5.3"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 358.81px 209.02px;
        "
        id="elmxig2r25dfs"
        class="animable"
      ></path>
      <path
        d="M365.75,208.2a36.23,36.23,0,0,1-2.38-4.48"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 364.56px 205.96px;
        "
        id="elku59io3afa9"
        class="animable"
      ></path>
      <line
        x1="358.72"
        y1="209.07"
        x2="364.49"
        y2="206"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 361.605px 207.535px;
        "
        id="el3nffqzqf6n9"
        class="animable"
      ></line>
      <path
        d="M357.08,205.66a44.26,44.26,0,0,1-1.92-5.69"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 356.12px 202.815px;
        "
        id="elri333igkoi"
        class="animable"
      ></path>
      <path
        d="M363.1,203.12a37.24,37.24,0,0,1-1.62-4.81"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 362.29px 200.715px;
        "
        id="el9xx8b4a4osh"
        class="animable"
      ></path>
      <line
        x1="356.03"
        y1="202.85"
        x2="362.21"
        y2="200.74"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 359.12px 201.795px;
        "
        id="elb1ebg2jwelh"
        class="animable"
      ></line>
      <path
        d="M355,199.22a43.81,43.81,0,0,1-1-5.92"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 354.5px 196.26px;
        "
        id="el0y6erpy4xr5o"
        class="animable"
      ></path>
      <path
        d="M361.31,197.67a38.68,38.68,0,0,1-.85-5"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 360.885px 195.17px;
        "
        id="el0ucftf7i49xc"
        class="animable"
      ></path>
      <line
        x1="354.36"
        y1="196.28"
        x2="360.8"
        y2="195.18"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 357.58px 195.73px;
        "
        id="elg6tkewlttwk"
        class="animable"
      ></line>
      <path
        d="M396.2,163.75a25.5,25.5,0,0,1,24.95,28.94"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 2px;
          transform-origin: 408.792px 178.22px;
        "
        id="el9da9nrngryj"
        class="animable"
      ></path>
      <path
        d="M372.11,180.08a25.51,25.51,0,0,1,24.09-16.33"
        style="
          fill: none;
          stroke: rgb(81, 123, 189);
          stroke-miterlimit: 10;
          stroke-width: 5px;
          transform-origin: 384.155px 171.914px;
        "
        id="el9z5hocy304t"
        class="animable"
      ></path>
      <path
        d="M400.49,214.3a25.49,25.49,0,0,1-28.38-34.22"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 2px;
          transform-origin: 385.451px 197.398px;
        "
        id="elt5dc94hl8uh"
        class="animable"
      ></path>
      <path
        d="M421.15,192.69a25.5,25.5,0,0,1-20.66,21.61"
        style="
          fill: none;
          stroke: rgb(81, 123, 189);
          stroke-miterlimit: 10;
          stroke-width: 5px;
          transform-origin: 410.82px 203.495px;
        "
        id="el23d22cyfx8m"
        class="animable"
      ></path>
      <polygon
        points="183.58 341.4 203.65 341.4 203.65 329.06 192 329.06 190.82 326.04 183.58 326.04 183.58 341.4"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 193.615px 333.72px;
        "
        id="el0vrds4ywg8dq"
        class="animable"
      ></polygon>
      <polygon
        points="183.58 361.34 203.65 361.34 203.65 349 192 349 190.82 345.99 183.58 345.99 183.58 361.34"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 193.615px 353.665px;
        "
        id="eliq7lf9dm2m"
        class="animable"
      ></polygon>
      <polygon
        points="183.58 381.28 203.65 381.28 203.65 368.94 192 368.94 190.82 365.93 183.58 365.93 183.58 381.28"
        style="
          fill: rgb(38, 50, 56);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 193.615px 373.605px;
        "
        id="elyh5tc04gmk"
        class="animable"
      ></polygon>
      <polygon
        points="215.58 341.4 235.65 341.4 235.65 329.06 224 329.06 222.82 326.04 215.58 326.04 215.58 341.4"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 225.615px 333.72px;
        "
        id="ela4tsq0tk59r"
        class="animable"
      ></polygon>
      <polygon
        points="215.58 361.34 235.65 361.34 235.65 349 224 349 222.82 345.99 215.58 345.99 215.58 361.34"
        style="
          fill: rgb(38, 50, 56);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 225.615px 353.665px;
        "
        id="elszlvregbxcn"
        class="animable"
      ></polygon>
      <polygon
        points="215.58 381.28 235.65 381.28 235.65 368.94 224 368.94 222.82 365.93 215.58 365.93 215.58 381.28"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 225.615px 373.605px;
        "
        id="elzwzupbxwc7"
        class="animable"
      ></polygon>
      <polygon
        points="183.58 90.4 203.65 90.4 203.65 78.06 192 78.06 190.82 75.04 183.58 75.04 183.58 90.4"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 193.615px 82.72px;
        "
        id="elhwsbrigifrv"
        class="animable"
      ></polygon>
      <polygon
        points="183.58 110.34 203.65 110.34 203.65 98 192 98 190.82 94.99 183.58 94.99 183.58 110.34"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 193.615px 102.665px;
        "
        id="el5fngx4hmvg8"
        class="animable"
      ></polygon>
      <polygon
        points="183.58 130.28 203.65 130.28 203.65 117.94 192 117.94 190.82 114.93 183.58 114.93 183.58 130.28"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 193.615px 122.605px;
        "
        id="elk92bg9ov5bk"
        class="animable"
      ></polygon>
      <polygon
        points="215.58 90.4 235.65 90.4 235.65 78.06 224 78.06 222.82 75.04 215.58 75.04 215.58 90.4"
        style="
          fill: rgb(38, 50, 56);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 225.615px 82.72px;
        "
        id="eliiare0yhqeg"
        class="animable"
      ></polygon>
      <polygon
        points="215.58 110.34 235.65 110.34 235.65 98 224 98 222.82 94.99 215.58 94.99 215.58 110.34"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 225.615px 102.665px;
        "
        id="el1c0rexoy9sk"
        class="animable"
      ></polygon>
      <polygon
        points="215.58 130.28 235.65 130.28 235.65 117.94 224 117.94 222.82 114.93 215.58 114.93 215.58 130.28"
        style="
          fill: rgb(38, 50, 56);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 225.615px 122.605px;
        "
        id="el0yep7dvfhga"
        class="animable"
      ></polygon>
    </g>
    <g
      id="freepik--Character--inject-2"
      class="animable"
      style="transform-origin: 309.045px 299.657px"
    >
      <ellipse
        cx="328.07"
        cy="435.61"
        rx="55"
        ry="8.5"
        style="fill: rgb(38, 50, 56); transform-origin: 328.07px 435.61px"
        id="eltc5msdkg2mo"
        class="animable"
      ></ellipse>
      <path
        d="M317.85,423.92s-10.5,6.75-16.25,8-7.5,3-2.75,5.25,19.25,3,26,1.5,9.25.5,10-2.5a19.12,19.12,0,0,0-.25-8.5C334.1,425.42,321.85,421.92,317.85,423.92Z"
        style="
          fill: rgb(255, 255, 255);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 315.661px 431.388px;
        "
        id="elf4pk5zlt9yb"
        class="animable"
      ></path>
      <path
        d="M304.1,288.92s1.25,19.5,4.75,34.75,5.25,35.75,7,47,3,29.75,3.25,38,.75,10.75.25,11.5-1.75,1-1.75,2-.25,3.25-.25,3.25,2.25,2,8.25,4.25,10,3.5,10,3.5a10.84,10.84,0,0,0,3.25-6.5c.75-4.5-1.5-9.75-1.5-12.75s6.5-29.25,4.25-40-6.75-23-6.75-29.5,2.5-25.75,2.5-25.75,6.5-10.75,6.75-18.5a132.63,132.63,0,0,0-.25-13.75S314.35,290.67,304.1,288.92Z"
        style="
          fill: rgb(38, 50, 56);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 324.138px 359.795px;
        "
        id="el789tr6fhlpk"
        class="animable"
      ></path>
      <path
        d="M309.25,219.31a60.1,60.1,0,0,0-3.66,19.38c0,10.05-1.46,31.81-2.93,41.31s-2.55,10.06,0,11.52,10.06,3.11,21.21,2.38,23-4.39,23.59-6,.18-3.84-.37-6,2-25.23-3.47-44.42-9.33-28.89-11.89-31.45l-2.56-2.56Z"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 324.438px 248.774px;
        "
        id="elo08h2efiic"
        class="animable"
      ></path>
      <path
        d="M292.06,170.67s-.36,6.77.55,9.15,1.28,5.11.37,6.94-2.75,4-1.47,5.12a15.22,15.22,0,0,0,2.74,1.83s3.66,6.58,5.49,10.24,2.74,4.57,4.57,3.84a27.49,27.49,0,0,0,3.84-2s5.12,5.85,5.85,7.31a9.23,9.23,0,0,0,2.56,2.74L329,202.3a19.13,19.13,0,0,1-4.39-8.41c-1.28-5.3-1.64-6-.36-8.41s3.84-2.56,2.37-8.41.55-7.68-4.75-11.33-15.36-4.21-18.28-4.21a10.85,10.85,0,0,0-6.58,2C295.53,164.64,292.06,170.67,292.06,170.67Z"
        style="
          fill: rgb(255, 255, 255);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 310.036px 188.683px;
        "
        id="eldqt0grteaw4"
        class="animable"
      ></path>
      <path
        d="M303.94,157.15s-13.16,4.2-15,5.85,2.75,11.7,2.75,11.7l9.14-5.31L297,174s-2,.54,5.12.18,10.6-2.93,10.6-2.93-4.57,5.12-4.2,6.58,2.92,3.84,3.65,4.94,1.83-.36,1.83-.36,1.1,4.57,1.46,3.47a39.35,39.35,0,0,0,.73-4.94s1.65-3.65,4-2.74,3.65,6.76,2,9.51a7.42,7.42,0,0,1-4.57,3.29s3.84,4.2,4.93,3.29,1.47-2.74,2.2-3.66,9.87-8.77,11-14.44-7.13-19.38-13.53-20.66S303.94,157.15,303.94,157.15Z"
        style="
          fill: rgb(38, 50, 56);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 312.18px 174.811px;
        "
        id="elklw0idoeoi"
        class="animable"
      ></path>
      <path
        d="M293.05,180.8l1.49,6A1.54,1.54,0,0,0,295.9,188c1.67.15,4.72.3,5.12-.49.55-1.09-.18-8.22-1.1-9.32-1.12-1.35-3.67-.27-4.91.32a13.9,13.9,0,0,0-1.45.93A1.34,1.34,0,0,0,293.05,180.8Z"
        style="
          fill: rgb(255, 255, 255);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 297.115px 182.83px;
        "
        id="elyzq3s9qwol"
        class="animable"
      ></path>
      <polygon
        points="301.2 181.09 316.38 179.63 301.27 182.74 301.2 181.09"
        style="
          fill: rgb(38, 50, 56);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 308.79px 181.185px;
        "
        id="eldu2k1u3azlt"
        class="animable"
      ></polygon>
      <path
        d="M316.74,191s2.2,6.4.73,8.22-6.21,4.76-6.21,4.76"
        style="
          fill: rgb(255, 255, 255);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 314.618px 197.49px;
        "
        id="elielqrktxeyk"
        class="animable"
      ></path>
      <path
        d="M329,198.65s-23.4,21.39-22.85,21.94,4.72,6.9,4.72,6.9a163.3,163.3,0,0,1,10.33-13.78,159,159,0,0,1,12.19-11.59Z"
        style="
          fill: rgb(38, 50, 56);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 319.765px 213.07px;
        "
        id="el91qmgv2mq"
        class="animable"
      ></path>
      <path
        d="M274,256.05s-6.58,0-7.86,1.47,2.19,2.92,3.47,3.1a22.15,22.15,0,0,0,4.76-.54Z"
        style="
          fill: rgb(255, 255, 255);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 270.116px 258.335px;
        "
        id="elxcl6dcy46w"
        class="animable"
      ></path>
      <path
        d="M276.89,254.41s-2.93-1.1-3.66,1.28,1.1,9.5,2.19,9.69,1.65-.55,1.65-2.74S276.89,254.41,276.89,254.41Z"
        style="
          fill: rgb(255, 255, 255);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 275.066px 259.782px;
        "
        id="elrpln3q8i4pq"
        class="animable"
      ></path>
      <path
        d="M282,254s-3.84-2.19-5.31-.55.19,11.34.19,11.34c1.09.55,2.92-5.85,4.57-7.86a19.46,19.46,0,0,0,2-2.74Z"
        style="
          fill: rgb(255, 255, 255);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 279.766px 258.832px;
        "
        id="el6bm1b6xd4wn"
        class="animable"
      ></path>
      <path
        d="M305.77,254.23s-9.5,8.77-11.15,8.77-8.41-7.86-10.05-8.77-3.84,4.2-4.39,5.85a29.42,29.42,0,0,0-.73,5.48,1.07,1.07,0,0,0,1.82-.18,64.4,64.4,0,0,0,3.11-6l1.1,1.47"
        style="
          fill: rgb(255, 255, 255);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 292.61px 260.055px;
        "
        id="el326jni8fxfe"
        class="animable"
      ></path>
      <path
        d="M290.6,260.44s-5.67.92-6.58,2.56-1.1,1.83.91,2,6.77.73,7.5-.18S291.33,260.62,290.6,260.44Z"
        style="
          fill: rgb(255, 255, 255);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 287.981px 262.883px;
        "
        id="elcrtr7wkarww"
        class="animable"
      ></path>
      <polygon
        points="257.69 271.59 235.02 222.05 248.91 222.78 268.48 269.22 257.69 271.59"
        style="
          fill: rgb(125, 125, 125);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 251.75px 246.82px;
        "
        id="el2ld0jinfefz"
        class="animable"
      ></polygon>
      <polygon
        points="267.2 265.74 257.14 270.13 312.17 270.13 304.68 265.38 267.2 265.74"
        style="
          fill: rgb(125, 125, 125);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 284.655px 267.755px;
        "
        id="eleq595k7gwl"
        class="animable"
      ></polygon>
      <path
        d="M326.8,218.94s-6.95,1.28-8.05,7.5,2,30,2,30.53v4.39c0,1.09,4.39.73,9.51,3.1s8.23,6.22,8.23,3.29-.73-38-.73-38"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 328.502px 243.873px;
        "
        id="elww1s2nguxld"
        class="animable"
      ></path>
      <line
        x1="338.13"
        y1="243.8"
        x2="341.06"
        y2="233.75"
        style="
          fill: rgb(255, 255, 255);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 339.595px 238.775px;
        "
        id="eldspq8mfgqlm"
        class="animable"
      ></line>
      <path
        d="M317.29,258.43l.91,7.86s19.93,11.7,20.48,11.34,1.1-1.47,1.1-3.29,2.92-11.34-.55-13.53S323,258.25,323,258.25,319.3,257.52,317.29,258.43Z"
        style="
          fill: rgb(38, 50, 56);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 329.11px 267.806px;
        "
        id="elmtz8jitjns"
        class="animable"
      ></path>
      <path
        d="M319.67,265.93s-18.83,6.76-31.08,7.49-13.71,0-13.71,0-1.1-4.39-2.93-5.48-7.68-.19-8.78-.92-1.09-3.1-1.82-3.84-4-.36-4-.36-1.64.91-1.64,2.19-1.1,9.51.36,10.24,20.48,4.39,27.61,4.39,28.16,2,38.94.36,14.81-2.92,14.45-5.85S323.14,265.19,319.67,265.93Z"
        style="
          fill: rgb(255, 255, 255);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 296.202px 271.684px;
        "
        id="elxwi2oio7iwr"
        class="animable"
      ></path>
      <line
        x1="256.41"
        y1="263.92"
        x2="256.78"
        y2="273.6"
        style="
          fill: rgb(255, 255, 255);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 256.595px 268.76px;
        "
        id="elhdxhkl8k14"
        class="animable"
      ></line>
      <line
        x1="259.34"
        y1="263"
        x2="259.88"
        y2="272.51"
        style="
          fill: rgb(255, 255, 255);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 259.61px 267.755px;
        "
        id="elhptk447yyzq"
        class="animable"
      ></line>
      <path
        d="M263.17,267s-.73,2.2.55,3.11,6.22,1.65,6.22,1.65"
        style="
          fill: rgb(255, 255, 255);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 266.451px 269.38px;
        "
        id="el9g9a7vm2yxt"
        class="animable"
      ></path>
      <rect
        x="308.88"
        y="240.15"
        width="8.78"
        height="14.44"
        style="
          fill: rgb(255, 255, 255);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 313.27px 247.37px;
        "
        id="elc77watpfjw9"
        class="animable"
      ></rect>
    </g>
    <defs>
      <filter id="active" height="200%">
        <feMorphology
          in="SourceAlpha"
          result="DILATED"
          operator="dilate"
          radius="2"
        ></feMorphology>
        <feFlood
          flood-color="#32DFEC"
          flood-opacity="1"
          result="PINK"
        ></feFlood>
        <feComposite
          in="PINK"
          in2="DILATED"
          operator="in"
          result="OUTLINE"
        ></feComposite>
        <feMerge>
          <feMergeNode in="OUTLINE"></feMergeNode>
          <feMergeNode in="SourceGraphic"></feMergeNode>
        </feMerge>
      </filter>
      <filter id="hover" height="200%">
        <feMorphology
          in="SourceAlpha"
          result="DILATED"
          operator="dilate"
          radius="2"
        ></feMorphology>
        <feFlood
          flood-color="#ff0000"
          flood-opacity="0.5"
          result="PINK"
        ></feFlood>
        <feComposite
          in="PINK"
          in2="DILATED"
          operator="in"
          result="OUTLINE"
        ></feComposite>
        <feMerge>
          <feMergeNode in="OUTLINE"></feMergeNode>
          <feMergeNode in="SourceGraphic"></feMergeNode>
        </feMerge>
        <feColorMatrix
          type="matrix"
          values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 "
        ></feColorMatrix>
      </filter>
    </defs>
  </svg>
</template>
<script>
export default {
  name: "GetStartedIlustration",
};
</script>
<style>
svg#freepik_stories-secure-data:not(.animated) .animable {
  opacity: 0;
}
svg#freepik_stories-secure-data.animated #freepik--Shield--inject-2 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) slideDown;
  animation-delay: 0s;
}
@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.animator-hidden {
  display: none;
}
</style>
