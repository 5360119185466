var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-none text-body-2 text-md-body-1 font-weight-bold px-8",attrs:{"x-large":"","color":"accent","block":_vm.block},on:{"click":function($event){return _vm.$emit('started')}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$ml.get("userShiftSettings"))+" "),_c('v-icon',{staticClass:"ml-4",attrs:{"right":"","size":"22"},domProps:{"textContent":_vm._s('mdi-chevron-right')}})],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pt-3"},[_c('v-card-title',{staticClass:"text-h5 primary--text mb-2"},[_vm._v(" "+_vm._s(_vm.$ml.get("whatDoYouWantToDo"))+" ")]),_c('v-list',{staticClass:"px-5",model:{value:(_vm.selectedRoute),callback:function ($$v) {_vm.selectedRoute=$$v},expression:"selectedRoute"}},_vm._l((_vm.options),function(ref,index){
var icon = ref.icon;
var title = ref.title;
var to = ref.to;
return _c('v-list-item',{key:index,staticClass:"py-2 grey lighten-4 secondary--text text--lighten-2 mb-4",attrs:{"link":""},on:{"click":function($event){return _vm.setRedirectDialog(to)}}},[_c('v-list-item-action',{staticClass:"mr-5"},[_c('v-avatar',{attrs:{"color":"accent"}},[_c('v-icon',{attrs:{"size":"26","color":"white"},domProps:{"textContent":_vm._s(icon)}})],1)],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-wrap break-word normal-line-height font-weight-medium text-h6 mt-1",domProps:{"innerHTML":_vm._s(_vm.$ml.get(title))}})],1),_c('v-list-item-action',[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-arrow-right')}})],1)],1)}),1)],1)],1),_c('RedirectDialog',{attrs:{"show":_vm.showRedirect,"custom-description":"","max-width":"650"},on:{"redirect":_vm.setRedirectRoute}},[_c('v-list',{staticClass:"px-2 text-left py-0 my-0"},_vm._l((_vm.redirectTips),function(ref,index){
var title = ref.title;
return _c('v-list-item',{key:index},[_c('v-list-item-action',{staticClass:"mr-4"},[_c('v-icon',{attrs:{"size":"24","color":"success"},domProps:{"textContent":_vm._s('mdi-check-circle')}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-wrap break-word normal-line-height text-body-1 mt-1",domProps:{"innerHTML":_vm._s(_vm.$ml.get(title))}})],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }