<template>
  <svg
    class="animated"
    id="freepik_stories-secure-login"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
  >
    <g
      id="freepik--Plants--inject-110"
      class="animable"
      style="transform-origin: 211.106px 306.665px"
    >
      <line
        x1="48.65"
        y1="220.72"
        x2="67.1"
        y2="397.46"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 2px;
          transform-origin: 57.875px 309.09px;
        "
        id="eltpkx5ap04h"
        class="animable"
      ></line>
      <line
        x1="64.12"
        y1="363.63"
        x2="55.76"
        y2="354.8"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 2px;
          transform-origin: 59.94px 359.215px;
        "
        id="el3dr3rqifhyo"
        class="animable"
      ></line>
      <path
        d="M58.3,357.45A9.45,9.45,0,0,0,55,350.36c-2.14-1.92-13.44-6.67-14.51-2.69C39.29,352,50.86,360.49,58.3,357.45Z"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 49.3518px 352.11px;
        "
        id="elsrk9481d13d"
        class="animable"
      ></path>
      <line
        x1="58.3"
        y1="357.45"
        x2="47.55"
        y2="350.75"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 0.5px;
          transform-origin: 52.925px 354.1px;
        "
        id="elj0a23gdyxh8"
        class="animable"
      ></line>
      <line
        x1="66.22"
        y1="383.73"
        x2="57.86"
        y2="374.9"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 2px;
          transform-origin: 62.04px 379.315px;
        "
        id="elkfaoz42esno"
        class="animable"
      ></line>
      <path
        d="M60.4,377.55a9.45,9.45,0,0,0-3.33-7.09c-2.14-1.92-13.44-6.67-14.51-2.69C41.39,372.09,53,380.59,60.4,377.55Z"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 51.439px 372.209px;
        "
        id="el9jzao8cqhlu"
        class="animable"
      ></path>
      <line
        x1="60.4"
        y1="377.55"
        x2="49.65"
        y2="370.85"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 0.5px;
          transform-origin: 55.025px 374.2px;
        "
        id="el7k19tm40075"
        class="animable"
      ></line>
      <line
        x1="59"
        y1="314.59"
        x2="50.64"
        y2="305.76"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 2px;
          transform-origin: 54.82px 310.175px;
        "
        id="el8y9qxhe0i2w"
        class="animable"
      ></line>
      <path
        d="M53.18,308.4a9.47,9.47,0,0,0-3.32-7.09c-2.15-1.92-13.45-6.67-14.52-2.69C34.18,303,45.74,311.44,53.18,308.4Z"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 44.2195px 303.061px;
        "
        id="eleczdrtp91ni"
        class="animable"
      ></path>
      <line
        x1="53.18"
        y1="308.4"
        x2="42.43"
        y2="301.7"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 0.5px;
          transform-origin: 47.805px 305.05px;
        "
        id="eltwf8jr2w34q"
        class="animable"
      ></line>
      <line
        x1="61.1"
        y1="334.69"
        x2="52.74"
        y2="325.86"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 2px;
          transform-origin: 56.92px 330.275px;
        "
        id="el33aeszmhruu"
        class="animable"
      ></line>
      <path
        d="M55.28,328.5A9.46,9.46,0,0,0,52,321.41c-2.14-1.92-13.44-6.67-14.51-2.69C36.27,323.05,47.83,331.54,55.28,328.5Z"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 46.3404px 323.16px;
        "
        id="elkgmq7uocdv"
        class="animable"
      ></path>
      <line
        x1="55.28"
        y1="328.5"
        x2="44.53"
        y2="321.8"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 0.5px;
          transform-origin: 49.905px 325.15px;
        "
        id="el0k1nlvl3ab6e"
        class="animable"
      ></line>
      <line
        x1="55.6"
        y1="281.94"
        x2="47.24"
        y2="273.11"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 2px;
          transform-origin: 51.42px 277.525px;
        "
        id="elxra9hrt3wem"
        class="animable"
      ></line>
      <path
        d="M49.78,275.75a9.5,9.5,0,0,0-3.33-7.09C44.3,266.74,33,262,31.93,266,30.77,270.3,42.33,278.79,49.78,275.75Z"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 40.8145px 270.417px;
        "
        id="ela7lkvkj0ef"
        class="animable"
      ></path>
      <line
        x1="49.78"
        y1="275.75"
        x2="39.02"
        y2="269.05"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 0.5px;
          transform-origin: 44.4px 272.4px;
        "
        id="el9uzfhm5cn84"
        class="animable"
      ></line>
      <line
        x1="52.17"
        y1="249.14"
        x2="43.81"
        y2="240.31"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 2px;
          transform-origin: 47.99px 244.725px;
        "
        id="elw9uzb3fbo1f"
        class="animable"
      ></line>
      <path
        d="M46.35,243A9.46,9.46,0,0,0,43,235.87c-2.15-1.92-13.45-6.67-14.52-2.69C27.35,237.5,38.91,246,46.35,243Z"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 37.3765px 237.632px;
        "
        id="elgr7ioz2a85l"
        class="animable"
      ></path>
      <line
        x1="46.35"
        y1="242.96"
        x2="35.6"
        y2="236.26"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 0.5px;
          transform-origin: 40.975px 239.61px;
        "
        id="el0f3ez6q5fdy"
        class="animable"
      ></line>
      <line
        x1="62.72"
        y1="351.74"
        x2="69.08"
        y2="341.37"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 2px;
          transform-origin: 65.9px 346.555px;
        "
        id="elk1xxhaaiy3"
        class="animable"
      ></line>
      <path
        d="M67.14,344.49a9.48,9.48,0,0,1,1.79-7.63c1.7-2.32,11.77-9.3,13.64-5.63C84.61,335.22,75.05,345.93,67.14,344.49Z"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 74.9039px 337.403px;
        "
        id="el9gzuekudtvb"
        class="animable"
      ></path>
      <line
        x1="67.14"
        y1="344.49"
        x2="76.82"
        y2="335.23"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 0.5px;
          transform-origin: 71.98px 339.86px;
        "
        id="el3m5blue2zqb"
        class="animable"
      ></line>
      <line
        x1="57.2"
        y1="298.85"
        x2="63.56"
        y2="288.48"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 2px;
          transform-origin: 60.38px 293.665px;
        "
        id="elwqv6pz514wc"
        class="animable"
      ></line>
      <path
        d="M61.62,291.6A9.48,9.48,0,0,1,63.41,284c1.7-2.32,11.77-9.3,13.64-5.63C79.09,282.33,69.53,293,61.62,291.6Z"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 69.3863px 284.525px;
        "
        id="elniurqlo6ji8"
        class="animable"
      ></path>
      <line
        x1="61.62"
        y1="291.6"
        x2="71.3"
        y2="282.34"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 0.5px;
          transform-origin: 66.46px 286.97px;
        "
        id="el41n05e5aik"
        class="animable"
      ></line>
      <line
        x1="54.77"
        y1="275.6"
        x2="61.13"
        y2="265.24"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 2px;
          transform-origin: 57.95px 270.42px;
        "
        id="elex2j0iudhyk"
        class="animable"
      ></line>
      <path
        d="M59.19,268.35A9.48,9.48,0,0,1,61,260.72c1.71-2.31,11.78-9.3,13.65-5.63C76.66,259.09,67.1,269.79,59.19,268.35Z"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 66.9672px 261.263px;
        "
        id="elrufetd7rclh"
        class="animable"
      ></path>
      <line
        x1="59.19"
        y1="268.35"
        x2="68.87"
        y2="259.1"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 0.5px;
          transform-origin: 64.03px 263.725px;
        "
        id="elqfrhtz5crc9"
        class="animable"
      ></line>
      <line
        x1="51.22"
        y1="241.53"
        x2="57.57"
        y2="231.16"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 2px;
          transform-origin: 54.395px 236.345px;
        "
        id="elhd8u51rm807"
        class="animable"
      ></line>
      <path
        d="M55.63,234.27a9.44,9.44,0,0,1,1.8-7.62c1.7-2.32,11.77-9.3,13.64-5.63C73.1,225,63.55,235.71,55.63,234.27Z"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 63.3982px 227.188px;
        "
        id="eldvjyqpugb6u"
        class="animable"
      ></path>
      <line
        x1="55.63"
        y1="234.27"
        x2="65.32"
        y2="225.02"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 0.5px;
          transform-origin: 60.475px 229.645px;
        "
        id="elqt9xpa35xq"
        class="animable"
      ></line>
      <path
        d="M48.5,219.74a9.48,9.48,0,0,1,1.79-7.63c1.7-2.31,11.77-9.3,13.64-5.62C66,210.48,56.41,221.18,48.5,219.74Z"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 56.2674px 212.656px;
        "
        id="elbt3faat0sm5"
        class="animable"
      ></path>
      <line
        x1="48.5"
        y1="219.74"
        x2="58.18"
        y2="210.49"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 0.5px;
          transform-origin: 53.34px 215.115px;
        "
        id="elb3ek5cq12t"
        class="animable"
      ></line>
      <path
        d="M49.06,220.14a9.48,9.48,0,0,1-7.4-2.58c-2.13-1.94-8-12.69-4.17-14.16C41.68,201.79,51.32,212.42,49.06,220.14Z"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 42.8105px 211.708px;
        "
        id="el71ajvrrasug"
        class="animable"
      ></path>
      <line
        x1="49.06"
        y1="220.14"
        x2="40.87"
        y2="209.54"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 0.5px;
          transform-origin: 44.965px 214.84px;
        "
        id="el7a0i1zctpzi"
        class="animable"
      ></line>
      <line
        x1="97.3"
        y1="245.06"
        x2="78.96"
        y2="421.81"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 2px;
          transform-origin: 88.13px 333.435px;
        "
        id="el94185b5h2ru"
        class="animable"
      ></line>
      <line
        x1="83.01"
        y1="388.1"
        x2="76.65"
        y2="377.73"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 2px;
          transform-origin: 79.83px 382.915px;
        "
        id="eltkwixbptu6n"
        class="animable"
      ></line>
      <path
        d="M78.59,380.85a9.46,9.46,0,0,0-1.8-7.63c-1.7-2.32-11.78-9.29-13.64-5.62C61.11,371.59,70.68,382.29,78.59,380.85Z"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 70.8209px 373.767px;
        "
        id="elo5v1jp4cw8a"
        class="animable"
      ></path>
      <line
        x1="78.59"
        y1="380.85"
        x2="69.45"
        y2="372.07"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 0.5px;
          transform-origin: 74.02px 376.46px;
        "
        id="elnagndjrdpe8"
        class="animable"
      ></line>
      <line
        x1="88.1"
        y1="339.05"
        x2="81.74"
        y2="328.68"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 2px;
          transform-origin: 84.92px 333.865px;
        "
        id="el4h82vol5y2i"
        class="animable"
      ></line>
      <path
        d="M83.68,331.8a9.46,9.46,0,0,0-1.8-7.63c-1.7-2.32-11.78-9.29-13.64-5.62C66.2,322.54,75.77,333.24,83.68,331.8Z"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 75.9109px 324.717px;
        "
        id="elrkswpgdcawe"
        class="animable"
      ></path>
      <line
        x1="83.68"
        y1="331.8"
        x2="74.54"
        y2="323.02"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 0.5px;
          transform-origin: 79.11px 327.41px;
        "
        id="eliyuu0ug5z7"
        class="animable"
      ></line>
      <line
        x1="86.02"
        y1="359.15"
        x2="79.65"
        y2="348.78"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 2px;
          transform-origin: 82.835px 353.965px;
        "
        id="elmyevkg8u83r"
        class="animable"
      ></line>
      <path
        d="M81.59,351.9a9.48,9.48,0,0,0-1.79-7.63C78.09,342,68,335,66.15,338.65,64.12,342.64,73.68,353.34,81.59,351.9Z"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 73.8223px 344.824px;
        "
        id="el5me5g18umoy"
        class="animable"
      ></path>
      <line
        x1="81.59"
        y1="351.9"
        x2="72.45"
        y2="343.13"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 0.5px;
          transform-origin: 77.02px 347.515px;
        "
        id="elu5xcldfzv4o"
        class="animable"
      ></line>
      <line
        x1="91.49"
        y1="306.39"
        x2="85.13"
        y2="296.03"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 2px;
          transform-origin: 88.31px 301.21px;
        "
        id="elw33sv81lba9"
        class="animable"
      ></line>
      <path
        d="M87.07,299.14a9.44,9.44,0,0,0-1.8-7.62c-1.7-2.32-11.78-9.3-13.65-5.62C69.59,289.89,79.16,300.59,87.07,299.14Z"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 79.297px 292.061px;
        "
        id="el9ty89tzqctt"
        class="animable"
      ></path>
      <line
        x1="87.07"
        y1="299.14"
        x2="77.93"
        y2="290.37"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 0.5px;
          transform-origin: 82.5px 294.755px;
        "
        id="el92de1kcmdrv"
        class="animable"
      ></line>
      <line
        x1="94.89"
        y1="273.6"
        x2="88.53"
        y2="263.24"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 2px;
          transform-origin: 91.71px 268.42px;
        "
        id="elyfl9qesd32e"
        class="animable"
      ></line>
      <path
        d="M90.47,266.35a9.48,9.48,0,0,0-1.79-7.63C87,256.4,76.89,249.43,75,253.1,73,257.09,82.56,267.79,90.47,266.35Z"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 82.6911px 259.267px;
        "
        id="el52z8z8fzc97"
        class="animable"
      ></path>
      <line
        x1="90.47"
        y1="266.35"
        x2="81.33"
        y2="257.58"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 0.5px;
          transform-origin: 85.9px 261.965px;
        "
        id="elbfr46z0a4rh"
        class="animable"
      ></line>
      <line
        x1="84.09"
        y1="376.17"
        x2="92.44"
        y2="367.33"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 2px;
          transform-origin: 88.265px 371.75px;
        "
        id="elu78no0he0up"
        class="animable"
      ></line>
      <path
        d="M89.9,370a9.45,9.45,0,0,1,3.33-7.09c2.14-1.92,13.44-6.68,14.51-2.7C108.91,364.51,97.35,373,89.9,370Z"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 98.8612px 364.648px;
        "
        id="elitklfeukihd"
        class="animable"
      ></path>
      <line
        x1="89.9"
        y1="369.98"
        x2="101.29"
        y2="362.92"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 0.5px;
          transform-origin: 95.595px 366.45px;
        "
        id="el3k0tzr4z913"
        class="animable"
      ></line>
      <line
        x1="89.58"
        y1="323.27"
        x2="97.93"
        y2="314.44"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 2px;
          transform-origin: 93.755px 318.855px;
        "
        id="elwiwqujqftp"
        class="animable"
      ></line>
      <path
        d="M95.39,317.09a9.48,9.48,0,0,1,3.33-7.1c2.14-1.92,13.43-6.68,14.51-2.7C114.4,311.62,102.84,320.12,95.39,317.09Z"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 104.351px 311.738px;
        "
        id="elne36knba7q"
        class="animable"
      ></path>
      <line
        x1="95.39"
        y1="317.09"
        x2="106.77"
        y2="310.02"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 0.5px;
          transform-origin: 101.08px 313.555px;
        "
        id="elirqehptf4xg"
        class="animable"
      ></line>
      <line
        x1="91.99"
        y1="300.03"
        x2="100.34"
        y2="291.19"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 2px;
          transform-origin: 96.165px 295.61px;
        "
        id="elgqze9hhuhv"
        class="animable"
      ></line>
      <path
        d="M97.81,293.84a9.42,9.42,0,0,1,3.32-7.09c2.14-1.92,13.44-6.68,14.51-2.7C116.81,288.37,105.25,296.88,97.81,293.84Z"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 106.766px 288.495px;
        "
        id="elffbw2eq98rf"
        class="animable"
      ></path>
      <line
        x1="97.81"
        y1="293.84"
        x2="109.19"
        y2="286.78"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 0.5px;
          transform-origin: 103.5px 290.31px;
        "
        id="elzgklky6rojg"
        class="animable"
      ></line>
      <line
        x1="95.52"
        y1="265.95"
        x2="103.88"
        y2="257.11"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 2px;
          transform-origin: 99.7px 261.53px;
        "
        id="elj991yqzy10a"
        class="animable"
      ></line>
      <path
        d="M101.34,259.76a9.45,9.45,0,0,1,3.33-7.09c2.14-1.92,13.43-6.68,14.51-2.7C120.35,254.29,108.79,262.8,101.34,259.76Z"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 110.301px 254.415px;
        "
        id="elhvgu6msb8q"
        class="animable"
      ></path>
      <line
        x1="101.34"
        y1="259.76"
        x2="112.72"
        y2="252.7"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 0.5px;
          transform-origin: 107.03px 256.23px;
        "
        id="el5itrzsnb249"
        class="animable"
      ></line>
      <path
        d="M97.35,244.07a9.46,9.46,0,0,1,3.32-7.09c2.15-1.92,13.44-6.68,14.51-2.7C116.35,238.6,104.8,247.11,97.35,244.07Z"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 106.306px 238.725px;
        "
        id="el0nz0wx5jz6u"
        class="animable"
      ></path>
      <line
        x1="97.35"
        y1="244.07"
        x2="108.73"
        y2="237.01"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 0.5px;
          transform-origin: 103.04px 240.54px;
        "
        id="elb2hqz29tset"
        class="animable"
      ></line>
      <path
        d="M97.82,244.58a9.48,9.48,0,0,1-6.71-4.05c-1.68-2.34-5.23-14.07-1.16-14.72C94.37,225.1,101.62,237.49,97.82,244.58Z"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 93.4768px 235.18px;
        "
        id="eli88pt1pmck"
        class="animable"
      ></path>
      <line
        x1="97.82"
        y1="244.58"
        x2="91.99"
        y2="232.52"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 0.5px;
          transform-origin: 94.905px 238.55px;
        "
        id="ellp1l52pjwqk"
        class="animable"
      ></line>
      <line
        x1="76.28"
        y1="173.09"
        x2="76.28"
        y2="396.6"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 2px;
          transform-origin: 76.28px 284.845px;
        "
        id="el23wirli6obp"
        class="animable"
      ></line>
      <line
        x1="76.46"
        y1="362.99"
        x2="69.06"
        y2="353.34"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 2px;
          transform-origin: 72.76px 358.165px;
        "
        id="el5kspng7vtrd"
        class="animable"
      ></line>
      <path
        d="M71.31,356.23a9.45,9.45,0,0,0-2.57-7.4c-1.94-2.12-12.68-8-14.16-4.18C53,348.83,63.59,358.49,71.31,356.23Z"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 62.8863px 349.979px;
        "
        id="el6wdpon0kjp9"
        class="animable"
      ></path>
      <line
        x1="71.31"
        y1="356.23"
        x2="61.31"
        y2="348.45"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 0.5px;
          transform-origin: 66.31px 352.34px;
        "
        id="ela4dwaos17fb"
        class="animable"
      ></line>
      <line
        x1="76.46"
        y1="320.99"
        x2="69.06"
        y2="311.34"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 2px;
          transform-origin: 72.76px 316.165px;
        "
        id="el5avra6s45b4"
        class="animable"
      ></line>
      <path
        d="M71.31,314.23a9.45,9.45,0,0,0-2.57-7.4c-1.94-2.13-12.68-8-14.16-4.18C53,306.83,63.59,316.48,71.31,314.23Z"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 62.8863px 307.977px;
        "
        id="ele8q6vgjr16u"
        class="animable"
      ></path>
      <line
        x1="71.31"
        y1="314.23"
        x2="61.31"
        y2="306.45"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 0.5px;
          transform-origin: 66.31px 310.34px;
        "
        id="ely6k8hkkz04"
        class="animable"
      ></line>
      <line
        x1="76.46"
        y1="271.67"
        x2="69.06"
        y2="262.02"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 2px;
          transform-origin: 72.76px 266.845px;
        "
        id="el1o2diu436vu"
        class="animable"
      ></line>
      <path
        d="M71.31,264.92a9.45,9.45,0,0,0-2.57-7.4c-1.94-2.13-12.68-8-14.16-4.18C53,257.52,63.59,267.17,71.31,264.92Z"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 62.8863px 258.667px;
        "
        id="el7zfvb6c1zla"
        class="animable"
      ></path>
      <line
        x1="71.31"
        y1="264.92"
        x2="61.31"
        y2="257.14"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 0.5px;
          transform-origin: 66.31px 261.03px;
        "
        id="eloayevf3lpra"
        class="animable"
      ></line>
      <line
        x1="76.46"
        y1="238.85"
        x2="69.06"
        y2="229.19"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 2px;
          transform-origin: 72.76px 234.02px;
        "
        id="elcuolh96tn1g"
        class="animable"
      ></line>
      <path
        d="M71.31,232.09a9.45,9.45,0,0,0-2.57-7.4c-1.94-2.13-12.68-8-14.16-4.18C53,224.69,63.59,234.34,71.31,232.09Z"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 62.8863px 225.837px;
        "
        id="eljuyarg5mvti"
        class="animable"
      ></path>
      <line
        x1="71.31"
        y1="232.09"
        x2="61.31"
        y2="224.31"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 0.5px;
          transform-origin: 66.31px 228.2px;
        "
        id="elvuefyuhg3q"
        class="animable"
      ></line>
      <line
        x1="76.46"
        y1="205.88"
        x2="69.06"
        y2="196.22"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 2px;
          transform-origin: 72.76px 201.05px;
        "
        id="elki0zzti5e99"
        class="animable"
      ></line>
      <path
        d="M71.31,199.12a9.45,9.45,0,0,0-2.57-7.4c-1.94-2.13-12.68-8-14.16-4.18C53,191.72,63.59,201.37,71.31,199.12Z"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 62.8863px 192.867px;
        "
        id="elm1750zdmoas"
        class="animable"
      ></path>
      <line
        x1="71.31"
        y1="199.12"
        x2="61.31"
        y2="191.34"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 0.5px;
          transform-origin: 66.31px 195.23px;
        "
        id="elvqnatp4d3h"
        class="animable"
      ></line>
      <line
        x1="76.3"
        y1="375.82"
        x2="83.7"
        y2="366.16"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 2px;
          transform-origin: 80px 370.99px;
        "
        id="elcmn2znz5u6j"
        class="animable"
      ></line>
      <path
        d="M81.44,369.06a9.48,9.48,0,0,1,2.58-7.4c1.93-2.13,12.67-8,14.15-4.18C99.78,361.66,89.16,371.31,81.44,369.06Z"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 89.8673px 362.807px;
        "
        id="elti9d7v4kgdr"
        class="animable"
      ></path>
      <line
        x1="81.44"
        y1="369.06"
        x2="92.03"
        y2="360.86"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 0.5px;
          transform-origin: 86.735px 364.96px;
        "
        id="el5tjc0g2olhv"
        class="animable"
      ></line>
      <line
        x1="76.3"
        y1="309.01"
        x2="83.7"
        y2="299.36"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 2px;
          transform-origin: 80px 304.185px;
        "
        id="els8qu1ka09am"
        class="animable"
      ></line>
      <path
        d="M81.44,302.26a9.48,9.48,0,0,1,2.58-7.4c1.93-2.13,12.67-8,14.15-4.18C99.78,294.86,89.16,304.51,81.44,302.26Z"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 89.8673px 296.007px;
        "
        id="elkn5vsk3n0ap"
        class="animable"
      ></path>
      <line
        x1="81.44"
        y1="302.26"
        x2="92.03"
        y2="294.06"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 0.5px;
          transform-origin: 86.735px 298.16px;
        "
        id="eldcegf4yw7x"
        class="animable"
      ></line>
      <line
        x1="76.3"
        y1="276.04"
        x2="83.7"
        y2="266.39"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 2px;
          transform-origin: 80px 271.215px;
        "
        id="el0ci91jpnup6i"
        class="animable"
      ></line>
      <path
        d="M81.44,269.29a9.48,9.48,0,0,1,2.58-7.4c1.93-2.13,12.67-8,14.15-4.19C99.78,261.89,89.16,271.54,81.44,269.29Z"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 89.8673px 263.035px;
        "
        id="elkbc2dg0if3"
        class="animable"
      ></path>
      <line
        x1="81.44"
        y1="269.29"
        x2="92.03"
        y2="261.09"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 0.5px;
          transform-origin: 86.735px 265.19px;
        "
        id="el218frr9dzox"
        class="animable"
      ></line>
      <line
        x1="76.3"
        y1="232.46"
        x2="83.7"
        y2="222.81"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 2px;
          transform-origin: 80px 227.635px;
        "
        id="eltxszptgy9wl"
        class="animable"
      ></line>
      <path
        d="M81.44,225.71a9.48,9.48,0,0,1,2.58-7.4c1.93-2.13,12.67-8,14.15-4.19C99.78,218.31,89.16,228,81.44,225.71Z"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 89.8673px 219.459px;
        "
        id="elle282mtt9w"
        class="animable"
      ></path>
      <line
        x1="81.44"
        y1="225.71"
        x2="92.03"
        y2="217.51"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 0.5px;
          transform-origin: 86.735px 221.61px;
        "
        id="el63givc2mt84"
        class="animable"
      ></line>
      <line
        x1="76.3"
        y1="198.2"
        x2="83.7"
        y2="188.55"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 2px;
          transform-origin: 80px 193.375px;
        "
        id="el6cv5nv6qaag"
        class="animable"
      ></line>
      <path
        d="M81.44,191.45a9.47,9.47,0,0,1,2.58-7.4c1.93-2.13,12.67-8,14.15-4.19C99.78,184.05,89.16,193.7,81.44,191.45Z"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 89.8671px 185.195px;
        "
        id="elemrpvpl86ck"
        class="animable"
      ></path>
      <line
        x1="81.44"
        y1="191.45"
        x2="92.03"
        y2="183.25"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 0.5px;
          transform-origin: 86.735px 187.35px;
        "
        id="el71kpcmyzv5"
        class="animable"
      ></line>
      <path
        d="M75.85,176.25a9.48,9.48,0,0,1,2.58-7.4c1.93-2.13,12.67-8,14.15-4.18C94.19,168.85,83.57,178.5,75.85,176.25Z"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 84.2773px 169.997px;
        "
        id="elcdc2dbfhmnp"
        class="animable"
      ></path>
      <line
        x1="75.85"
        y1="176.25"
        x2="86.44"
        y2="168.05"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 0.5px;
          transform-origin: 81.145px 172.15px;
        "
        id="elixueg5xmbf9"
        class="animable"
      ></line>
      <path
        d="M76.37,176.71a9.48,9.48,0,0,1-7.09-3.34c-1.91-2.15-6.65-13.45-2.67-14.52C70.93,157.69,79.42,169.26,76.37,176.71Z"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 71.0405px 167.74px;
        "
        id="el1rbdiv8h941j"
        class="animable"
      ></path>
      <line
        x1="76.37"
        y1="176.71"
        x2="69.33"
        y2="165.31"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          stroke-width: 0.5px;
          transform-origin: 72.85px 171.01px;
        "
        id="elkygg8h8n32o"
        class="animable"
      ></line>
      <polygon
        points="85.48 454.56 61.81 454.56 58.85 394.79 88.44 394.79 85.48 454.56"
        style="
          fill: rgb(38, 50, 56);
          stroke: rgb(38, 50, 56);
          stroke-miterlimit: 10;
          transform-origin: 73.645px 424.675px;
        "
        id="elff7v63jbk8d"
        class="animable"
      ></polygon>
      <path
        d="M379.34,315.42a13.23,13.23,0,0,1,1.51-5.53c1.38-2.27,6.41-3.9,9.06-2.77a35.14,35.14,0,0,1,3.9,1.89,29.77,29.77,0,0,0-7.43,3.52A18.59,18.59,0,0,1,379.34,315.42Z"
        style="fill: rgb(81, 123, 189); transform-origin: 386.575px 311.086px"
        id="elh1fikjlmqdj"
        class="animable"
      ></path>
      <path
        d="M374.05,317.06a13,13,0,0,0,3.53-6.92c.5-4-3.78,1.68-3.78,1.68s-2.14-8.1-3.39-3.57S374.05,317.06,374.05,317.06Z"
        style="fill: rgb(81, 123, 189); transform-origin: 373.913px 311.967px"
        id="el5u5dmn7r9nu"
        class="animable"
      ></path>
      <path
        d="M368.27,315.8s.75-3.77-.63-6.41-5-2.39-8.68-1.26a20.55,20.55,0,0,1-4.78,1.13,54.18,54.18,0,0,0,7.92,4.4A49.08,49.08,0,0,0,368.27,315.8Z"
        style="fill: rgb(81, 123, 189); transform-origin: 361.327px 311.56px"
        id="el2p3ysuplumx"
        class="animable"
      ></path>
      <path
        d="M374,329.36a.33.33,0,0,0,.33-.33V311a.32.32,0,0,0-.33-.32.32.32,0,0,0-.32.32v18A.32.32,0,0,0,374,329.36Z"
        style="fill: rgb(38, 50, 56); transform-origin: 374.004px 320.02px"
        id="el6a51674l54p"
        class="animable"
      ></path>
      <path
        d="M372.51,329.36h.05a.33.33,0,0,0,.27-.37c-.06-.45-1.58-11.11-6.74-17a.33.33,0,0,0-.46,0,.32.32,0,0,0,0,.46c5,5.77,6.57,16.58,6.59,16.69A.32.32,0,0,0,372.51,329.36Z"
        style="fill: rgb(38, 50, 56); transform-origin: 369.183px 320.628px"
        id="ele7h0wl6pw99"
        class="animable"
      ></path>
      <path
        d="M375.53,329.11h0a.34.34,0,0,0,.33-.33c0-.11-.05-10.35,6-15.22a.33.33,0,1,0-.41-.51c-6.34,5.08-6.29,15.3-6.29,15.74A.34.34,0,0,0,375.53,329.11Z"
        style="fill: rgb(38, 50, 56); transform-origin: 378.589px 321.03px"
        id="elqz02xmlokf"
        class="animable"
      ></path>
      <path
        d="M370.5,329h.08a.32.32,0,0,0,.23-.4,20.86,20.86,0,0,0-2.94-6.14.33.33,0,0,0-.46-.08.34.34,0,0,0-.07.46,20.25,20.25,0,0,1,2.84,5.93A.32.32,0,0,0,370.5,329Z"
        style="fill: rgb(38, 50, 56); transform-origin: 369.051px 325.66px"
        id="elramw5zh7ixc"
        class="animable"
      ></path>
      <path
        d="M368.52,324s.12-3.52-1.39-4-2.89.76-4,1.51-2.39,1.51-2.39,3.78a42.4,42.4,0,0,0,.88,6.41s1.26-3.9,3.4-5.66A8.8,8.8,0,0,1,368.52,324Z"
        style="fill: rgb(81, 123, 189); transform-origin: 364.631px 325.796px"
        id="elo37ilaeqno"
        class="animable"
      ></path>
      <path
        d="M377.8,329.11a.34.34,0,0,0,.31-.23,11.39,11.39,0,0,1,3.28-4.87.33.33,0,1,0-.4-.52,12,12,0,0,0-3.5,5.19.32.32,0,0,0,.21.41Z"
        style="fill: rgb(38, 50, 56); transform-origin: 379.511px 326.254px"
        id="elvq7rwznung"
        class="animable"
      ></path>
      <path
        d="M379.34,325.11a5.2,5.2,0,0,1,.75-3.9c1.26-1.63,4.28-1.26,5.79.5s3.65,7.05,3.65,7.05a12,12,0,0,0-6.54-2.39C379.46,326.37,379.34,325.11,379.34,325.11Z"
        style="fill: rgb(81, 123, 189); transform-origin: 384.382px 324.464px"
        id="el8ln1wjddus"
        class="animable"
      ></path>
      <polygon
        points="369.31 348.39 379.35 348.39 384.37 331.79 364.29 331.79 369.31 348.39"
        style="
          fill: rgb(38, 50, 56);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 374.33px 340.09px;
        "
        id="el1d12185q20pj"
        class="animable"
      ></polygon>
      <g id="elv72ufxir8we">
        <rect
          x="364.24"
          y="329.03"
          width="20.19"
          height="2.75"
          style="
            fill: rgb(38, 50, 56);
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 374.335px 330.405px;
            transform: rotate(180deg);
          "
          class="animable"
        ></rect>
      </g>
    </g>
    <g
      id="freepik--Graphics--inject-110"
      class="animable"
      style="transform-origin: 267.142px 204.265px"
    >
      <g id="elc538b0o6tbi">
        <g
          style="opacity: 0.2; transform-origin: 421.302px 279.206px"
          class="animable"
        >
          <path
            d="M382.26,279.49a.94.94,0,0,1-.7-1.28l.05-.13a.94.94,0,0,1,1.3-.57,11.1,11.1,0,0,1,2.31,1.22,11,11,0,0,1-.44-2.66,1,1,0,0,1,1-1.06h.14a1,1,0,0,1,1,1.06,11.45,11.45,0,0,1-.43,2.66,12.14,12.14,0,0,1,2.3-1.22.93.93,0,0,1,1.3.59l0,.09a.93.93,0,0,1-.68,1.3,11.36,11.36,0,0,1-2.57.38,10.86,10.86,0,0,1,1.92,1.81,1,1,0,0,1-.16,1.41l-.11.09a.94.94,0,0,1-1.44-.28,12.13,12.13,0,0,1-1.19-2.35,13.26,13.26,0,0,1-1.22,2.35.94.94,0,0,1-1.44.28l-.1-.09a1,1,0,0,1-.17-1.41,12.72,12.72,0,0,1,1.93-1.84A10.25,10.25,0,0,1,382.26,279.49Z"
            id="elorj4mfqfr7m"
            class="animable"
            style="transform-origin: 385.832px 279.206px"
          ></path>
          <path
            d="M392.4,279.49a.94.94,0,0,1-.7-1.28l.05-.13a.94.94,0,0,1,1.3-.57,11.1,11.1,0,0,1,2.31,1.22,11,11,0,0,1-.44-2.66,1,1,0,0,1,1-1.06H396a1,1,0,0,1,1,1.06,11.45,11.45,0,0,1-.43,2.66,12.14,12.14,0,0,1,2.3-1.22,1,1,0,0,1,1.31.59l0,.09a.93.93,0,0,1-.68,1.3,11.36,11.36,0,0,1-2.57.38,10.86,10.86,0,0,1,1.92,1.81,1,1,0,0,1-.16,1.41l-.11.09a.93.93,0,0,1-1.43-.28,11.57,11.57,0,0,1-1.2-2.35,13.26,13.26,0,0,1-1.22,2.35.94.94,0,0,1-1.44.28l-.1-.09a1,1,0,0,1-.17-1.41,12.72,12.72,0,0,1,1.93-1.84A10.25,10.25,0,0,1,392.4,279.49Z"
            id="elcdy5lrojgbk"
            class="animable"
            style="transform-origin: 395.947px 279.206px"
          ></path>
          <path
            d="M402.54,279.49a1,1,0,0,1-.7-1.28l.05-.13a.94.94,0,0,1,1.3-.57,11.1,11.1,0,0,1,2.31,1.22,11,11,0,0,1-.43-2.66,1,1,0,0,1,1-1.06h.14a1,1,0,0,1,1,1.06,11.45,11.45,0,0,1-.43,2.66,12.14,12.14,0,0,1,2.3-1.22,1,1,0,0,1,1.31.59l0,.09a.93.93,0,0,1-.67,1.3,11.54,11.54,0,0,1-2.58.38,10.86,10.86,0,0,1,1.92,1.81,1,1,0,0,1-.16,1.41l-.11.09a.93.93,0,0,1-1.43-.28,12.1,12.1,0,0,1-1.2-2.35,13.26,13.26,0,0,1-1.22,2.35.93.93,0,0,1-1.43.28l-.11-.09a1,1,0,0,1-.17-1.41,12.72,12.72,0,0,1,1.93-1.84A10.25,10.25,0,0,1,402.54,279.49Z"
            id="eltyy8s4q9f3f"
            class="animable"
            style="transform-origin: 406.132px 279.206px"
          ></path>
          <path
            d="M412.68,279.49a1,1,0,0,1-.7-1.28l0-.13a.94.94,0,0,1,1.3-.57,11.1,11.1,0,0,1,2.31,1.22,11.45,11.45,0,0,1-.43-2.66,1,1,0,0,1,1-1.06h.14a1,1,0,0,1,1,1.06,11.45,11.45,0,0,1-.43,2.66,12.14,12.14,0,0,1,2.3-1.22,1,1,0,0,1,1.31.59l0,.09a.93.93,0,0,1-.67,1.3,11.54,11.54,0,0,1-2.58.38,10.86,10.86,0,0,1,1.92,1.81,1,1,0,0,1-.16,1.41l-.11.09a.93.93,0,0,1-1.43-.28,12.1,12.1,0,0,1-1.2-2.35A13.26,13.26,0,0,1,415,282.9a.93.93,0,0,1-1.43.28l-.11-.09a1,1,0,0,1-.17-1.41,12.72,12.72,0,0,1,1.93-1.84A10.25,10.25,0,0,1,412.68,279.49Z"
            id="elt2m5pxn1rb8"
            class="animable"
            style="transform-origin: 416.247px 279.206px"
          ></path>
          <path
            d="M422.82,279.49a1,1,0,0,1-.7-1.28l.05-.13a1,1,0,0,1,1.31-.57,11.3,11.3,0,0,1,2.3,1.22,11.45,11.45,0,0,1-.43-2.66,1,1,0,0,1,1-1.06h.14a1,1,0,0,1,1,1.06,11.45,11.45,0,0,1-.43,2.66,12.14,12.14,0,0,1,2.3-1.22,1,1,0,0,1,1.31.59l0,.09a.93.93,0,0,1-.67,1.3,11.54,11.54,0,0,1-2.58.38,10.86,10.86,0,0,1,1.92,1.81,1,1,0,0,1-.16,1.41l-.11.09a.93.93,0,0,1-1.43-.28,12.1,12.1,0,0,1-1.2-2.35,13.26,13.26,0,0,1-1.22,2.35.93.93,0,0,1-1.43.28l-.11-.09a.94.94,0,0,1-.16-1.41,12.16,12.16,0,0,1,1.92-1.84A10.25,10.25,0,0,1,422.82,279.49Z"
            id="elvbd6ggddit"
            class="animable"
            style="transform-origin: 426.412px 279.206px"
          ></path>
          <path
            d="M433,279.49a1,1,0,0,1-.7-1.28l.05-.13a1,1,0,0,1,1.31-.57,11.3,11.3,0,0,1,2.3,1.22,11.45,11.45,0,0,1-.43-2.66,1,1,0,0,1,1-1.06h.14a1,1,0,0,1,1,1.06,11.45,11.45,0,0,1-.43,2.66,11.9,11.9,0,0,1,2.31-1.22.94.94,0,0,1,1.3.59l0,.09a.93.93,0,0,1-.67,1.3,11.45,11.45,0,0,1-2.58.38,11.31,11.31,0,0,1,1.93,1.81,1,1,0,0,1-.17,1.41l-.11.09a.93.93,0,0,1-1.43-.28,12.1,12.1,0,0,1-1.2-2.35,13.26,13.26,0,0,1-1.22,2.35.93.93,0,0,1-1.43.28l-.11-.09a.94.94,0,0,1-.16-1.41,12.16,12.16,0,0,1,1.92-1.84A10.25,10.25,0,0,1,433,279.49Z"
            id="el9axpumafnhh"
            class="animable"
            style="transform-origin: 436.592px 279.206px"
          ></path>
          <path
            d="M443.11,279.49a1,1,0,0,1-.71-1.28l.05-.13a1,1,0,0,1,1.31-.57,11.3,11.3,0,0,1,2.3,1.22,11.45,11.45,0,0,1-.43-2.66,1,1,0,0,1,1-1.06h.14a1,1,0,0,1,1,1.06,11,11,0,0,1-.44,2.66,11.9,11.9,0,0,1,2.31-1.22.94.94,0,0,1,1.3.59l0,.09a.93.93,0,0,1-.68,1.3,11.45,11.45,0,0,1-2.58.38,11.31,11.31,0,0,1,1.93,1.81,1,1,0,0,1-.17,1.41l-.1.09a.94.94,0,0,1-1.44-.28,11.59,11.59,0,0,1-1.19-2.35,13.22,13.22,0,0,1-1.23,2.35.93.93,0,0,1-1.43.28l-.11-.09a.94.94,0,0,1-.16-1.41,12.16,12.16,0,0,1,1.92-1.84A10.1,10.1,0,0,1,443.11,279.49Z"
            id="ela9yzkckbhgs"
            class="animable"
            style="transform-origin: 446.687px 279.206px"
          ></path>
          <path
            d="M453.25,279.49a1,1,0,0,1-.71-1.28l0-.13a1,1,0,0,1,1.31-.57,11.3,11.3,0,0,1,2.3,1.22,11.45,11.45,0,0,1-.43-2.66,1,1,0,0,1,1-1.06h.14a1,1,0,0,1,1,1.06,11,11,0,0,1-.44,2.66,11.9,11.9,0,0,1,2.31-1.22.94.94,0,0,1,1.3.59l0,.09a.93.93,0,0,1-.68,1.3,11.45,11.45,0,0,1-2.58.38,11.31,11.31,0,0,1,1.93,1.81,1,1,0,0,1-.17,1.41l-.11.09a.93.93,0,0,1-1.43-.28,11.59,11.59,0,0,1-1.19-2.35,13.22,13.22,0,0,1-1.23,2.35.93.93,0,0,1-1.43.28l-.11-.09a.94.94,0,0,1-.16-1.41,12.16,12.16,0,0,1,1.92-1.84A10.1,10.1,0,0,1,453.25,279.49Z"
            id="elnwlbogje1af"
            class="animable"
            style="transform-origin: 456.802px 279.206px"
          ></path>
        </g>
      </g>
      <g id="ell3otjw43gh">
        <g
          style="opacity: 0.2; transform-origin: 221.844px 54.6968px"
          class="animable"
        >
          <path
            d="M182.79,55a.94.94,0,0,1-.71-1.27l.06-.14a.94.94,0,0,1,1.3-.57,11.83,11.83,0,0,1,2.3,1.22,11.45,11.45,0,0,1-.43-2.66.94.94,0,0,1,1-1.05h.13a1,1,0,0,1,1,1.05,11,11,0,0,1-.44,2.66A12.47,12.47,0,0,1,189.27,53a.94.94,0,0,1,1.3.6l0,.08a.93.93,0,0,1-.68,1.3,11.36,11.36,0,0,1-2.58.38,11,11,0,0,1,1.93,1.82,1,1,0,0,1-.16,1.41l-.11.08a1,1,0,0,1-1.44-.27A12,12,0,0,1,186.37,56a13.76,13.76,0,0,1-1.22,2.36,1,1,0,0,1-1.44.27l-.11-.08a1,1,0,0,1-.16-1.41,12.18,12.18,0,0,1,1.92-1.85A10.17,10.17,0,0,1,182.79,55Z"
            id="el0re8w2mxum3"
            class="animable"
            style="transform-origin: 186.334px 54.6968px"
          ></path>
          <path
            d="M192.93,55a.94.94,0,0,1-.71-1.27l.06-.14a.94.94,0,0,1,1.3-.57,11.55,11.55,0,0,1,2.3,1.22,11.45,11.45,0,0,1-.43-2.66.94.94,0,0,1,1-1.05h.13a.94.94,0,0,1,1,1.05,11,11,0,0,1-.44,2.66A12.47,12.47,0,0,1,199.41,53a.94.94,0,0,1,1.3.6l0,.08a.93.93,0,0,1-.68,1.3,11.36,11.36,0,0,1-2.58.38,11,11,0,0,1,1.93,1.82,1,1,0,0,1-.16,1.41l-.11.08a1,1,0,0,1-1.44-.27A12,12,0,0,1,196.51,56a13.76,13.76,0,0,1-1.22,2.36,1,1,0,0,1-1.44.27l-.11-.08a1,1,0,0,1-.16-1.41,12.63,12.63,0,0,1,1.92-1.85A10.17,10.17,0,0,1,192.93,55Z"
            id="elybz7qtnbz48"
            class="animable"
            style="transform-origin: 196.474px 54.6968px"
          ></path>
          <path
            d="M203.07,55a.94.94,0,0,1-.71-1.27l.06-.14a.94.94,0,0,1,1.3-.57,11.55,11.55,0,0,1,2.3,1.22,11.45,11.45,0,0,1-.43-2.66.94.94,0,0,1,1-1.05h.13a.94.94,0,0,1,1,1.05,11,11,0,0,1-.44,2.66A12.47,12.47,0,0,1,209.55,53a.94.94,0,0,1,1.3.6l0,.08a.93.93,0,0,1-.68,1.3,11.36,11.36,0,0,1-2.58.38,11,11,0,0,1,1.93,1.82,1,1,0,0,1-.16,1.41l-.11.08a1,1,0,0,1-1.44-.27A12,12,0,0,1,206.65,56a13.76,13.76,0,0,1-1.22,2.36,1,1,0,0,1-1.44.27l-.11-.08a1,1,0,0,1-.16-1.41,12.63,12.63,0,0,1,1.92-1.85A10.17,10.17,0,0,1,203.07,55Z"
            id="el7qon4698vxe"
            class="animable"
            style="transform-origin: 206.614px 54.6968px"
          ></path>
          <path
            d="M213.21,55a.94.94,0,0,1-.71-1.27l.06-.14a.94.94,0,0,1,1.3-.57,11.55,11.55,0,0,1,2.3,1.22,11.45,11.45,0,0,1-.43-2.66.94.94,0,0,1,1-1.05h.13a.94.94,0,0,1,1,1.05,11,11,0,0,1-.44,2.66A12.47,12.47,0,0,1,219.69,53a.94.94,0,0,1,1.3.6l0,.08a.93.93,0,0,1-.68,1.3,11.36,11.36,0,0,1-2.58.38,11,11,0,0,1,1.93,1.82,1,1,0,0,1-.16,1.41l-.11.08A1,1,0,0,1,218,58.4,12,12,0,0,1,216.79,56a13.76,13.76,0,0,1-1.22,2.36,1,1,0,0,1-1.44.27l-.11-.08a1,1,0,0,1-.16-1.41,12.63,12.63,0,0,1,1.92-1.85A10.17,10.17,0,0,1,213.21,55Z"
            id="el5cvqipb6tnu"
            class="animable"
            style="transform-origin: 216.754px 54.6847px"
          ></path>
          <path
            d="M223.35,55a.94.94,0,0,1-.71-1.27l.06-.14A.94.94,0,0,1,224,53a11.55,11.55,0,0,1,2.3,1.22,11.45,11.45,0,0,1-.43-2.66.94.94,0,0,1,1-1.05H227a.94.94,0,0,1,1,1.05,11.45,11.45,0,0,1-.43,2.66,12.12,12.12,0,0,1,2.3-1.22.94.94,0,0,1,1.3.6l0,.08a.93.93,0,0,1-.68,1.3,11.36,11.36,0,0,1-2.58.38,11,11,0,0,1,1.93,1.82,1,1,0,0,1-.16,1.41l-.11.08a1,1,0,0,1-1.44-.27A12,12,0,0,1,226.93,56a13.76,13.76,0,0,1-1.22,2.36,1,1,0,0,1-1.44.27l-.11-.08a1,1,0,0,1-.16-1.41,12.28,12.28,0,0,1,1.93-1.85A10.18,10.18,0,0,1,223.35,55Z"
            id="el9hzzrwwvgii"
            class="animable"
            style="transform-origin: 226.914px 54.6868px"
          ></path>
          <path
            d="M233.49,55a1,1,0,0,1-.71-1.27l.06-.14a.94.94,0,0,1,1.3-.57,11.88,11.88,0,0,1,2.31,1.22,11,11,0,0,1-.44-2.66.94.94,0,0,1,1-1.05h.13a.94.94,0,0,1,1,1.05,11.45,11.45,0,0,1-.43,2.66A12.42,12.42,0,0,1,240,53a.94.94,0,0,1,1.3.6l0,.08a.93.93,0,0,1-.68,1.3,11.36,11.36,0,0,1-2.57.38A11.24,11.24,0,0,1,240,57.18a1,1,0,0,1-.16,1.41l-.11.08a1,1,0,0,1-1.44-.27A12,12,0,0,1,237.07,56a13.76,13.76,0,0,1-1.22,2.36,1,1,0,0,1-1.44.27l-.11-.08a1,1,0,0,1-.16-1.41,12.28,12.28,0,0,1,1.93-1.85A10.18,10.18,0,0,1,233.49,55Z"
            id="elvs6gdvd0nbi"
            class="animable"
            style="transform-origin: 237.059px 54.6968px"
          ></path>
          <path
            d="M243.63,55a.93.93,0,0,1-.7-1.27l0-.14a.94.94,0,0,1,1.3-.57,11.88,11.88,0,0,1,2.31,1.22,11,11,0,0,1-.44-2.66,1,1,0,0,1,1-1.05h.13a.94.94,0,0,1,1,1.05,11.45,11.45,0,0,1-.43,2.66,12.42,12.42,0,0,1,2.3-1.22.94.94,0,0,1,1.3.6l0,.08a.93.93,0,0,1-.68,1.3,11.36,11.36,0,0,1-2.57.38,11.24,11.24,0,0,1,1.92,1.82,1,1,0,0,1-.16,1.41l-.11.08a1,1,0,0,1-1.44-.27A12,12,0,0,1,247.21,56,13.76,13.76,0,0,1,246,58.4a1,1,0,0,1-1.44.27l-.11-.08a1,1,0,0,1-.16-1.41,12.28,12.28,0,0,1,1.93-1.85A10.18,10.18,0,0,1,243.63,55Z"
            id="el18c7jl4a3x6"
            class="animable"
            style="transform-origin: 247.172px 54.7068px"
          ></path>
          <path
            d="M253.77,55a.93.93,0,0,1-.7-1.27l.05-.14a.94.94,0,0,1,1.3-.57,11.88,11.88,0,0,1,2.31,1.22,11,11,0,0,1-.44-2.66.94.94,0,0,1,1-1.05h.13a.94.94,0,0,1,1,1.05,11.45,11.45,0,0,1-.43,2.66,12.42,12.42,0,0,1,2.3-1.22.94.94,0,0,1,1.3.6l0,.08a.93.93,0,0,1-.68,1.3,11.36,11.36,0,0,1-2.57.38,11.24,11.24,0,0,1,1.92,1.82,1,1,0,0,1-.16,1.41l-.11.08a1,1,0,0,1-1.44-.27A12,12,0,0,1,257.35,56a13.76,13.76,0,0,1-1.22,2.36,1,1,0,0,1-1.44.27l-.11-.08a1,1,0,0,1-.16-1.41,12.28,12.28,0,0,1,1.93-1.85A10.18,10.18,0,0,1,253.77,55Z"
            id="elvmyv2jc0mu"
            class="animable"
            style="transform-origin: 257.337px 54.7068px"
          ></path>
        </g>
      </g>
      <g id="eldvwa7z449zc">
        <g
          style="opacity: 0.2; transform-origin: 306.726px 371.299px"
          class="animable"
        >
          <path
            d="M267.67,371.58a.94.94,0,0,1-.71-1.27l.05-.14a1,1,0,0,1,1.31-.57,11.83,11.83,0,0,1,2.3,1.22,11.46,11.46,0,0,1-.43-2.65.94.94,0,0,1,1-1.06h.14a1,1,0,0,1,1,1.06,11,11,0,0,1-.44,2.65,12.47,12.47,0,0,1,2.31-1.22.94.94,0,0,1,1.3.6l0,.08a.92.92,0,0,1-.68,1.3,10.91,10.91,0,0,1-2.58.38,11.75,11.75,0,0,1,1.93,1.82,1,1,0,0,1-.17,1.41l-.1.08a1,1,0,0,1-1.44-.27,11.7,11.7,0,0,1-1.19-2.36A13.35,13.35,0,0,1,270,375a.94.94,0,0,1-1.43.27l-.11-.08a.94.94,0,0,1-.16-1.41,12.16,12.16,0,0,1,1.92-1.84A10.07,10.07,0,0,1,267.67,371.58Z"
            id="elwdwdwn4gqq"
            class="animable"
            style="transform-origin: 271.241px 371.299px"
          ></path>
          <path
            d="M277.81,371.58a.94.94,0,0,1-.71-1.27l.06-.14a.94.94,0,0,1,1.3-.57,11.83,11.83,0,0,1,2.3,1.22,11.46,11.46,0,0,1-.43-2.65.94.94,0,0,1,1-1.06h.14a1,1,0,0,1,1,1.06,11,11,0,0,1-.44,2.65,12.47,12.47,0,0,1,2.31-1.22.94.94,0,0,1,1.3.6l0,.08a.92.92,0,0,1-.68,1.3,10.91,10.91,0,0,1-2.58.38,11.75,11.75,0,0,1,1.93,1.82,1,1,0,0,1-.17,1.41l-.1.08a1,1,0,0,1-1.44-.27,11.7,11.7,0,0,1-1.19-2.36,13.39,13.39,0,0,1-1.22,2.36,1,1,0,0,1-1.44.27l-.11-.08a.94.94,0,0,1-.16-1.41,12.16,12.16,0,0,1,1.92-1.84A10.07,10.07,0,0,1,277.81,371.58Z"
            id="el63htz9ur3af"
            class="animable"
            style="transform-origin: 281.381px 371.287px"
          ></path>
          <path
            d="M288,371.58a.94.94,0,0,1-.71-1.27l.06-.14a.94.94,0,0,1,1.3-.57,11.83,11.83,0,0,1,2.3,1.22,11.46,11.46,0,0,1-.43-2.65.94.94,0,0,1,1-1.06h.14a1,1,0,0,1,1,1.06,11,11,0,0,1-.44,2.65,12.47,12.47,0,0,1,2.31-1.22.94.94,0,0,1,1.3.6l0,.08a.92.92,0,0,1-.68,1.3,10.91,10.91,0,0,1-2.58.38,11.75,11.75,0,0,1,1.93,1.82,1,1,0,0,1-.17,1.41l-.1.08a1,1,0,0,1-1.44-.27,11.7,11.7,0,0,1-1.19-2.36,13.39,13.39,0,0,1-1.22,2.36,1,1,0,0,1-1.44.27l-.11-.08a1,1,0,0,1-.16-1.41,12.16,12.16,0,0,1,1.92-1.84A10.14,10.14,0,0,1,288,371.58Z"
            id="el7z41o78c1w4"
            class="animable"
            style="transform-origin: 291.571px 371.287px"
          ></path>
          <path
            d="M298.09,371.58a.94.94,0,0,1-.71-1.27l.06-.14a.94.94,0,0,1,1.3-.57,11.83,11.83,0,0,1,2.3,1.22,11.46,11.46,0,0,1-.43-2.65.94.94,0,0,1,1-1.06h.14a1,1,0,0,1,1,1.06,11,11,0,0,1-.44,2.65,12.47,12.47,0,0,1,2.31-1.22.94.94,0,0,1,1.3.6l0,.08a.92.92,0,0,1-.68,1.3,10.83,10.83,0,0,1-2.58.38,11.75,11.75,0,0,1,1.93,1.82,1,1,0,0,1-.16,1.41l-.11.08a1,1,0,0,1-1.44-.27,11.7,11.7,0,0,1-1.19-2.36,13.39,13.39,0,0,1-1.22,2.36,1,1,0,0,1-1.44.27l-.11-.08a1,1,0,0,1-.16-1.41,12.62,12.62,0,0,1,1.92-1.84A10.14,10.14,0,0,1,298.09,371.58Z"
            id="elk8e9u8alh1"
            class="animable"
            style="transform-origin: 301.661px 371.287px"
          ></path>
          <path
            d="M308.23,371.58a.94.94,0,0,1-.71-1.27l.06-.14a.94.94,0,0,1,1.3-.57,11.83,11.83,0,0,1,2.3,1.22,11.46,11.46,0,0,1-.43-2.65,1,1,0,0,1,1-1.06h.13a1,1,0,0,1,1,1.06,11,11,0,0,1-.44,2.65,12.47,12.47,0,0,1,2.31-1.22.94.94,0,0,1,1.3.6l0,.08a.92.92,0,0,1-.68,1.3,10.83,10.83,0,0,1-2.58.38,11.75,11.75,0,0,1,1.93,1.82,1,1,0,0,1-.16,1.41l-.11.08A1,1,0,0,1,313,375a11.7,11.7,0,0,1-1.19-2.36,13.39,13.39,0,0,1-1.22,2.36,1,1,0,0,1-1.44.27l-.11-.08a1,1,0,0,1-.16-1.41,12.62,12.62,0,0,1,1.92-1.84A10.14,10.14,0,0,1,308.23,371.58Z"
            id="eli6sjdd7sptr"
            class="animable"
            style="transform-origin: 311.796px 371.29px"
          ></path>
          <path
            d="M318.37,371.58a.94.94,0,0,1-.71-1.27l.06-.14a.94.94,0,0,1,1.3-.57,11.55,11.55,0,0,1,2.3,1.22,11.46,11.46,0,0,1-.43-2.65,1,1,0,0,1,1-1.06H322a1,1,0,0,1,1,1.06,11,11,0,0,1-.44,2.65,12.47,12.47,0,0,1,2.31-1.22.94.94,0,0,1,1.3.6l0,.08a.92.92,0,0,1-.68,1.3,10.83,10.83,0,0,1-2.58.38,11.75,11.75,0,0,1,1.93,1.82,1,1,0,0,1-.16,1.41l-.11.08a1,1,0,0,1-1.44-.27,11.7,11.7,0,0,1-1.19-2.36,13.39,13.39,0,0,1-1.22,2.36,1,1,0,0,1-1.44.27l-.11-.08a1,1,0,0,1-.16-1.41,13.1,13.1,0,0,1,1.92-1.84A10.14,10.14,0,0,1,318.37,371.58Z"
            id="elpt6mcxkifog"
            class="animable"
            style="transform-origin: 321.926px 371.288px"
          ></path>
          <path
            d="M328.51,371.58a.94.94,0,0,1-.71-1.27l.06-.14a.94.94,0,0,1,1.3-.57,11.55,11.55,0,0,1,2.3,1.22,11.46,11.46,0,0,1-.43-2.65,1,1,0,0,1,1-1.06h.13a1,1,0,0,1,1,1.06,11,11,0,0,1-.44,2.65A12.47,12.47,0,0,1,335,369.6a.94.94,0,0,1,1.3.6l0,.08a.92.92,0,0,1-.68,1.3,10.83,10.83,0,0,1-2.58.38,11.75,11.75,0,0,1,1.93,1.82,1,1,0,0,1-.16,1.41l-.11.08a1,1,0,0,1-1.44-.27,11.7,11.7,0,0,1-1.19-2.36,13.39,13.39,0,0,1-1.22,2.36,1,1,0,0,1-1.44.27l-.11-.08a1,1,0,0,1-.16-1.41,12.72,12.72,0,0,1,1.93-1.84A10.16,10.16,0,0,1,328.51,371.58Z"
            id="elkg5lsgri8b"
            class="animable"
            style="transform-origin: 332.061px 371.288px"
          ></path>
          <path
            d="M338.65,371.58a.94.94,0,0,1-.71-1.27l.06-.14a.94.94,0,0,1,1.3-.57,11.55,11.55,0,0,1,2.3,1.22,11.46,11.46,0,0,1-.43-2.65,1,1,0,0,1,1-1.06h.13a1,1,0,0,1,1,1.06,11,11,0,0,1-.44,2.65,12.47,12.47,0,0,1,2.31-1.22.94.94,0,0,1,1.3.6l0,.08a.92.92,0,0,1-.68,1.3,10.83,10.83,0,0,1-2.58.38,11.75,11.75,0,0,1,1.93,1.82,1,1,0,0,1-.16,1.41l-.11.08a1,1,0,0,1-1.44-.27,11.7,11.7,0,0,1-1.19-2.36A13.39,13.39,0,0,1,341,375a1,1,0,0,1-1.44.27l-.11-.08a1,1,0,0,1-.16-1.41,12.72,12.72,0,0,1,1.93-1.84A10.16,10.16,0,0,1,338.65,371.58Z"
            id="el86kn0b8jctt"
            class="animable"
            style="transform-origin: 342.216px 371.288px"
          ></path>
        </g>
      </g>
      <g id="elsp6g3vwbdls">
        <g
          style="opacity: 0.2; transform-origin: 437.375px 323.579px"
          class="animable"
        >
          <path
            d="M429.4,322.38v-7.12a8.14,8.14,0,0,1,16.27,0v7.07h3.24v-7.07a11.38,11.38,0,0,0-22.75,0v7.12Z"
            style="
              fill: none;
              stroke: rgb(0, 0, 0);
              stroke-miterlimit: 10;
              transform-origin: 437.535px 313.299px;
            "
            id="elou9ef6zfoq7"
            class="animable"
          ></path>
          <rect
            x="424.96"
            y="322.4"
            width="24.83"
            height="20.54"
            style="
              fill: none;
              stroke: rgb(0, 0, 0);
              stroke-miterlimit: 10;
              transform-origin: 437.375px 332.67px;
            "
            id="elon5h52cvh7p"
            class="animable"
          ></rect>
          <path
            d="M440,330.93a2.71,2.71,0,0,0-5.41,0,2.63,2.63,0,0,0,1.42,2.33l-.46,4.66H439l-.45-4.66A2.65,2.65,0,0,0,440,330.93Z"
            style="
              fill: none;
              stroke: rgb(0, 0, 0);
              stroke-miterlimit: 10;
              transform-origin: 437.295px 333.152px;
            "
            id="el0ozpcp1i3du"
            class="animable"
          ></path>
        </g>
      </g>
      <g id="ell8gw3dnjxee">
        <g
          style="opacity: 0.2; transform-origin: 85.585px 100.179px"
          class="animable"
        >
          <path
            d="M77.61,99V91.86a8.14,8.14,0,0,1,16.27,0v7.07h3.24V91.86a11.38,11.38,0,0,0-22.75,0V99Z"
            style="
              fill: none;
              stroke: rgb(0, 0, 0);
              stroke-miterlimit: 10;
              transform-origin: 85.745px 89.9087px;
            "
            id="el7tn0ep9vnjr"
            class="animable"
          ></path>
          <rect
            x="73.17"
            y="99"
            width="24.83"
            height="20.54"
            style="
              fill: none;
              stroke: rgb(0, 0, 0);
              stroke-miterlimit: 10;
              transform-origin: 85.585px 109.27px;
            "
            id="elka00wfaop6s"
            class="animable"
          ></rect>
          <path
            d="M88.17,107.53a2.71,2.71,0,0,0-5.41,0,2.63,2.63,0,0,0,1.42,2.33l-.46,4.67H87.2l-.45-4.67A2.65,2.65,0,0,0,88.17,107.53Z"
            style="
              fill: none;
              stroke: rgb(0, 0, 0);
              stroke-miterlimit: 10;
              transform-origin: 85.465px 109.757px;
            "
            id="el1nm9hau4dx5"
            class="animable"
          ></path>
        </g>
      </g>
      <g id="elwplitgmivh">
        <g
          style="opacity: 0.2; transform-origin: 392.855px 45.85px"
          class="animable"
        >
          <path
            d="M387.62,45.17V40.5a5.34,5.34,0,0,1,10.67,0v4.64h2.13V40.5a7.46,7.46,0,0,0-14.92,0v4.67Z"
            style="
              fill: none;
              stroke: rgb(0, 0, 0);
              stroke-miterlimit: 10;
              transform-origin: 392.96px 39.105px;
            "
            id="elfkl7jfqrej4"
            class="animable"
          ></path>
          <rect
            x="384.71"
            y="45.18"
            width="16.29"
            height="13.48"
            style="
              fill: none;
              stroke: rgb(0, 0, 0);
              stroke-miterlimit: 10;
              transform-origin: 392.855px 51.92px;
            "
            id="elrh09bb5x7ef"
            class="animable"
          ></rect>
          <path
            d="M394.55,50.78a1.78,1.78,0,0,0-3.55,0,1.72,1.72,0,0,0,.93,1.52l-.3,3.07h2.28l-.29-3.07A1.72,1.72,0,0,0,394.55,50.78Z"
            style="
              fill: none;
              stroke: rgb(0, 0, 0);
              stroke-miterlimit: 10;
              transform-origin: 392.775px 52.2517px;
            "
            id="elojs9981u9oo"
            class="animable"
          ></path>
        </g>
      </g>
    </g>
    <g
      id="freepik--window-2--inject-110"
      class="animable"
      style="transform-origin: 276.175px 163.395px"
    >
      <rect
        x="117.17"
        y="86.62"
        width="318.28"
        height="169.04"
        style="
          fill: rgb(255, 255, 255);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 276.31px 171.14px;
        "
        id="elqq46x27ldch"
        class="animable"
      ></rect>
      <rect
        x="172.63"
        y="86.62"
        width="262.81"
        height="15.85"
        style="
          fill: rgb(217, 217, 217);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 304.035px 94.545px;
        "
        id="elvh61cfl0ltm"
        class="animable"
      ></rect>
      <rect
        x="228.37"
        y="86.62"
        width="207.07"
        height="15.85"
        style="
          fill: rgb(168, 168, 168);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 331.905px 94.545px;
        "
        id="el38ot9e0ueuk"
        class="animable"
      ></rect>
      <rect
        x="116.9"
        y="71.13"
        width="318.54"
        height="15.85"
        style="
          fill: rgb(38, 50, 56);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 276.17px 79.055px;
        "
        id="elk8kcf4aeou9"
        class="animable"
      ></rect>
      <path
        d="M338.05,82.19H130.94a3.43,3.43,0,0,1-3.43-3.43h0a3.42,3.42,0,0,1,3.43-3.42H338.05a3.43,3.43,0,0,1,3.43,3.42h0A3.44,3.44,0,0,1,338.05,82.19Z"
        style="
          fill: rgb(168, 168, 168);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 234.495px 78.765px;
        "
        id="el1ykjgz22sif"
        class="animable"
      ></path>
      <circle
        cx="400.57"
        cy="79.22"
        r="2.88"
        style="
          fill: rgb(255, 255, 255);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 400.57px 79.22px;
        "
        id="eleoz5lppcck8"
        class="animable"
      ></circle>
      <path
        d="M415.46,79.22a2.89,2.89,0,1,0-2.88,2.88A2.88,2.88,0,0,0,415.46,79.22Z"
        style="
          fill: rgb(255, 255, 255);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 412.57px 79.21px;
        "
        id="elqih7cuntdr"
        class="animable"
      ></path>
      <circle
        cx="424.58"
        cy="79.22"
        r="2.88"
        style="
          fill: rgb(255, 255, 255);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 424.58px 79.22px;
        "
        id="eludb3x7mkzed"
        class="animable"
      ></circle>
      <polygon
        points="137.73 154.3 173.12 154.3 173.12 130.28 152.58 130.28 150.49 124.42 137.73 124.42 137.73 154.3"
        style="
          fill: rgb(217, 217, 217);
          stroke: rgb(181, 181, 181);
          stroke-miterlimit: 10;
          transform-origin: 155.425px 139.36px;
        "
        id="el98kmd4h45eo"
        class="animable"
      ></polygon>
      <polygon
        points="137.73 200.69 173.12 200.69 173.12 176.67 152.58 176.67 150.49 170.81 137.73 170.81 137.73 200.69"
        style="
          fill: rgb(217, 217, 217);
          stroke: rgb(181, 181, 181);
          stroke-miterlimit: 10;
          transform-origin: 155.425px 185.75px;
        "
        id="el61w3uh7cfc2"
        class="animable"
      ></polygon>
      <polygon
        points="137.73 247.08 173.12 247.08 173.12 223.06 152.58 223.06 150.49 217.19 137.73 217.19 137.73 247.08"
        style="
          fill: rgb(217, 217, 217);
          stroke: rgb(181, 181, 181);
          stroke-miterlimit: 10;
          transform-origin: 155.425px 232.135px;
        "
        id="el8v4tcqk9kvu"
        class="animable"
      ></polygon>
      <polygon
        points="186.9 154.3 222.28 154.3 222.28 130.28 201.74 130.28 199.66 124.42 186.9 124.42 186.9 154.3"
        style="
          fill: rgb(217, 217, 217);
          stroke: rgb(181, 181, 181);
          stroke-miterlimit: 10;
          transform-origin: 204.59px 139.36px;
        "
        id="elnsrhawwk21"
        class="animable"
      ></polygon>
      <polygon
        points="186.9 200.69 222.28 200.69 222.28 176.67 201.74 176.67 199.66 170.81 186.9 170.81 186.9 200.69"
        style="
          fill: rgb(217, 217, 217);
          stroke: rgb(181, 181, 181);
          stroke-miterlimit: 10;
          transform-origin: 204.59px 185.75px;
        "
        id="elfxncp6d0tm5"
        class="animable"
      ></polygon>
      <polygon
        points="186.9 247.08 222.28 247.08 222.28 223.06 201.74 223.06 199.66 217.19 186.9 217.19 186.9 247.08"
        style="
          fill: rgb(217, 217, 217);
          stroke: rgb(181, 181, 181);
          stroke-miterlimit: 10;
          transform-origin: 204.59px 232.135px;
        "
        id="elv0jnagepa5"
        class="animable"
      ></polygon>
      <polygon
        points="236.06 154.3 271.44 154.3 271.44 130.28 250.91 130.28 248.82 124.42 236.06 124.42 236.06 154.3"
        style="
          fill: rgb(217, 217, 217);
          stroke: rgb(181, 181, 181);
          stroke-miterlimit: 10;
          transform-origin: 253.75px 139.36px;
        "
        id="el63223v02du6"
        class="animable"
      ></polygon>
      <polygon
        points="236.06 200.69 271.44 200.69 271.44 176.67 250.91 176.67 248.82 170.81 236.06 170.81 236.06 200.69"
        style="
          fill: rgb(217, 217, 217);
          stroke: rgb(181, 181, 181);
          stroke-miterlimit: 10;
          transform-origin: 253.75px 185.75px;
        "
        id="elozh31j3k4of"
        class="animable"
      ></polygon>
      <polygon
        points="236.06 247.08 271.44 247.08 271.44 223.06 250.91 223.06 248.82 217.19 236.06 217.19 236.06 247.08"
        style="
          fill: rgb(217, 217, 217);
          stroke: rgb(181, 181, 181);
          stroke-miterlimit: 10;
          transform-origin: 253.75px 232.135px;
        "
        id="elazskb45d1xt"
        class="animable"
      ></polygon>
      <polygon
        points="285.22 154.3 320.61 154.3 320.61 130.28 300.07 130.28 297.98 124.42 285.22 124.42 285.22 154.3"
        style="
          fill: rgb(217, 217, 217);
          stroke: rgb(181, 181, 181);
          stroke-miterlimit: 10;
          transform-origin: 302.915px 139.36px;
        "
        id="elw2z7hhijr9"
        class="animable"
      ></polygon>
      <polygon
        points="285.22 200.69 320.61 200.69 320.61 176.67 300.07 176.67 297.98 170.81 285.22 170.81 285.22 200.69"
        style="
          fill: rgb(217, 217, 217);
          stroke: rgb(181, 181, 181);
          stroke-miterlimit: 10;
          transform-origin: 302.915px 185.75px;
        "
        id="elgm9agnsms4q"
        class="animable"
      ></polygon>
      <polygon
        points="285.22 247.08 320.61 247.08 320.61 223.06 300.07 223.06 297.98 217.19 285.22 217.19 285.22 247.08"
        style="
          fill: rgb(217, 217, 217);
          stroke: rgb(181, 181, 181);
          stroke-miterlimit: 10;
          transform-origin: 302.915px 232.135px;
        "
        id="elm4hfgebnip8"
        class="animable"
      ></polygon>
      <polygon
        points="334.39 154.3 369.77 154.3 369.77 130.28 349.23 130.28 347.15 124.42 334.39 124.42 334.39 154.3"
        style="
          fill: rgb(217, 217, 217);
          stroke: rgb(181, 181, 181);
          stroke-miterlimit: 10;
          transform-origin: 352.08px 139.36px;
        "
        id="el1rccrs7u3kl"
        class="animable"
      ></polygon>
      <polygon
        points="334.39 200.69 369.77 200.69 369.77 176.67 349.23 176.67 347.15 170.81 334.39 170.81 334.39 200.69"
        style="
          fill: rgb(217, 217, 217);
          stroke: rgb(181, 181, 181);
          stroke-miterlimit: 10;
          transform-origin: 352.08px 185.75px;
        "
        id="elmdzurbeqd9p"
        class="animable"
      ></polygon>
      <polygon
        points="334.39 247.08 369.77 247.08 369.77 223.06 349.23 223.06 347.15 217.19 334.39 217.19 334.39 247.08"
        style="
          fill: rgb(217, 217, 217);
          stroke: rgb(181, 181, 181);
          stroke-miterlimit: 10;
          transform-origin: 352.08px 232.135px;
        "
        id="elde13yhgxoc"
        class="animable"
      ></polygon>
      <polygon
        points="383.55 154.3 418.94 154.3 418.94 130.28 398.4 130.28 396.31 124.42 383.55 124.42 383.55 154.3"
        style="
          fill: rgb(217, 217, 217);
          stroke: rgb(181, 181, 181);
          stroke-miterlimit: 10;
          transform-origin: 401.245px 139.36px;
        "
        id="elknp1quu9hmb"
        class="animable"
      ></polygon>
      <polygon
        points="383.55 200.69 418.94 200.69 418.94 176.67 398.4 176.67 396.31 170.81 383.55 170.81 383.55 200.69"
        style="
          fill: rgb(217, 217, 217);
          stroke: rgb(181, 181, 181);
          stroke-miterlimit: 10;
          transform-origin: 401.245px 185.75px;
        "
        id="elbg5v4pe77pd"
        class="animable"
      ></polygon>
      <polygon
        points="383.55 247.08 418.94 247.08 418.94 223.06 398.4 223.06 396.31 217.19 383.55 217.19 383.55 247.08"
        style="
          fill: rgb(217, 217, 217);
          stroke: rgb(181, 181, 181);
          stroke-miterlimit: 10;
          transform-origin: 401.245px 232.135px;
        "
        id="eloxlg5y8s1h9"
        class="animable"
      ></polygon>
    </g>
    <g
      id="freepik--window-1--inject-110"
      class="animable"
      style="transform-origin: 273.71px 173.385px"
    >
      <g id="elxpepop6m2zh">
        <rect
          x="208.36"
          y="120.01"
          width="127.5"
          height="112.5"
          style="opacity: 0.3; transform-origin: 272.11px 176.26px"
          class="animable"
        ></rect>
      </g>
      <rect
        x="211.56"
        y="114.26"
        width="127.5"
        height="112.5"
        style="
          fill: rgb(255, 255, 255);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 275.31px 170.51px;
        "
        id="elizvj0e5lc7e"
        class="animable"
      ></rect>
      <rect
        x="211.56"
        y="114.26"
        width="127.5"
        height="8.04"
        style="
          fill: rgb(38, 50, 56);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 275.31px 118.28px;
        "
        id="el1ncwudba3xyh"
        class="animable"
      ></rect>
      <path
        d="M267.34,162.44V150.58a8.13,8.13,0,0,1,16.26,0v6.93h3.24v-6.93a11.37,11.37,0,0,0-22.74,0v11.86Z"
        style="
          fill: rgb(255, 255, 255);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 275.47px 150.833px;
        "
        id="el61hmu9ztx9x"
        class="animable"
      ></path>
      <rect
        x="262.89"
        y="161.28"
        width="24.83"
        height="20.54"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 275.305px 171.55px;
        "
        id="elvnq4bi4le3m"
        class="animable"
      ></rect>
      <line
        x1="291.26"
        y1="153.75"
        x2="292.87"
        y2="150.07"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 292.065px 151.91px;
        "
        id="elmz25f27h6u"
        class="animable"
      ></line>
      <line
        x1="291.49"
        y1="158.11"
        x2="297.47"
        y2="158.11"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 294.48px 158.11px;
        "
        id="elsl4bsuo602s"
        class="animable"
      ></line>
      <line
        x1="290.8"
        y1="162.25"
        x2="294.02"
        y2="165.7"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 292.41px 163.975px;
        "
        id="elp27ykm5d258"
        class="animable"
      ></line>
      <path
        d="M277.89,169.81a2.71,2.71,0,1,0-4,2.33l-.45,4.67h3.47l-.45-4.67A2.63,2.63,0,0,0,277.89,169.81Z"
        style="
          fill: rgb(38, 50, 56);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 275.181px 171.928px;
        "
        id="elkrp3wwm90qj"
        class="animable"
      ></path>
      <rect
        x="226.85"
        y="190.19"
        width="96.91"
        height="17.49"
        rx="4"
        style="
          fill: rgb(255, 255, 255);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 275.305px 198.935px;
        "
        id="elok8208vw96"
        class="animable"
      ></rect>
      <path
        d="M236.26,199.49a.94.94,0,0,1-.7-1.28l.05-.13a.94.94,0,0,1,1.3-.57,11.1,11.1,0,0,1,2.31,1.22,11,11,0,0,1-.44-2.66,1,1,0,0,1,1-1.06h.14a1,1,0,0,1,1,1.06,11.45,11.45,0,0,1-.43,2.66,12.14,12.14,0,0,1,2.3-1.22.93.93,0,0,1,1.3.59l0,.09a.93.93,0,0,1-.68,1.3,11.36,11.36,0,0,1-2.57.38,10.86,10.86,0,0,1,1.92,1.81,1,1,0,0,1-.16,1.41l-.11.09a.94.94,0,0,1-1.44-.28,12.13,12.13,0,0,1-1.19-2.35,13.26,13.26,0,0,1-1.22,2.35.94.94,0,0,1-1.44.28l-.1-.09a1,1,0,0,1-.17-1.41,12.72,12.72,0,0,1,1.93-1.84A10.25,10.25,0,0,1,236.26,199.49Z"
        style="fill: rgb(38, 50, 56); transform-origin: 239.832px 199.206px"
        id="els955s26knb"
        class="animable"
      ></path>
      <path
        d="M246.4,199.49a.94.94,0,0,1-.7-1.28l.05-.13a.94.94,0,0,1,1.3-.57,11.1,11.1,0,0,1,2.31,1.22,11,11,0,0,1-.44-2.66,1,1,0,0,1,1-1.06H250a1,1,0,0,1,1,1.06,11.45,11.45,0,0,1-.43,2.66,12.14,12.14,0,0,1,2.3-1.22,1,1,0,0,1,1.31.59l0,.09a.93.93,0,0,1-.68,1.3,11.36,11.36,0,0,1-2.57.38,10.86,10.86,0,0,1,1.92,1.81,1,1,0,0,1-.16,1.41l-.11.09a.93.93,0,0,1-1.43-.28,11.57,11.57,0,0,1-1.2-2.35,13.26,13.26,0,0,1-1.22,2.35.94.94,0,0,1-1.44.28l-.1-.09a1,1,0,0,1-.17-1.41,12.72,12.72,0,0,1,1.93-1.84A10.25,10.25,0,0,1,246.4,199.49Z"
        style="fill: rgb(38, 50, 56); transform-origin: 249.947px 199.206px"
        id="elk1lhylf45oa"
        class="animable"
      ></path>
      <path
        d="M256.54,199.49a1,1,0,0,1-.7-1.28l0-.13a.94.94,0,0,1,1.3-.57,11.1,11.1,0,0,1,2.31,1.22,11,11,0,0,1-.43-2.66,1,1,0,0,1,1-1.06h.14a1,1,0,0,1,1,1.06,11.45,11.45,0,0,1-.43,2.66,12.14,12.14,0,0,1,2.3-1.22,1,1,0,0,1,1.31.59l0,.09a.93.93,0,0,1-.67,1.3,11.54,11.54,0,0,1-2.58.38,10.86,10.86,0,0,1,1.92,1.81,1,1,0,0,1-.16,1.41l-.11.09a.93.93,0,0,1-1.43-.28,12.1,12.1,0,0,1-1.2-2.35,13.26,13.26,0,0,1-1.22,2.35.93.93,0,0,1-1.43.28l-.11-.09a1,1,0,0,1-.17-1.41,12.72,12.72,0,0,1,1.93-1.84A10.25,10.25,0,0,1,256.54,199.49Z"
        style="fill: rgb(38, 50, 56); transform-origin: 260.107px 199.206px"
        id="elh39fe8toede"
        class="animable"
      ></path>
      <path
        d="M266.68,199.49a1,1,0,0,1-.7-1.28l0-.13a.94.94,0,0,1,1.3-.57,11.1,11.1,0,0,1,2.31,1.22,11.45,11.45,0,0,1-.43-2.66,1,1,0,0,1,1-1.06h.14a1,1,0,0,1,1,1.06,11.45,11.45,0,0,1-.43,2.66,12.14,12.14,0,0,1,2.3-1.22,1,1,0,0,1,1.31.59l0,.09a.93.93,0,0,1-.67,1.3,11.54,11.54,0,0,1-2.58.38,10.86,10.86,0,0,1,1.92,1.81,1,1,0,0,1-.16,1.41l-.11.09a.93.93,0,0,1-1.43-.28,12.1,12.1,0,0,1-1.2-2.35A13.26,13.26,0,0,1,269,202.9a.93.93,0,0,1-1.43.28l-.11-.09a1,1,0,0,1-.17-1.41,12.72,12.72,0,0,1,1.93-1.84A10.25,10.25,0,0,1,266.68,199.49Z"
        style="fill: rgb(38, 50, 56); transform-origin: 270.247px 199.206px"
        id="elac1sl1o87un"
        class="animable"
      ></path>
      <path
        d="M276.82,199.49a1,1,0,0,1-.7-1.28l.05-.13a1,1,0,0,1,1.31-.57,11.3,11.3,0,0,1,2.3,1.22,11.45,11.45,0,0,1-.43-2.66,1,1,0,0,1,1-1.06h.14a1,1,0,0,1,1,1.06,11.45,11.45,0,0,1-.43,2.66,12.14,12.14,0,0,1,2.3-1.22,1,1,0,0,1,1.31.59l0,.09a.93.93,0,0,1-.67,1.3,11.54,11.54,0,0,1-2.58.38,10.86,10.86,0,0,1,1.92,1.81,1,1,0,0,1-.16,1.41l-.11.09a.93.93,0,0,1-1.43-.28,12.1,12.1,0,0,1-1.2-2.35,13.26,13.26,0,0,1-1.22,2.35.93.93,0,0,1-1.43.28l-.11-.09a.94.94,0,0,1-.16-1.41,12.16,12.16,0,0,1,1.92-1.84A10.25,10.25,0,0,1,276.82,199.49Z"
        style="fill: rgb(38, 50, 56); transform-origin: 280.412px 199.206px"
        id="elip1y5v8mkv"
        class="animable"
      ></path>
      <path
        d="M287,199.49a1,1,0,0,1-.7-1.28l.05-.13a1,1,0,0,1,1.31-.57,11.3,11.3,0,0,1,2.3,1.22,11.45,11.45,0,0,1-.43-2.66,1,1,0,0,1,1-1.06h.14a1,1,0,0,1,1,1.06,11.45,11.45,0,0,1-.43,2.66,11.9,11.9,0,0,1,2.31-1.22.94.94,0,0,1,1.3.59l0,.09a.93.93,0,0,1-.67,1.3,11.45,11.45,0,0,1-2.58.38,11.31,11.31,0,0,1,1.93,1.81,1,1,0,0,1-.17,1.41l-.11.09a.93.93,0,0,1-1.43-.28,12.1,12.1,0,0,1-1.2-2.35,13.26,13.26,0,0,1-1.22,2.35.93.93,0,0,1-1.43.28l-.11-.09a.94.94,0,0,1-.16-1.41,12.16,12.16,0,0,1,1.92-1.84A10.25,10.25,0,0,1,287,199.49Z"
        style="fill: rgb(38, 50, 56); transform-origin: 290.592px 199.206px"
        id="elno8ntnprn1"
        class="animable"
      ></path>
      <path
        d="M297.11,199.49a1,1,0,0,1-.71-1.28l.05-.13a1,1,0,0,1,1.31-.57,11.3,11.3,0,0,1,2.3,1.22,11.45,11.45,0,0,1-.43-2.66,1,1,0,0,1,1-1.06h.14a1,1,0,0,1,1,1.06,11,11,0,0,1-.44,2.66,11.9,11.9,0,0,1,2.31-1.22.94.94,0,0,1,1.3.59l0,.09a.93.93,0,0,1-.68,1.3,11.45,11.45,0,0,1-2.58.38,11.31,11.31,0,0,1,1.93,1.81,1,1,0,0,1-.17,1.41l-.1.09a.94.94,0,0,1-1.44-.28,11.59,11.59,0,0,1-1.19-2.35,13.22,13.22,0,0,1-1.23,2.35.93.93,0,0,1-1.43.28l-.11-.09a.94.94,0,0,1-.16-1.41,12.16,12.16,0,0,1,1.92-1.84A10.1,10.1,0,0,1,297.11,199.49Z"
        style="fill: rgb(38, 50, 56); transform-origin: 300.687px 199.206px"
        id="el1gi36ahb7rk"
        class="animable"
      ></path>
      <path
        d="M307.25,199.49a1,1,0,0,1-.71-1.28l0-.13a1,1,0,0,1,1.31-.57,11.3,11.3,0,0,1,2.3,1.22,11.45,11.45,0,0,1-.43-2.66,1,1,0,0,1,1-1.06h.14a1,1,0,0,1,1,1.06,11,11,0,0,1-.44,2.66,11.9,11.9,0,0,1,2.31-1.22.94.94,0,0,1,1.3.59l0,.09a.93.93,0,0,1-.68,1.3,11.45,11.45,0,0,1-2.58.38,11.31,11.31,0,0,1,1.93,1.81,1,1,0,0,1-.17,1.41l-.11.09a.93.93,0,0,1-1.43-.28,11.59,11.59,0,0,1-1.19-2.35,13.22,13.22,0,0,1-1.23,2.35.93.93,0,0,1-1.43.28l-.11-.09a.94.94,0,0,1-.16-1.41,12.16,12.16,0,0,1,1.92-1.84A10.1,10.1,0,0,1,307.25,199.49Z"
        style="fill: rgb(38, 50, 56); transform-origin: 310.802px 199.206px"
        id="el95ij1dqc44"
        class="animable"
      ></path>
    </g>
    <g
      id="freepik--Character--inject-110"
      class="animable"
      style="transform-origin: 194.281px 334.766px"
    >
      <path
        d="M148.35,454.5h3.45a.89.89,0,0,0,.89-.84l2.74-28.54h-10.7l2.73,28.54A.9.9,0,0,0,148.35,454.5Z"
        style="
          fill: rgb(255, 255, 255);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 150.08px 439.81px;
        "
        id="elkgr0asdv9l"
        class="animable"
      ></path>
      <path
        d="M115,454.5h3.45a.9.9,0,0,0,.89-.84l2.73-28.54h-10.7l2.74,28.54A.89.89,0,0,0,115,454.5Z"
        style="
          fill: rgb(255, 255, 255);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 116.72px 439.81px;
        "
        id="elb7h6hy06r3h"
        class="animable"
      ></path>
      <path
        d="M169.35,454.5h3.45a.89.89,0,0,0,.89-.84l2.74-28.54h-10.7l2.73,28.54A.9.9,0,0,0,169.35,454.5Z"
        style="
          fill: rgb(255, 255, 255);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 171.08px 439.81px;
        "
        id="elwn0vlx2gll"
        class="animable"
      ></path>
      <path
        d="M204,454.5h3.45a.9.9,0,0,0,.89-.84l2.73-28.54h-10.7l2.74,28.54A.89.89,0,0,0,204,454.5Z"
        style="
          fill: rgb(255, 255, 255);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 205.72px 439.81px;
        "
        id="elcmow187m89"
        class="animable"
      ></path>
      <path
        d="M153.56,426.29h68.52s7-23.4,12.46-46,11.68-51,5.06-56.85-24.14,4.29-37.38,7-16-.39-24.91-9.73-16.74-24.53-31.93-34.26-26.47-11.29-37-4.67a36.27,36.27,0,0,0-11.3,24.13c-.77,15.19,3.51,34.27,9,44.78S153.56,426.29,153.56,426.29Z"
        style="
          fill: rgb(255, 255, 255);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 169.725px 352.026px;
        "
        id="elp5spmrog74"
        class="animable"
      ></path>
      <path
        d="M143.93,383.45h74s13.33-13.33,15.33-45.33"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 188.595px 360.785px;
        "
        id="elbky1t13ay0q"
        class="animable"
      ></path>
      <path
        d="M195.66,254.05l-8.38,5.12s5.82,31.89,4.43,48.65-8.38,32.82-8.38,32.82H205.9s8.38-20,12.11-34-2.79-46.79-2.79-46.79Z"
        style="
          fill: rgb(117, 117, 117);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 201.246px 297.345px;
        "
        id="elec6tfkwc4t"
        class="animable"
      ></path>
      <path
        d="M288.56,339.05s4.81-1.53,6.1-1.52,5.48,3.29,5.48,3.29.17,1.2-.78,1.45-4.2-2-4.2-2l-5.41,1.6Z"
        style="
          fill: rgb(217, 217, 217);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 294.356px 339.91px;
        "
        id="el7n6z9d8p3sb"
        class="animable"
      ></path>
      <path
        d="M289.08,336a40.71,40.71,0,0,1,6.15,1.35c1.15.57,3.45,5.37,3.45,5.37s-.38,1.15-1.34,1-2.88-3.65-2.88-3.65l-5.57-1Z"
        style="
          fill: rgb(217, 217, 217);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 293.785px 339.867px;
        "
        id="el2t18xofkypu"
        class="animable"
      ></path>
      <path
        d="M283.83,334.45l6.34,1.54,4,2.69,2.3,5.56a2.12,2.12,0,0,1-1.73.58c-1-.19-5-4.61-5-4.61L284,336.56l-1.92-2.11Z"
        style="
          fill: rgb(217, 217, 217);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 289.275px 339.643px;
        "
        id="el6g1rss8h1d8"
        class="animable"
      ></path>
      <path
        d="M270,332.73s9.79.38,11.9.38,7.68,4.41,8.64,4.8,5,6.14,5,6.14.19,0-1.15.39-3.46-1-3.46-1l-2.68-3.27-6.53-1.15s4.8,2.5,5.95,2.88,1.92,1.73,1.54,2.5-6.18-1.72-6.18-1.72a5.22,5.22,0,0,1-4.47,0c-2.31-1.15-2.61-1.18-5.05-2.31s-7.34-4-7.34-4Z"
        style="
          fill: rgb(217, 217, 217);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 280.86px 338.639px;
        "
        id="el9w0rp81aaul"
        class="animable"
      ></path>
      <path
        d="M220.1,264.29s3.73.7,4.66,2.79,7,49.59,7,49.59l40,14.62-.77,14s-47.37-5.81-50.63-5.11-11.18,1.63-14.44-.94S215,326,217.26,316.12c5.34-23.33,4.67-39.33,4.67-39.33S215.45,261.5,220.1,264.29Z"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 238.568px 304.62px;
        "
        id="el0l1wgl599nu"
        class="animable"
      ></path>
      <path
        d="M225.23,291.52s-5.82,23.28-8.38,29.57-7.69,16.76-7.69,16.76l-5.58.93s11.64-25.14,13.73-35.15-2.56-35.38-2.79-36.31,1.63-6.75,5.12-5.59"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 214.405px 300.179px;
        "
        id="ele529qq4r3pt"
        class="animable"
      ></path>
      <polygon
        points="214.75 285.01 208 302 200.78 283.84 209.4 277.09 214.75 285.01"
        style="
          fill: rgb(255, 255, 255);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 207.765px 289.545px;
        "
        id="elawcpy6b94w5"
        class="animable"
      ></polygon>
      <polygon
        points="216.38 271.27 218.94 291.06 208 282.68 216.38 271.27"
        style="
          fill: rgb(255, 255, 255);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 213.47px 281.165px;
        "
        id="el80nxd0x52jm"
        class="animable"
      ></polygon>
      <path
        d="M246.88,236.07s-.24,13.14-1.31,15-3.33,4.63-3.43,7.14,2.9,7.93,1.1,9.67a4.41,4.41,0,0,1-4.27.89s-5.85,7-9.12,8.62-12-3-12-3l-8.71,9-16.06-29.1,9.2-6.19s3.55-18.29,14.94-22.14S245.1,231.46,246.88,236.07Z"
        style="
          fill: rgb(217, 217, 217);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 219.98px 254.221px;
        "
        id="el194zvlq4b49"
        class="animable"
      ></path>
      <path
        d="M214.8,252.63s-3.52,7.35-.5,12"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 213.938px 258.63px;
        "
        id="ellowytju7bo"
        class="animable"
      ></path>
      <path
        d="M237.25,254.85c-.44,1-.42,2,.05,2.18s1.21-.43,1.65-1.43.43-2,0-2.18S237.7,253.85,237.25,254.85Z"
        style="fill: rgb(38, 50, 56); transform-origin: 238.105px 255.225px"
        id="elwmymkk0poko"
        class="animable"
      ></path>
      <path
        d="M235.11,269.33s-3.52-.71-3.52-3.52"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 233.35px 267.57px;
        "
        id="elakk94gs07sd"
        class="animable"
      ></path>
      <line
        x1="234.87"
        y1="255.73"
        x2="237.45"
        y2="258.08"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 236.16px 256.905px;
        "
        id="ela5f7by2or7p"
        class="animable"
      ></line>
      <path
        d="M242,251s-.13-3.45-5.72-2"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 239.14px 249.822px;
        "
        id="elvaeyab3v8s"
        class="animable"
      ></path>
      <path
        d="M254.55,226.23c-2.46.27-4.91,2.18-5.73.54s-.27-7.64-4.37-8.18-3.82,1.36-5.18,1.64-3-6-8.18-5.19-8.44,3-8.73,4.08c-8.94-.07-14.44,3.57-18.38,10.87-4.33,8-1.68,18.1-1.68,18.1s-4.08,3.16-.82,4.09,13.32.45,13.32.45l-1.06-.47c-1.07-.47-2.79-4,.73-9.1s5.91-.69,6.22,1.49.49,4,1.77,4.61,2.15-.83,2.48-2.72,4.18-2,7-3.24.15-3.75-2.22-7.61,3.64-4.75,9.22-1.25c3.22,2,4.9,3.1,6.49,3.3l.08.05s2.72,2.18,4.91.27,3.54-2.18,5.45-4.37S257,226,254.55,226.23Z"
        style="
          fill: rgb(38, 50, 56);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 228.578px 233.861px;
        "
        id="elcrjladwk04"
        class="animable"
      ></path>
      <path
        d="M194,252.65l15.37,29.8-9.78,10.24s-8.38-22.58-9.31-28.17-1.63-9.54-1.63-9.54Z"
        style="
          fill: rgb(255, 255, 255);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 199.01px 272.67px;
        "
        id="el7r1gf67o44y"
        class="animable"
      ></path>
      <path
        d="M202.93,336.79s45-2.34,50.67,0,7,8.33,6.33,14.33-4,41.33-5.33,51.33-2,22.34-1,24.67,4,6.33,3,8.67-2.67,3.33-2.67,4.33a6.41,6.41,0,0,0,1.33,2.67s12.34,5.33,16.67,7,4.67,3,3.67,3.66-21.67,0-25.34,0-10.66-2.33-10.66-2.33v1.67h-9v-6.34a54.53,54.53,0,0,1,.33-6s-1.67-4.33-1.33-6,2.33-5.33,3-8.66,0-8-.34-11.67a28.33,28.33,0,0,1,.34-8c.33-2-2-38.33-2-38.33s-26.34,8-29,8.33-24.34,8.33-33.34,6,3.67-30.67,13.67-40.33S202.93,336.79,202.93,336.79Z"
        style="
          fill: rgb(38, 50, 56);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 220.628px 394.705px;
        "
        id="elpbs1556c5n"
        class="animable"
      ></path>
      <path
        d="M184.6,333.45s29.66,2.34,33,5.67,2.33,10,2.33,14.33-4,62-4.33,65.34,3.33,6.66,2,9a15.22,15.22,0,0,0-2,6c0,1,9,8.33,12.66,10.33s14,4.33,15.67,6,1.33,3-1.67,4-28,0-28,0-3.66,0-5-1a9.09,9.09,0,0,0-2.33-1.33L206.6,454h-13a94,94,0,0,1-1.34-10.9,54,54,0,0,1,1-8.33,10.42,10.42,0,0,1-1.66-6c.33-3,2.66-4.34,2.66-6.34s-2-8-1.66-11.66.66-8,.66-8l-3-29.34s-20,11-29.66,10-12.34-2.33-15-7-2.67-24.33,1.66-32S175.6,329.79,184.6,333.45Z"
        style="
          fill: rgb(38, 50, 56);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 194.35px 393.506px;
        "
        id="eln9ggkc9bwxg"
        class="animable"
      ></path>
      <path
        d="M188.68,255s7,17.46,7.68,27.93-.23,34.92-2.79,41.9-3.73,11.41-3.73,11.41-23.27,3.49-26.3,4.65-9.31,10.25-10.48,17.93c-.33,2.21-.57,4.6-.74,6.88a56.89,56.89,0,0,0,1.17,15.56c.19,1,.23,1.76-.06,2.05-.93.93-13.4-4.71-17.59-19.14s-2.1-31.42,1.63-39.34,18.15-55.17,24.44-61.45S188.68,255,188.68,255Z"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 165.053px 319.204px;
        "
        id="el0j7z4092rz1"
        class="animable"
      ></path>
      <path
        d="M267.43,338.84s8.8,1.14,11.07,1.71a7.69,7.69,0,0,1,3.8,2.63l-.85.79-6.36-2.41Z"
        style="
          fill: rgb(217, 217, 217);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 274.865px 341.405px;
        "
        id="eloskvbrtv358"
        class="animable"
      ></path>
      <path
        d="M271,340.24s5.37.33,6,1,3.51,5.48,3.51,5.48l-1.31.88-3.62-3.4L271,341.67Z"
        style="
          fill: rgb(217, 217, 217);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 275.755px 343.92px;
        "
        id="elmbx3choc5qn"
        class="animable"
      ></path>
      <path
        d="M261.16,338.26s10,.85,11.95,2,4.5,6.25,4.5,6.25l-1.42.56-4.82-4.82-11.91-2Z"
        style="
          fill: rgb(217, 217, 217);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 268.535px 342.665px;
        "
        id="elzvbjiurk3j"
        class="animable"
      ></path>
      <path
        d="M260.72,343.31s2.75,1.43,4.17,1.43,5.92-1.54,5.92-1.54a53.92,53.92,0,0,0-5.37-2C264.56,341.12,260.72,343.31,260.72,343.31Z"
        style="
          fill: rgb(217, 217, 217);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 265.765px 342.969px;
        "
        id="elpxol5sogy98"
        class="animable"
      ></path>
      <path
        d="M249,333.72s11.91,2.84,13.33,3.69,8.23,4.25,9.08,4.82,3.41,5.11,3.41,5.11-1.71,2-2.27.56a19.23,19.23,0,0,0-3.12-4l-5.11-1.7a15.09,15.09,0,0,1-6,1.42c-3.12,0-6.48-3.08-6.48-3.08L246.12,342Z"
        style="
          fill: rgb(217, 217, 217);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 260.47px 341.071px;
        "
        id="elp3shotxrx2h"
        class="animable"
      ></path>
      <path
        d="M178.46,267.24s7.07,6.17,7.65,13.13.5,9.76.55,10.93,4.2,31.74,4.2,31.74l59.43,9.63-1.14,13.2-67.72-1.3s-4.62.93-7.12-2.44-17-54.48-17-54.48"
        style="
          fill: rgb(81, 123, 189);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 203.8px 306.555px;
        "
        id="elmni1dijzbv"
        class="animable"
      ></path>
      <path
        d="M179.21,327.69a12.13,12.13,0,0,0-1.79,1.91"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 178.315px 328.645px;
        "
        id="elxsuwalbjay"
        class="animable"
      ></path>
      <path
        d="M190,324.07a18.47,18.47,0,0,0-8.41,2"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 185.795px 325.07px;
        "
        id="elhtphu8yt9bd"
        class="animable"
      ></path>
      <path
        d="M185.89,326.61s-5.73,1.92-6.92,5.94"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 182.43px 329.58px;
        "
        id="els8sp4wigxt"
        class="animable"
      ></path>
      <path
        d="M153.56,426.29s-1.17-41.7-11.68-69-16.74-30.76-21-29.59-10.51,8.56-14.4,3.5-9.73-19.08-7.4-31.54,7-14.79,9.35-17.9-10.13,4.28-15.58,12.45-5.45,28-2.33,48.67,10.9,79.08,13.62,81.41,9.35,2.34,9.35,2.34h35.82Z"
        style="
          fill: rgb(255, 255, 255);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 120.986px 353.832px;
        "
        id="el7yxycefma45"
        class="animable"
      ></path>
    </g>
    <g
      id="freepik--Device--inject-110"
      class="animable"
      style="transform-origin: 308.435px 328.67px"
    >
      <rect
        x="256.31"
        y="344.55"
        width="91.5"
        height="4"
        style="
          fill: rgb(255, 255, 255);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 302.06px 346.55px;
        "
        id="el4scu3pdxan7"
        class="animable"
      ></rect>
      <rect
        x="303.49"
        y="344.55"
        width="44.32"
        height="4"
        style="
          fill: rgb(178, 178, 178);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 325.65px 346.55px;
        "
        id="eloz3ndj0dx2f"
        class="animable"
      ></rect>
      <polygon
        points="360.56 308.79 316.24 308.79 303.49 344.54 347.81 344.54 360.56 308.79"
        style="
          fill: rgb(217, 217, 217);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 332.025px 326.665px;
        "
        id="el1yzkadea4iz"
        class="animable"
      ></polygon>
    </g>
    <g
      id="freepik--Table--inject-110"
      class="animable"
      style="transform-origin: 314.415px 401.625px"
    >
      <g id="elfhymcoikuks">
        <path
          d="M314.07,351h.63a2.59,2.59,0,0,1,2.59,2.59v101a0,0,0,0,1,0,0h-5.8a0,0,0,0,1,0,0v-101A2.59,2.59,0,0,1,314.07,351Z"
          style="
            fill: rgb(82, 82, 82);
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 314.39px 402.795px;
            transform: rotate(180deg);
          "
          class="animable"
        ></path>
      </g>
      <g id="ela9rgbosv5ye">
        <path
          d="M324.62,351h.63a2.59,2.59,0,0,1,2.59,2.59v101a0,0,0,0,1,0,0H322a0,0,0,0,1,0,0v-101A2.59,2.59,0,0,1,324.62,351Z"
          style="
            fill: rgb(82, 82, 82);
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 324.92px 402.795px;
            transform: rotate(180deg);
          "
          class="animable"
        ></path>
      </g>
      <g id="el864t5zfjrhf">
        <path
          d="M242.52,351h.63a2.59,2.59,0,0,1,2.59,2.59v101a0,0,0,0,1,0,0h-5.8a0,0,0,0,1,0,0v-101A2.59,2.59,0,0,1,242.52,351Z"
          style="
            fill: rgb(82, 82, 82);
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 242.84px 402.795px;
            transform: rotate(180deg);
          "
          class="animable"
        ></path>
      </g>
      <g id="elojez355a0e">
        <path
          d="M387,351h.63a2.59,2.59,0,0,1,2.59,2.59v101a0,0,0,0,1,0,0h-5.8a0,0,0,0,1,0,0v-101A2.59,2.59,0,0,1,387,351Z"
          style="
            fill: rgb(82, 82, 82);
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 387.32px 402.795px;
            transform: rotate(-180deg);
          "
          class="animable"
        ></path>
      </g>
      <g id="elgg6izenxw9n">
        <rect
          x="233.17"
          y="348.66"
          width="162.49"
          height="4.72"
          style="
            fill: rgb(255, 255, 255);
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 314.415px 351.02px;
            transform: rotate(180deg);
          "
          class="animable"
        ></rect>
      </g>
      <g id="eluin782rgxs9">
        <rect
          x="233.17"
          y="398.7"
          width="162.49"
          height="4.72"
          style="
            fill: rgb(255, 255, 255);
            stroke: rgb(38, 50, 56);
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 314.415px 401.06px;
            transform: rotate(180deg);
          "
          class="animable"
        ></rect>
      </g>
      <rect
        x="262.15"
        y="393.88"
        width="42.43"
        height="4.73"
        style="
          fill: rgb(255, 255, 255);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 283.365px 396.245px;
        "
        id="elqnrh5n0cnhm"
        class="animable"
      ></rect>
      <rect
        x="285.53"
        y="393.88"
        width="19.06"
        height="4.73"
        style="
          fill: rgb(255, 255, 255);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 295.06px 396.245px;
        "
        id="elggwyam7xr5"
        class="animable"
      ></rect>
      <rect
        x="326.57"
        y="393.88"
        width="42.43"
        height="4.73"
        style="
          fill: rgb(255, 255, 255);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 347.785px 396.245px;
        "
        id="el5zzohw53mln"
        class="animable"
      ></rect>
      <rect
        x="349.95"
        y="393.88"
        width="19.06"
        height="4.73"
        style="
          fill: rgb(255, 255, 255);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 359.48px 396.245px;
        "
        id="elq0bh0vi152"
        class="animable"
      ></rect>
      <rect
        x="258.65"
        y="388.93"
        width="42.43"
        height="4.73"
        style="
          fill: rgb(255, 255, 255);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 279.865px 391.295px;
        "
        id="elqkxb79nrsob"
        class="animable"
      ></rect>
      <rect
        x="282.02"
        y="388.93"
        width="19.06"
        height="4.73"
        style="
          fill: rgb(255, 255, 255);
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 291.55px 391.295px;
        "
        id="el89krfbpu95"
        class="animable"
      ></rect>
    </g>
    <g
      id="freepik--Floor--inject-110"
      class="animable"
      style="transform-origin: 256.3px 454.56px"
    >
      <line
        x1="37.3"
        y1="454.56"
        x2="475.3"
        y2="454.56"
        style="
          fill: none;
          stroke: rgb(38, 50, 56);
          stroke-linecap: round;
          stroke-linejoin: round;
          transform-origin: 256.3px 454.56px;
        "
        id="elztb7bfp1ux"
        class="animable"
      ></line>
    </g>
    <defs>
      <filter id="active" height="200%">
        <feMorphology
          in="SourceAlpha"
          result="DILATED"
          operator="dilate"
          radius="2"
        ></feMorphology>
        <feFlood
          flood-color="#32DFEC"
          flood-opacity="1"
          result="PINK"
        ></feFlood>
        <feComposite
          in="PINK"
          in2="DILATED"
          operator="in"
          result="OUTLINE"
        ></feComposite>
        <feMerge>
          <feMergeNode in="OUTLINE"></feMergeNode>
          <feMergeNode in="SourceGraphic"></feMergeNode>
        </feMerge>
      </filter>
      <filter id="hover" height="200%">
        <feMorphology
          in="SourceAlpha"
          result="DILATED"
          operator="dilate"
          radius="2"
        ></feMorphology>
        <feFlood
          flood-color="#ff0000"
          flood-opacity="0.5"
          result="PINK"
        ></feFlood>
        <feComposite
          in="PINK"
          in2="DILATED"
          operator="in"
          result="OUTLINE"
        ></feComposite>
        <feMerge>
          <feMergeNode in="OUTLINE"></feMergeNode>
          <feMergeNode in="SourceGraphic"></feMergeNode>
        </feMerge>
        <feColorMatrix
          type="matrix"
          values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 "
        ></feColorMatrix>
      </filter>
    </defs>
  </svg>
</template>
<script>
export default {
  name: "ChangeTemporaryPasswordIlustration",
};
</script>
<style>
svg#freepik_stories-secure-login:not(.animated) .animable {
  opacity: 0;
}
svg#freepik_stories-secure-login.animated #freepik--window-2--inject-110 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) slideUp;
  animation-delay: 0s;
}
svg#freepik_stories-secure-login.animated #freepik--window-1--inject-110 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) slideDown;
  animation-delay: 0s;
}
@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: inherit;
  }
}
@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
