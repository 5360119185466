<template>
  <v-form v-model="isValid" :ref="reference" @submit.prevent="">
    <v-text-field
      v-model="newPassword"
      :label="$ml.get(label)"
      :class="customClass"
      :maxLength="max"
      :style="`max-height: 60px; ${customStyle}`"
      :append-icon="hideText ? 'mdi-eye' : 'mdi-eye-off'"
      :type="hideText ? 'password' : 'text'"
      :rules="[...hasRequiredRule, ...rules]"
      :disabled="
        loading || askedGenerateRandomPassword || askedSendLinkToChangePassword
      "
      :outlined="outlined"
      :solo="solo"
      :solo-inverted="soloInverted"
      :filled="filled"
      :background-color="backgroundColor"
      :validate-on-blur="validateOnBlur"
      :error="error"
      :hide-details="hideDetails"
      oncopy="return false"
      onpaste="return false"
      @update:error="hasPasswordError = $event"
      @click:append="hideText = !hideText"
    />
  </v-form>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "PasswordField",

  props: {
    backgroundColor: { type: String, default: "" },
    customClass: { type: String, default: "" },
    customStyle: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    filled: { type: Boolean, default: false },
    label: { type: String, default: "default_password" },
    loading: { type: Boolean, default: false },
    max: { type: [String, Number], default: 5 },
    min: { type: [String, Number], default: 5 },
    outlined: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    reference: { type: String, default: "" },
    rules: { type: Array, default: () => [] },
    solo: { type: Boolean, default: false },
    soloInverted: { type: Boolean, default: false },
    value: { type: String, default: "" },
    validateOnBlur: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    hideDetails: { type: Boolean, default: false },
  },

  data() {
    return {
      hasPasswordError: true,

      isValid: false,

      newPassword: "",

      requiredRule: (e) => !!e || this.$ml.get("required_field"),

      hideText: true,
    };
  },

  computed: {
    ...mapGetters([
      "askedGenerateRandomPassword",
      "askedSendLinkToChangePassword",
    ]),

    hasRequiredRule() {
      return this.required ? [this.requiredRule] : [];
    },
  },

  watch: {
    hasPasswordError: {
      immediate: true,

      handler() {
        this.$emit("error", !this.isValid);
      },
    },

    isValid() {
      this.$emit("valid", this.isValid);
    },

    newPassword() {
      this.$emit("update:password", this.newPassword);
    },

    value() {
      this.reset();
    },
  },

  methods: {
    reset() {
      this.newPassword = this.value;
      this.hasPasswordError = true;

      this.$nextTick(() => {
        if (this.$refs[this.reference]) {
          this.$refs[this.reference].validate();
        }
      });
    },
  },

  beforeMount() {
    this.reset();

    if (this.value) {
      this.hideText = false;
    }
  },
};
</script>
