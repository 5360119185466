<template>
  <div>
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          x-large
          color="accent"
          class="text-none text-body-2 text-md-body-1 font-weight-bold px-8"
          :block="block"
          @click="$emit('started')"
          v-bind="attrs"
          v-on="on"
        >
          {{ $ml.get("userShiftSettings") }}

          <v-icon right v-text="'mdi-chevron-right'" class="ml-4" size="22" />
        </v-btn>
      </template>

      <v-card class="pt-3">
        <v-card-title class="text-h5 primary--text mb-2">
          {{ $ml.get("whatDoYouWantToDo") }}
        </v-card-title>

        <v-list class="px-5" v-model="selectedRoute">
          <v-list-item
            link
            v-for="({ icon, title, to }, index) in options"
            :key="index"
            class="py-2 grey lighten-4 secondary--text text--lighten-2 mb-4"
            @click="setRedirectDialog(to)"
          >
            <v-list-item-action class="mr-5">
              <v-avatar color="accent">
                <v-icon v-text="icon" size="26" color="white" />
              </v-avatar>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title
                class="text-wrap break-word normal-line-height font-weight-medium text-h6 mt-1"
                v-html="$ml.get(title)"
              />
            </v-list-item-content>
            <v-list-item-action>
              <v-icon v-text="'mdi-arrow-right'" />
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>

    <!-- REDIRECT COUNTDOWN WARNING -->
    <RedirectDialog
      :show="showRedirect"
      @redirect="setRedirectRoute"
      custom-description
      max-width="650"
    >
      <v-list class="px-2 text-left py-0 my-0">
        <v-list-item v-for="({ title }, index) in redirectTips" :key="index">
          <v-list-item-action class="mr-4">
            <v-icon v-text="'mdi-check-circle'" size="24" color="success" />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title
              class="text-wrap break-word normal-line-height text-body-1 mt-1"
              v-html="$ml.get(title)"
            />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </RedirectDialog>
  </div>
</template>
<script>
import RedirectDialog from "@/components/base/RedirectDialog";

export default {
  name: "ShiftSettingsRedirectDialog",

  props: {
    block: { type: Boolean, default: false },
  },

  components: { RedirectDialog },

  data() {
    return {
      dialog: false,
      selectedRoute: "",
      showRedirect: false,
    };
  },

  computed: {
    options() {
      return [
        {
          icon: "mdi-briefcase-account-outline",
          title: "userOptionRedirectToUsers",
          to: "/users",
        },
        {
          icon: "mdi-briefcase-plus-outline",
          title: "userOptionRedirectToShifts",
          to: "/groups",
        },
      ];
    },

    redirectTips() {
      return [
        {
          title: "dontWorryYourActvationProgressIsSaved",
        },
        {
          title: "comeBackAnytimeByClickingInControlActivation",
        },
      ];
    },
  },

  methods: {
    setRedirectDialog(to) {
      this.selectedRoute = to;
      this.showRedirect = true;
      this.dialog = false;
    },

    setRedirectRoute() {
      this.showRedirect = false;
      this.$router.push(this.selectedRoute);
    },
  },
};
</script>
