<template>
  <Dialog
    id="notify-users-via-email"
    :show="show"
    color="accent"
    :title="$ml.get('notify_users_via_email')"
    :loading="loading"
    @close="$emit('close')"
    @action="notifyUsersByEmail"
    :actionText="$ml.get('confirm')"
    showCancel
    :customFooter="false"
  >
    <template #body>
      {{ $ml.get("notify_users_via_email_description") }}
    </template>
  </Dialog>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "NotifyControlActivationDialog",
  props: {
    show: { type: Boolean, required: true },
    loading: { type: Boolean, required: true },
  },
  data() {
    return {
      loadingNotification: false,
    };
  },

  computed: {
    ...mapGetters(["token", "defaultPassword", "domainToActivate"]),
  },

  methods: {
    ...mapActions(["setSnackbarDefault"]),

    notifyUsersByEmail() {
      this.loadingNotification = true;

      this.$emit("close");

      const url = "/companies/send-default-password";

      const payload = {
        default_password: this.defaultPassword,
        domain: this.domainToActivate,
      };

      this.$axios
        .post(url, payload, {
          headers: { Authorization: "Bearer " + this.token },
        })
        .then(() => {
          this.setSnackbarDefault({
            message: "default_password_sent",
            color: "success",
            show: true,
          });
        })
        .catch(() => {
          this.setSnackbarDefault({
            message: "error_default_password_sent",
            color: "error",
            show: true,
          });
        })
        .finally(() => (this.loadingNotification = false));
    },
  },

  watch: {
    loadingNotification(value) {
      this.$emit("update:loading", value);
    },
  },
};
</script>
